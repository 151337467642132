import React, { useState } from 'react';
import {
  Box,
  Button,
  Typography,
  Tabs,
  Tab,
  IconButton,
  Link,
  Paper,
  Drawer,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Calculate as CalculateIcon } from '@mui/icons-material';

// Styled components
const DrawerButton = styled(IconButton)(({ theme }) => ({
  position: 'fixed',
  right: 0,
  top: '20%',
  transform: 'translateY(-50%)',
  zIndex: theme.zIndex.drawer - 1,
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  padding: theme.spacing(1),
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
  },
  '& .MuiSvgIcon-root': {
    fontSize: 32,
  },
}));

const ContentBox = styled(Box)(({ theme }) => ({
  width: '400px',
  height: '100%',
  overflow: 'hidden',
  display: 'flex',
  flexDirection: 'column',
}));

const TabPanel = ({ children, value, index }) => (
  <Box hidden={value !== index} sx={{ flexGrow: 1, overflow: 'auto', p: 2 }}>
    {value === index && children}
  </Box>
);

const FormulasTheorems = ({ 
  lessonFormulas = {
    title: '',
    description: '',
    formulas: [],
    theorems: []
  }
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [tabValue, setTabValue] = useState(0);

  const toggleDrawer = () => setIsOpen(!isOpen);
  
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <>
      {/* Drawer Button */}
      {!isOpen && (
        <DrawerButton
          onClick={toggleDrawer}
          size="large"
        >
          <CalculateIcon />
        </DrawerButton>
      )}

      {/* Drawer */}
      <Drawer
        anchor="right"
        open={isOpen}
        onClose={toggleDrawer}
        variant="persistent"
        PaperProps={{
          sx: {
            width: '400px',
            position: 'fixed',
            height: '100%',
          },
        }}
      >
        <ContentBox>
          {/* Header */}
          <Box sx={{ 
            display: 'flex', 
            alignItems: 'center', 
            p: 2, 
            borderBottom: 1, 
            borderColor: 'divider'
          }}>
            <IconButton onClick={toggleDrawer}>
              <ChevronRightIcon />
            </IconButton>
            <Box sx={{ ml: 2 }}>
              <Typography variant="h6" component="h2">
                {lessonFormulas.title}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {lessonFormulas.description}
              </Typography>
            </Box>
          </Box>

          {/* Tabs */}
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            variant="fullWidth"
            sx={{ borderBottom: 1, borderColor: 'divider' }}
          >
            <Tab label="נוסחאות" />
            <Tab label="תיאוריות" />
          </Tabs>

          {/* Tab Content */}
          <TabPanel value={tabValue} index={0}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
              {lessonFormulas.formulas.map((formula, index) => (
                <Paper key={index} elevation={2} sx={{ p: 2 }}>
                  <Typography variant="h6" gutterBottom>
                    נוסחה {index + 1}
                  </Typography>
                  <Box sx={{ 
                    bgcolor: 'grey.100', 
                    p: 2, 
                    borderRadius: 1, 
                    mb: 1, 
                    fontFamily: 'monospace' 
                  }}>
                    <Typography>{formula.formula}</Typography>
                  </Box>
                  <Link
                    href={formula.reel}
                    target="_blank"
                    rel="noopener noreferrer"
                    sx={{ 
                      display: 'flex', 
                      alignItems: 'center', 
                      gap: 0.5 
                    }}
                  >
                    למידע נוסף
                    <OpenInNewIcon sx={{ fontSize: 16 }} />
                  </Link>
                </Paper>
              ))}
            </Box>
          </TabPanel>

          <TabPanel value={tabValue} index={1}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
              {lessonFormulas.theorems.map((theorem, index) => (
                <Paper key={index} elevation={2} sx={{ p: 2 }}>
                  <Typography variant="h6" gutterBottom>
                    תיאוריה {index + 1}
                  </Typography>
                  <Box sx={{ 
                    bgcolor: 'grey.100', 
                    p: 2, 
                    borderRadius: 1, 
                    mb: 1 
                  }}>
                    <Typography>{theorem.theorem}</Typography>
                  </Box>
                  <Link
                    href={theorem.reel}
                    target="_blank"
                    rel="noopener noreferrer"
                    sx={{ 
                      display: 'flex', 
                      alignItems: 'center', 
                      gap: 0.5 
                    }}
                  >
                    למידע נוסף
                    <OpenInNewIcon sx={{ fontSize: 16 }} />
                  </Link>
                </Paper>
              ))}
            </Box>
          </TabPanel>
        </ContentBox>
      </Drawer>
    </>
  );
};

export default FormulasTheorems;