// src/components/AllCourses.js

import React, {
  useContext,
  useEffect,
  useState,
  useCallback,
  memo,
} from 'react';
import { Link } from 'react-router-dom';
import {
  Box,
  Typography,
  Container,
  Grid,
  Card,
  CardContent,
  Button,
  CircularProgress,
  Alert,
  Skeleton,
  Snackbar,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  CardActionArea,
  ToggleButtonGroup,
  ToggleButton,
} from '@mui/material';
import { styled } from '@mui/system';
import { DataContext } from '../../context/DataContext';
import { UserContext } from '../../context/UserContext';
import IconDisplay from '../Icon/IconDisplay';
import MuiAlert from '@mui/material/Alert';
import CloseIcon from '@mui/icons-material/Close';
import { useTheme } from '@mui/material/styles';
import SearchComponent from '../Widgets/Filters/SearchComponent';
import SubjectsFilter from '../Widgets/Filters/SubjectsFilter';

// Styled Components

// Header for the Courses Section
const SectionHeader = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(4),
  fontWeight: 700,
  textAlign: 'center',
  [theme.breakpoints.down('sm')]: {
    fontSize: '1.8rem',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '2.5rem',
  },
}));

// StyledCard with relative positioning to anchor the AccessBadge
const StyledCard = styled(Card)(({ theme }) => ({
  position: 'relative',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  boxShadow: theme.shadows[2],
  borderRadius: theme.shape.borderRadius,
  transition: 'transform 0.3s, box-shadow 0.3s',
  '&:hover': {
    transform: 'translateY(-8px)',
    boxShadow: theme.shadows[4],
  },
}));

// AccessBadge positioned at the top-right corner
const AccessBadge = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'userHasAccess',
})(({ theme, userHasAccess }) => ({
  position: 'absolute',
  top: theme.spacing(1),
  right: theme.spacing(1),
  padding: theme.spacing(0.5, 1),
  borderRadius: theme.shape.borderRadius,
  backgroundColor: userHasAccess
    ? theme.palette.success.main
    : theme.palette.error.main,
  color: theme.palette.common.white,
  fontWeight: 600,
  zIndex: 1,
  fontSize: '0.75rem',
}));

// Course Type Badge positioned at the top-left corner
const CourseTypeBadge = styled(Box)(({ theme, isLive }) => ({
  position: 'absolute',
  top: theme.spacing(1),
  left: theme.spacing(1),
  padding: theme.spacing(0.5, 1),
  borderRadius: theme.shape.borderRadius,
  backgroundColor: isLive
    ? theme.palette.primary.main
    : theme.palette.warning.main,
  color: theme.palette.common.white,
  fontWeight: 600,
  zIndex: 1,
  fontSize: '0.75rem',
}));

// Actions Container for Buttons
const CourseActions = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  textAlign: 'center',
  display: 'flex',
  justifyContent: 'space-between',
  gap: theme.spacing(1),
}));

// Snackbar Alert Component
const AlertSnackbar = React.forwardRef(function AlertSnackbar(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

// Memoized Course Card Component with Access Badge and Course Type Badge
const CourseCard = memo(
  ({ course, userHasAccess, handleSelectPlan }) => {
    const theme = useTheme();
    return (
      <Grid item key={course._id} xs={12} sm={6} md={4}>
        <StyledCard>
          {/* Course Type Badge */}
          <CourseTypeBadge isLive={course.isLive}>
            {course.isLive ? 'רץ בלייב' : 'מוקלט'}
          </CourseTypeBadge>

          {/* Access Status Badge */}
          <AccessBadge userHasAccess={userHasAccess}>
            {userHasAccess ? 'יש גישה' : 'אין גישה'}
          </AccessBadge>

          {/* Clickable Area */}
          <CardActionArea
            component={Link}
            to={`/course/${course._id}/details`}
            aria-label={`מעבר לפרטים עבור ${course.title}`}
          >
            {/* Icon Display */}
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
              <IconDisplay
                iconName={course.icon}
                size={80}
                color="primary.main"
              />
            </Box>

            {/* Card Content */}
            <CardContent sx={{ flexGrow: 1, textAlign: 'center' }}>
              <Typography
                variant="h5"
                component="h2"
                gutterBottom
                sx={{
                  fontSize: {
                    xs: '1.2rem',
                    sm: '1.4rem',
                    md: '1.6rem',
                  },
                }}
              >
                {course.title}
              </Typography>
              <Typography
                variant="body2"
                color="text.secondary"
                sx={{
                  fontSize: {
                    xs: '0.9rem',
                    sm: '1rem',
                    md: '1.1rem',
                  },
                }}
              >
                {course.description.length > 100
                  ? `${course.description.substring(0, 100)}...`
                  : course.description}
              </Typography>
            </CardContent>
          </CardActionArea>

          {/* Course Actions */}
          <CourseActions>
            {userHasAccess ? (
              // Removed the "Lessons" button
              <>
                {/* Details Button */}
                <Button
                  variant="outlined"
                  color="info"
                  component={Link}
                  to={`/course/${course._id}/details`}
                  sx={{
                    flex: 1,
                    textTransform: 'none',
                    borderRadius: '50px',
                    borderColor: 'info.main',
                    color: 'info.main',
                    boxShadow: theme.shadows[2],
                    transition:
                      'background-color 0.3s ease, color 0.3s ease',
                    '&:hover': {
                      backgroundColor: 'info.main',
                      color: theme.palette.common.white,
                    },
                  }}
                  aria-label={`צפייה בפרטים עבור ${course.title}`}
                >
                  פרטים
                </Button>
              </>
            ) : (
              <>
                {/* Select Plan Button */}
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => handleSelectPlan(course)}
                  sx={{
                    flex: 1,
                    textTransform: 'none',
                    borderRadius: '50px',
                    boxShadow: theme.shadows[2],
                    transition:
                      'background-color 0.3s ease, color 0.3s ease',
                    '&:hover': {
                      backgroundColor: 'secondary.dark',
                      color: theme.palette.common.white,
                    },
                  }}
                  aria-label={`בחירת תוכנית עבור ${course.title}`}
                >
                  בחר תוכנית
                </Button>

                {/* Details Button */}
                <Button
                  variant="outlined"
                  color="info"
                  component={Link}
                  to={`/course/${course._id}/details`}
                  sx={{
                    flex: 1,
                    textTransform: 'none',
                    borderRadius: '50px',
                    borderColor: 'info.main',
                    color: 'info.main',
                    boxShadow: theme.shadows[2],
                    transition:
                      'background-color 0.3s ease, color 0.3s ease',
                    '&:hover': {
                      backgroundColor: 'info.main',
                      color: theme.palette.common.white,
                    },
                  }}
                  aria-label={`צפייה בפרטים עבור ${course.title}`}
                >
                  פרטים
                </Button>
              </>
            )}
          </CourseActions>
        </StyledCard>
      </Grid>
    );
  }
);

const PlansModal = ({ open, onClose, course, plans }) => {
  const theme = useTheme();
  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      dir="rtl"
      PaperProps={{
        sx: {
          borderRadius: 2,
          maxHeight: '80vh',
        },
      }}
    >
      <DialogTitle sx={{ pr: 2, pl: 6, position: 'relative' }}>
        <Typography variant="h5">
          תוכניות זמינות עבור {course?.title}
        </Typography>
        <IconButton
          onClick={onClose}
          sx={{
            position: 'absolute',
            left: 8,
            top: 8,
            color: theme.palette.grey[500],
          }}
          aria-label="סגור"
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent dividers>
        <Grid container spacing={3} sx={{ mt: 1 }}>
          {plans.map((plan) => (
            <Grid item key={plan._id} xs={12} sm={6}>
              <Card
                sx={{
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  border: `1px solid`,
                  borderColor: 'divider',
                  borderRadius: theme.shape.borderRadius,
                  transition: 'transform 0.2s, box-shadow 0.2s',
                  '&:hover': {
                    transform: 'translateY(-4px)',
                    boxShadow: theme.shadows[4],
                  },
                }}
              >
                <CardContent sx={{ flexGrow: 1, p: 3 }}>
                  <Typography variant="h5" gutterBottom>
                    {plan.name}
                  </Typography>
                  <Typography variant="body2" color="text.secondary" gutterBottom>
                    {plan.description}
                  </Typography>
                  <Typography
                    variant="h6"
                    color="primary"
                    sx={{ mt: 2, fontWeight: 'bold' }}
                  >
                    ₪{plan.price}
                  </Typography>
                </CardContent>
                <Box sx={{ p: 2, pt: 0 }}>
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    component={Link}
                    to={`/checkout/${plan._id}`}
                    sx={{
                      borderRadius: '50px',
                      textTransform: 'none',
                      py: 1,
                    }}
                  >
                    הירשם
                  </Button>
                </Box>
              </Card>
            </Grid>
          ))}
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

// Main AllCourses Component
const AllCourses = () => {
  const theme = useTheme();
  const { courses, plans, lessons, subjects, loading: dataLoading, error: dataError } = useContext(DataContext);
  const {
    user,
    isSignedIn,
    selectPlan,
    createSubscription,
    enrollInCourse,
    hasAccess,
    loading: userLoading,
    error: userError,
    setError,
  } = useContext(UserContext);

  // State to track access for each course
  const [courseAccess, setCourseAccess] = useState({});
  const [accessLoading, setAccessLoading] = useState(false);
  const [accessError, setAccessError] = useState(null);

  // Snackbar state
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success',
  });

  // State to track the selected course for which to display plans
  const [plansModalOpen, setPlansModalOpen] = useState(false);
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [availablePlans, setAvailablePlans] = useState([]);

  // State for filters
  const [filteredCourses, setFilteredCourses] = useState(courses);
  const [selectedSearchOptions, setSelectedSearchOptions] = useState([]);
  const [selectedSubjects, setSelectedSubjects] = useState([]);
  const [filterCourses, setFilterCourses] = useState('allCourses'); // 'myCourses', 'allCourses'

  const handleSearch = (selectedOptions) => {
    setSelectedSearchOptions(selectedOptions);
  };

  const handleSubjectsChange = (event, newSubjects) => {
    setSelectedSubjects(newSubjects);
  };

  const handleCoursesFilterChange = (event, newFilter) => {
    if (newFilter !== null) {
      setFilterCourses(newFilter);
    }
  };

  // Function to handle when the "Select Plan" button is clicked
  const handleSelectPlan = (course) => {
    const plansForCourse = plans.filter((plan) =>
      plan.courses.some((courseItem) => courseItem._id === course._id)
    );

    if (plansForCourse.length === 0) {
      setSnackbar({
        open: true,
        message: 'אין תוכניות זמינות עבור קורס זה.',
        severity: 'warning',
      });
      return;
    }

    setSelectedCourse(course);
    setAvailablePlans(plansForCourse);
    setPlansModalOpen(true);
  };

  const handleCloseModal = () => {
    setPlansModalOpen(false);
    setTimeout(() => {
      setSelectedCourse(null);
      setAvailablePlans([]);
    }, 300);
  };

  // Memoized function to check access
  const checkAccessForCourses = useCallback(async () => {
    if (!isSignedIn || courses.length === 0) {
      const noAccess = {};
      courses.forEach((course) => {
        noAccess[course._id] = false;
      });
      setCourseAccess(noAccess);
      return;
    }

    setAccessLoading(true);
    setAccessError(null);

    try {
      const accessResults = {};

      // Perform all access checks in parallel
      await Promise.all(
        courses.map(async (course) => {
          const access = await hasAccess('course', course._id);
          accessResults[course._id] = access;
        })
      );

      setCourseAccess(accessResults);
    } catch (err) {
      console.error('Error checking course access:', err);
      setAccessError('נכשל אימות הגישה לחלק מהקורסים.');
    } finally {
      setAccessLoading(false);
    }
  }, [isSignedIn, courses, hasAccess]);

  useEffect(() => {
    checkAccessForCourses();
  }, [checkAccessForCourses]);

  // Filter courses based on selected options
  useEffect(() => {
    let tempCourses = [...courses];

    // Filter by subjects
    if (selectedSubjects.length > 0) {
      const subjectNames = selectedSubjects.map(subject => subject.name);
      tempCourses = tempCourses.filter(course =>
        subjectNames.includes(course.subject)
      );
    }

    // Filter by search options
    if (selectedSearchOptions.length > 0) {
      const courseIdsFromSearch = selectedSearchOptions
        .filter((option) => option.type === 'course')
        .map((option) => option.id);

      const lessonCourseIdsFromSearch = selectedSearchOptions
        .filter((option) => option.type === 'lesson')
        .map((option) => {
          const lesson = lessons.find((l) => l._id === option.id);
          return lesson && lesson.course ? lesson.course.toString() : null;
        })
        .filter((id) => id !== null);

      const combinedSearchIds = Array.from(
        new Set([...courseIdsFromSearch, ...lessonCourseIdsFromSearch])
      );

      tempCourses = tempCourses.filter((course) =>
        combinedSearchIds.includes(course._id.toString())
      );
    }

    setFilteredCourses(tempCourses);
  }, [
    courses,
    selectedSubjects,
    selectedSearchOptions,
    lessons,
    filterCourses,
    courseAccess,
    accessLoading,
  ]);

  // Render Skeletons for loading state
  const renderSkeletons = () => {
    const skeletonArray = Array.from(new Array(6));
    return skeletonArray.map((_, index) => (
      <Grid item key={index} xs={12} sm={6} md={4}>
        <Card
          sx={{
            position: 'relative',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            boxShadow: theme.shadows[2],
            borderRadius: theme.shape.borderRadius,
            padding: theme.spacing(2),
          }}
        >
          {/* Skeleton for Course Type Badge */}
          <Skeleton
            variant="rectangular"
            width={80}
            height={24}
            sx={{
              position: 'absolute',
              top: theme.spacing(1),
              left: theme.spacing(1),
              borderRadius: 1,
            }}
          />

          {/* Skeleton for Access Badge */}
          <Skeleton
            variant="rectangular"
            width={100}
            height={24}
            sx={{
              position: 'absolute',
              top: theme.spacing(1),
              right: theme.spacing(1),
              borderRadius: 1,
            }}
          />

          {/* Icon Skeleton */}
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
            <Skeleton variant="circular" width={80} height={80} />
          </Box>

          {/* Card Content Skeleton */}
          <CardContent sx={{ flexGrow: 1, textAlign: 'center' }}>
            <Skeleton variant="text" height={40} />
            <Skeleton variant="text" height={20} />
            <Skeleton variant="text" height={20} />
          </CardContent>

          {/* Course Actions Skeleton */}
          <CourseActions>
            <Skeleton variant="rectangular" width="100%" height={36} />
            <Skeleton variant="rectangular" width="100%" height={36} />
          </CourseActions>
        </Card>
      </Grid>
    ));
  };

  // Handle Snackbar close
  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar((prev) => ({ ...prev, open: false }));
  };

  // Loading and error handling
  if (dataLoading || userLoading || accessLoading) {
    return (
      <Container>
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
          <CircularProgress />
        </Box>
      </Container>
    );
  }

  if (dataError || userError || accessError) {
    return (
      <Container>
        <Alert severity="error">{dataError || userError || accessError}</Alert>
      </Container>
    );
  }

  return (
    <main className="all-courses">
      <Container dir="rtl">
        {/* Header Section */}
        <Box sx={{ textAlign: 'center', mb: 8 }}>
          <SectionHeader variant="h3">כל הקורסים</SectionHeader>
          <Typography variant="h6" color="text.secondary">
            גלה מגוון קורסים שנועדו לשפר את היכולות והידע שלך.
          </Typography>
        </Box>

        {/* Enhanced Search Component */}
        <SearchComponent
          onSearch={handleSearch}
          includeCourses={true}
          includeLessons={false}
        />

        {/* Subjects Filter */}
        <SubjectsFilter
          multiple
          options={subjects}
          value={selectedSubjects}
          onChange={handleSubjectsChange}
        />

        {/* Courses Grid */}
        <Grid container spacing={4}>
          {filteredCourses.length > 0 ? (
            filteredCourses.map((course) => {
              const userHasAccess = courseAccess[course._id] || false;

              return (
                <CourseCard
                  key={course._id}
                  course={course}
                  userHasAccess={userHasAccess}
                  handleSelectPlan={handleSelectPlan}
                />
              );
            })
          ) : (
            <Grid item xs={12}>
              <Box sx={{ width: '100%', textAlign: 'center', mt: 4 }}>
                <Typography variant="h6">
                  אין קורסים זמינים לפי הסינונים שנבחרו.
                </Typography>
              </Box>
            </Grid>
          )}
        </Grid>

        {/* General Error Message */}
        {userError && (
          <Box sx={{ mt: 4 }}>
            <Alert severity="error" onClose={() => setError(null)}>
              {userError}
            </Alert>
          </Box>
        )}

        <PlansModal
          open={plansModalOpen}
          onClose={handleCloseModal}
          course={selectedCourse}
          plans={availablePlans}
        />

        {/* Snackbar for Feedback */}
        <Snackbar
          open={snackbar.open}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <AlertSnackbar
            onClose={handleCloseSnackbar}
            severity={snackbar.severity}
            sx={{ width: '100%' }}
          >
            {snackbar.message}
          </AlertSnackbar>
        </Snackbar>
      </Container>
    </main>
  );
};

export default AllCourses;
