// src/context/DataContext.js

import React, { createContext, useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';

// Create the DataContext
export const DataContext = createContext();

export const DataProvider = ({ children }) => {
  // Existing state variables
  const [courses, setCourses] = useState([]);
  const [plans, setPlans] = useState([]);
  const [lessons, setLessons] = useState([]);
  const [studyMaterials, setStudyMaterials] = useState([]);
  const [subscriptions, setSubscriptions] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // New state variables for filtered lessons
  const [filteredLessons, setFilteredLessons] = useState([]);
  const [filteredLoading, setFilteredLoading] = useState(false);
  const [filteredError, setFilteredError] = useState(null);

  /**
   * Function to fetch all courses
   */
  const fetchCourses = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await fetch(`${process.env.REACT_APP_INFINITY_BACK_URL}/api/courses`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      if (response.ok) {
        const data = await response.json();
        console.log(data);
        setCourses(data || []);
      } else {
        const errorData = await response.json();
        console.error('Failed to fetch courses:', errorData.error || response.statusText);
        setError(errorData.error || 'Failed to fetch courses.');
      }
    } catch (err) {
      console.error('Error fetching courses:', err);
      setError('An unexpected error occurred while fetching courses.');
    } finally {
      setLoading(false);
    }
  }, []);

  /**
   * Function to fetch all plans
   */
  const fetchPlans = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await fetch(`${process.env.REACT_APP_INFINITY_BACK_URL}/api/plans`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      if (response.ok) {
        const data = await response.json();
        setPlans(data || []);
      } else {
        const errorData = await response.json();
        console.error('Failed to fetch plans:', errorData.error || response.statusText);
        setError(errorData.error || 'Failed to fetch plans.');
      }
    } catch (err) {
      console.error('Error fetching plans:', err);
      setError('An unexpected error occurred while fetching plans.');
    } finally {
      setLoading(false);
    }
  }, []);

  /**
   * Function to fetch all lessons
   */
  const fetchLessons = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await fetch(`${process.env.REACT_APP_INFINITY_BACK_URL}/api/lessons`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      if (response.ok) {
        const data = await response.json();
        setLessons(data || []);
      } else {
        const errorData = await response.json();
        console.error('Failed to fetch lessons:', errorData.error || response.statusText);
        setError(errorData.error || 'Failed to fetch lessons.');
      }
    } catch (err) {
      console.error('Error fetching lessons:', err);
      setError('An unexpected error occurred while fetching lessons.');
    } finally {
      setLoading(false);
    }
  }, []);

  /**
   * Function to fetch all study materials
   */
  const fetchStudyMaterials = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await fetch(`${process.env.REACT_APP_INFINITY_BACK_URL}/api/studyMaterials`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      if (response.ok) {
        const data = await response.json();
        setStudyMaterials(data || []);
      } else {
        const errorData = await response.json();
        console.error('Failed to fetch study materials:', errorData.error || response.statusText);
        setError(errorData.error || 'Failed to fetch study materials.');
      }
    } catch (err) {
      console.error('Error fetching study materials:', err);
      setError('An unexpected error occurred while fetching study materials.');
    } finally {
      setLoading(false);
    }
  }, []);

  /**
   * Function to fetch all subjects
   */
  const fetchSubjects = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await fetch(`${process.env.REACT_APP_INFINITY_BACK_URL}/api/courses/subjects`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        const data = await response.json();
        console.log(data.subjects);
        setSubjects(data.subjects || []);
      } else {
        const errorData = await response.json();
        console.error('Failed to fetch subjects:', errorData.error || response.statusText);
        setError(errorData.error || 'Failed to fetch subjects.');
      }
    } catch (err) {
      console.error('Error fetching subjects:', err);
      setError('An unexpected error occurred while fetching subjects.');
    } finally {
      setLoading(false);
    }
  }, []);

  /**
   * Function to fetch all subscriptions
   */
  const fetchSubscriptions = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await fetch(`${process.env.REACT_APP_INFINITY_BACK_URL}/api/subscriptions`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      if (response.ok) {
        const data = await response.json();
        setSubscriptions(data || []);
      } else {
        const errorData = await response.json();
        console.error('Failed to fetch subscriptions:', errorData.error || response.statusText);
        setError(errorData.error || 'Failed to fetch subscriptions.');
      }
    } catch (err) {
      console.error('Error fetching subscriptions:', err);
      setError('An unexpected error occurred while fetching subscriptions.');
    } finally {
      setLoading(false);
    }
  }, []);

  /**
   * Function to get a course by ID
   */
  const getCourseById = useCallback(
    (id) => {
      return courses.find((course) => course._id === id);
    },
    [courses]
  );

  /**
   * Function to get a lesson by ID
   */
  const getLessonById = useCallback(
    (id) => {
      return lessons.find((lesson) => lesson._id === id);
    },
    [lessons]
  );

  /**
   * Function to fetch filtered upcoming lessons
   * @param {string} clerkUserId - The unique identifier for the user
   * @param {Object} filters - The filter parameters
   *        filters = {
   *          timeframe: 'today' | 'week' | 'month',
   *          courseFilter: 'myCourses' | 'allCourses',
   *          subjects: Array of subject IDs,
   *          search: String
   *        }
   */
  const fetchFilteredUpcomingLessons = useCallback(async (clerkUserId, filters) => {
    setFilteredLoading(true);
    setFilteredError(null);
    try {
      // Build query parameters
      const queryParams = new URLSearchParams();
      if (filters.timeframe) queryParams.append('timeframe', filters.timeframe);
      if (filters.courseFilter) queryParams.append('courseFilter', filters.courseFilter);
      if (filters.subjects && filters.subjects.length > 0) {
        filters.subjects.forEach(subject => queryParams.append('subjects', subject));
      }
      if (filters.search) queryParams.append('search', filters.search);

      const finalUrl = `${process.env.REACT_APP_INFINITY_BACK_URL}/api/lessons/upcoming-lessons/${clerkUserId}?${queryParams.toString()}`;
      console.log(queryParams,"a")
      const response = await fetch(finalUrl, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        const data = await response.json();
        console.log(data,"b");
        setFilteredLessons(data.upcomingLessons || []);
      } else {
        const errorData = await response.json();
        console.error('Failed to fetch filtered lessons:', errorData.error || response.statusText);
        setFilteredError(errorData.error || 'Failed to fetch filtered lessons.');
      }
    } catch (err) {
      console.error('Error fetching filtered lessons:', err);
      setFilteredError('An unexpected error occurred while fetching filtered lessons.');
    } finally {
      setFilteredLoading(false);
    }
  }, []);

  /**
   * Fetch all data on component mount
   */
  useEffect(() => {
    fetchCourses();
    fetchPlans();
    fetchLessons();
    fetchStudyMaterials();
    fetchSubscriptions();
    fetchSubjects();
  }, [fetchCourses, fetchPlans, fetchLessons, fetchStudyMaterials, fetchSubscriptions, fetchSubjects]);

  /**
   * Define the context value
   */
  const contextValue = {
    // Existing context values
    courses,
    plans,
    lessons,
    studyMaterials,
    subscriptions,
    subjects,
    loading,
    error,
    fetchCourses,
    fetchPlans,
    fetchLessons,
    fetchStudyMaterials,
    fetchSubscriptions,
    fetchSubjects,
    getLessonById,
    getCourseById,

    // New context values for filtered lessons
    filteredLessons,
    filteredLoading,
    filteredError,
    fetchFilteredUpcomingLessons,
  };

  return <DataContext.Provider value={contextValue}>{children}</DataContext.Provider>;
};

DataProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
