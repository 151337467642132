// src/theme/themeDesktop.js

import { createTheme } from '@mui/material/styles';

/**
 * Utility function to adjust the palette based on the selected color blind mode.
 * @param {string} colorBlindMode - The type of color blind mode ('none', 'protanopia', 'deuteranopia', 'tritanopia').
 * @returns {object} - An object containing palette adjustments.
 */
const getColorBlindPalette = (colorBlindMode) => {
  const palettes = {
    none: {},
    protanopia: {
      primary: { main: '#005f73' }, // Adjusted color for Protanopia
      secondary: { main: '#94d2bd' },
    },
    deuteranopia: {
      primary: { main: '#0a9396' }, // Adjusted color for Deuteranopia
      secondary: { main: '#ee9b00' },
    },
    tritanopia: {
      primary: { main: '#ca6702' }, // Adjusted color for Tritanopia
      secondary: { main: '#bb3e03' },
    },
  };
  return palettes[colorBlindMode] || {};
};

/**
 * Generates the desktop theme based on accessibility settings.
 * @param {object} settings - Accessibility settings.
 * @param {string} settings.mode - Theme mode ('light' or 'dark').
 * @param {boolean} settings.highContrast - High contrast mode toggle.
 * @param {number} settings.fontSize - Base font size.
 * @param {boolean} settings.reduceMotion - Reduce motion toggle.
 * @param {boolean} settings.dyslexiaFont - Dyslexia-friendly font toggle.
 * @param {string} settings.colorBlindMode - Color blind mode ('none', 'protanopia', 'deuteranopia', 'tritanopia').
 * @returns {object} - The generated MUI theme.
 */
const getDesktopTheme = ({
  mode,
  highContrast,
  fontSize,
  reduceMotion,
  dyslexiaFont,
  colorBlindMode,
}) =>
  createTheme({
    direction: 'rtl', // Right-to-left layout
    palette: highContrast
      ? {
          mode: 'dark',
          primary: { main: '#000000', contrastText: '#ffffff' },
          secondary: { main: '#ffffff', contrastText: '#000000' },
          background: { default: '#000000', paper: '#000000' },
          text: { primary: '#ffffff', secondary: '#ffffff' },
        }
      : {
          mode,
          primary: {
            main: '#D4AF37', // Rich Gold
            contrastText: '#000000',
          },
          secondary: {
            main: '#2C3E50', // Dark Blue
            contrastText: '#ffffff',
          },
          error: {
            main: '#E74C3C',
          },
          warning: {
            main: '#F39C12',
          },
          info: {
            main: '#3498DB',
          },
          success: {
            main: '#27AE60',
          },
          // Additional custom colors
          burgundy: {
            main: '#800020',
            contrastText: '#ffffff',
          },
          teal: {
            main: '#008080',
            contrastText: '#ffffff',
          },
          navy: {
            main: '#000080',
            contrastText: '#ffffff',
          },
          lightGreen: {
            main: '#90EE90',
            contrastText: '#000000',
          },
          background: {
            default: mode === 'light' ? '#F5F5F5' : '#121212',
            paper: mode === 'light' ? '#FFFFFF' : '#1e1e1e',
          },
          text: {
            primary: mode === 'light' ? '#343A40' : '#ffffff',
            secondary: mode === 'light' ? '#6C757D' : '#bbbbbb',
          },
          ...getColorBlindPalette(colorBlindMode), // Apply color blind adjustments
        },
    typography: {
      fontSize: fontSize, // Base font size
      fontFamily: dyslexiaFont
        ? 'OpenDyslexic, sans-serif' // Dyslexia-friendly font
        : [
            'Open Sans',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
          ].join(','),
      h1: {
        fontFamily: 'Roboto, sans-serif',
        fontWeight: 700,
        fontSize: '32px',
        '@media (max-width:600px)': {
          fontSize: '24px',
        },
      },
      h6: {
        fontWeight: 600,
      },
      body1: {
        fontWeight: 500,
      },
      // Add more typography settings as needed
    },
    transitions: {
      // Disable animations if reduceMotion is true
      duration: reduceMotion ? 0 : undefined,
    },
    components: {
      MuiAppBar: {
        styleOverrides: {
          root: {
            backgroundColor: highContrast
              ? '#000000'
              : mode === 'light'
              ? '#ffffff'
              : '#1e1e1e',
            color: highContrast
              ? '#ffffff'
              : mode === 'light'
              ? '#D4AF37'
              : '#ffffff',
            boxShadow: 'none',
            borderBottom: '1px solid #e0e0e0',
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: '5px',
            height: '50px',
            minWidth: '150px',
            padding: '0 16px',
          },
          containedPrimary: {
            backgroundColor: highContrast ? '#000000' : '#D4AF37',
            color: highContrast ? '#ffffff' : '#000000',
            '&:hover': {
              backgroundColor: highContrast ? '#333333' : '#c19a2b',
            },
          },
          containedSecondary: {
            backgroundColor: highContrast ? '#ffffff' : '#2C3E50',
            color: highContrast ? '#000000' : '#ffffff',
            '&:hover': {
              backgroundColor: highContrast ? '#cccccc' : '#1a2733',
            },
          },
        },
      },
      MuiTypography: {
        styleOverrides: {
          root: {
            cursor: 'default',
          },
        },
      },
      MuiIconButton: {
        styleOverrides: {
          root: {
            color: highContrast ? '#ffffff' : '#343A40',
            '&:hover': {
              backgroundColor: highContrast ? '#555555' : '#f5f5f5',
            },
          },
        },
      },
      MuiDrawer: {
        styleOverrides: {
          paper: {
            backgroundColor: highContrast ? '#000000' : '#ffffff',
          },
        },
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            '&:hover': {
              backgroundColor: highContrast
                ? '#333333'
                : mode === 'light'
                ? '#f5f5f5'
                : '#333333',
            },
          },
        },
      },
      MuiAccordion: {
        styleOverrides: {
          root: {
            borderRadius: '5px',
            marginBottom: '10px',
            boxShadow: '0 1px 3px rgba(0,0,0,0.1)',
          },
        },
      },
      MuiAccordionSummary: {
        styleOverrides: {
          root: {
            padding: '10px',
            '&.Mui-expanded': {
              minHeight: '48px',
            },
          },
        },
      },
      MuiAccordionDetails: {
        styleOverrides: {
          root: {
            padding: '10px',
          },
        },
      },
      // Add more component overrides as desired
    },
  });

export default getDesktopTheme;
