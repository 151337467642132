// src/components/MySubscription.js

import React, { useContext, useEffect, useState } from 'react';
import {
  Box,
  Typography,
  Container,
  Card,
  CardContent,
  Button,
  CircularProgress,
  Alert,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@mui/material';
import { styled } from '@mui/system';
import { DataContext } from '../../context/DataContext';
import { UserContext } from '../../context/UserContext';
import { useNavigate } from 'react-router-dom';

const SectionHeader = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(4),
  fontWeight: 'bold',
  textAlign: 'center',
}));

const MySubscription = () => {
  const navigate = useNavigate();
  const { plans, loading: dataLoading, error: dataError } = useContext(DataContext);
  const {
    subscriptions,
    loading: userLoading,
    error: userError,
    setError,
    cancelSubscription, // Ensure this function is provided by UserContext
  } = useContext(UserContext);

  // Log subscriptions and plans for debugging
  console.log("subscriptions", subscriptions);
  console.log("plans", plans);

  // State to manage which subscription's details dialog is open
  const [detailsDialogOpen, setDetailsDialogOpen] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(null);

  // State to manage which subscription's cancel dialog is open
  const [cancelDialogOpen, setCancelDialogOpen] = useState(false);
  const [subscriptionToCancel, setSubscriptionToCancel] = useState(null);

  // Function to open Plan Details dialog
  const handlePlanDetails = (plan) => {
    setSelectedPlan(plan);
    setDetailsDialogOpen(true);
  };

  // Function to close Plan Details dialog
  const handleCloseDetailsDialog = () => {
    setDetailsDialogOpen(false);
    setSelectedPlan(null);
  };

  // Function to open Cancel Subscription dialog
  const handleCancelSubscription = (subscription) => {
    setSubscriptionToCancel(subscription);
    setCancelDialogOpen(true);
  };

  // Function to confirm cancellation
  const confirmCancelSubscription = async () => {
    setCancelDialogOpen(false);
    if (!subscriptionToCancel) return;

    try {
      await cancelSubscription(subscriptionToCancel._id); // Pass subscription ID
      // Optionally, show a success message or update UI
      navigate('/subscription-canceled');
    } catch (error) {
      console.error('Error canceling subscription:', error);
      setError('Failed to cancel subscription. Please try again.');
    }
  };

  // Function to navigate to Manage Subscription page
  const handleNavigateToManage = () => {
    navigate('/manage-subscription');
  };

  if (dataLoading || userLoading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (dataError || userError) {
    return (
      <Container >
        <Alert severity="error">{dataError || userError}</Alert>
      </Container>
    );
  }

  if (!subscriptions || subscriptions.length === 0) {
    return (
      <Container >
        <Alert severity="info">You do not have any active subscriptions.</Alert>
        <Box sx={{ textAlign: 'center', mt: 4 }}>
          <Button variant="contained" color="primary" onClick={() => navigate('/select-plan')}>
            Subscribe Now
          </Button>
        </Box>
      </Container>
    );
  }

  return (
    <main className="my-subscription">
      <Container >
        {/* Header Section */}
        <Box sx={{ textAlign: 'center', mb: 8 }}>
          <Typography variant="h3" component="h1" gutterBottom>
            My Subscriptions
          </Typography>
          <Typography variant="h6" color="textSecondary">
            Manage your current subscriptions below.
          </Typography>
        </Box>

        {/* Subscriptions Grid */}
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
          {subscriptions.map((subscription) => {
            const plan = plans.find((p) => p._id === subscription.plan._id);

            // If plan is not found, skip rendering this subscription
            if (!plan) return null;

            return (
              <Card
                key={subscription._id}
                sx={{
                  maxWidth: 600,
                  margin: '0 auto',
                  padding: 4,
                  boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
                  borderRadius: 2,
                }}
              >
                <CardContent>
                  <Typography variant="h5" component="h2" gutterBottom>
                    {plan.title}
                  </Typography>
                  <Typography variant="h6" color="primary">
                  ₪{plan.price}
                  </Typography>
                  <Typography variant="body2" color="textSecondary" sx={{ mt: 2 }}>
                    {plan.description}
                  </Typography>
                  <Box sx={{ mt: 2 }}>
                    <Typography variant="subtitle1" gutterBottom>
                      Features:
                    </Typography>
                    <ul>
                      {plan.features.map((feature, index) => (
                        <li key={index}>
                          <Typography variant="body2">{feature}</Typography>
                        </li>
                      ))}
                    </ul>
                  </Box>
                  <Box sx={{ mt: 4, display: 'flex', justifyContent: 'space-between' }}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => handlePlanDetails(plan)}
                      sx={{
                        textTransform: 'none',
                        borderRadius: '50px',
                        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                        transition: 'background-color 0.3s ease',
                        '&:hover': {
                          backgroundColor: 'primary.dark',
                        },
                      }}
                    >
                      Plan Details
                    </Button>
                    <Button
                      variant="outlined"
                      color="error"
                      onClick={() => handleCancelSubscription(subscription)}
                      sx={{
                        textTransform: 'none',
                        borderRadius: '50px',
                        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                        transition: 'background-color 0.3s ease',
                        '&:hover': {
                          backgroundColor: 'error.light',
                        },
                      }}
                    >
                      Cancel Subscription
                    </Button>
                  </Box>
                </CardContent>
              </Card>
            );
          })}
        </Box>

        {/* Plan Details Dialog */}
        <Dialog
          open={detailsDialogOpen}
          onClose={handleCloseDetailsDialog}
          aria-labelledby="plan-details-dialog-title"
        >
          <DialogTitle id="plan-details-dialog-title">Plan Details</DialogTitle>
          <DialogContent>
            {selectedPlan ? (
              <>
                <DialogContentText>
                  <strong>Plan Name:</strong> {selectedPlan.title}
                </DialogContentText>
                <DialogContentText>
                  <strong>Price:</strong> ₪{selectedPlan.price}
                </DialogContentText>
                <DialogContentText>
                  <strong>Description:</strong> {selectedPlan.description}
                </DialogContentText>
                <Box sx={{ mt: 2 }}>
                  <Typography variant="subtitle1" gutterBottom>
                    Features:
                  </Typography>
                  <ul>
                    {selectedPlan.features.map((feature, index) => (
                      <li key={index}>
                        <Typography variant="body2">{feature}</Typography>
                      </li>
                    ))}
                  </ul>
                </Box>
              </>
            ) : (
              <CircularProgress />
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDetailsDialog} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>

        {/* Cancel Subscription Confirmation Dialog */}
        <Dialog
          open={cancelDialogOpen}
          onClose={() => setCancelDialogOpen(false)}
          aria-labelledby="cancel-dialog-title"
        >
          <DialogTitle id="cancel-dialog-title">Cancel Subscription</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to cancel your subscription? This action cannot be undone.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setCancelDialogOpen(false)} color="primary">
              No
            </Button>
            <Button onClick={confirmCancelSubscription} color="error" autoFocus>
              Yes, Cancel
            </Button>
          </DialogActions>
        </Dialog>

        {/* User Error Message */}
        {userError && (
          <Box sx={{ mt: 4, textAlign: 'center' }}>
            <Alert severity="error">{userError}</Alert>
          </Box>
        )}
      </Container>
    </main>
  );
};

export default MySubscription;
