// src/infoCards.js

const infoCards = [
  {
    title: 'המשימה שלנו',
    description:
      'העצמת תלמידים להשיג את מלוא הפוטנציאל שלהם באמצעות חוויות למידה מותאמות אישית.',
    path: 'images/home/mission.jpg',
  },
  {
    title: 'מה אנו מציעים',
    description:
      'טווח מקיף של קורסים ומשאבים המותאמים לצרכי למידה אישיים.',
    path: 'images/home/offers.jpg',
  },
  {
    title: 'הצטרפו לקהילה שלנו',
    description:
      'התחברו עם לומדים ומחנכים בעלי חשיבה דומה כדי לטפח סביבת למידה שיתופית.',
    path: 'images/home/community.jpg',
  },
];

export default infoCards;
