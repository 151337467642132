// src/components/Checkout.js

import React, { useEffect, useState, useContext } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { UserContext } from '../../context/UserContext';
import {
  Box,
  Typography,
  Container,
  Grid,
  Card,
  CardContent,
  TextField,
  Button,
  CircularProgress,
  Alert,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  useMediaQuery,
} from '@mui/material';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import CheckIcon from '@mui/icons-material/Check';
import CircleIcon from '@mui/icons-material/Circle';
import { styled } from '@mui/system';
import MuiAlert from '@mui/material/Alert';
import { useTheme } from '@mui/material/styles'; // Import useTheme and useMediaQuery

// Styled Components
const LessonHeader = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(4),
  textAlign: 'center',
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  padding: theme.spacing(4),
  borderRadius: theme.shape.borderRadius,
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(2),
  },
}));

const LessonTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 'bold',
  fontSize: '2rem',
  [theme.breakpoints.down('sm')]: {
    fontSize: '1.5rem',
  },
}));

const CourseTitle = styled(Typography)(({ theme }) => ({
  fontSize: '1.2rem',
  marginTop: theme.spacing(1),
}));

const StatusChip = styled(({ status, ...other }) => <MuiAlert {...other} />)(({ theme, status }) => ({
  marginTop: theme.spacing(2),
  backgroundColor:
    status === 'live'
      ? theme.palette.success.main
      : status === 'scheduled'
      ? theme.palette.info.main
      : status === 'passed'
      ? theme.palette.grey[500]
      : theme.palette.error.main,
  color: theme.palette.getContrastText(
    status === 'live'
      ? theme.palette.success.main
      : status === 'scheduled'
      ? theme.palette.info.main
      : status === 'passed'
      ? theme.palette.grey[500]
      : theme.palette.error.main
  ),
}));

const StyledButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2),
  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
}));

const BadgeChip = styled(Typography)(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(1),
  left: theme.spacing(1),
  zIndex: 1,
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  padding: theme.spacing(0.5, 1),
  borderRadius: theme.shape.borderRadius,
  fontSize: '0.8rem',
}));

const StyledAlert = styled(Alert)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

const Checkout = () => {
  const { planId } = useParams();
  const navigate = useNavigate();
  const { createSubscription } = useContext(UserContext);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // Determine if device is mobile

  const [plan, setPlan] = useState(null);
  const [loading, setLoading] = useState(true);
  const [fetchError, setFetchError] = useState(null);
  const [formData, setFormData] = useState({
    cardNumber: '',
    expiryDate: '',
    cvv: '',
    cardHolder: '',
  });
  const [formError, setFormError] = useState(null);
  const [success, setSuccess] = useState(false);

  // Fetch plan details
  useEffect(() => {
    const fetchPlan = async () => {
      setLoading(true);
      setFetchError(null);
      try {
        const response = await fetch(`${process.env.REACT_APP_INFINITY_BACK_URL}/api/plans/${planId}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });

        if (response.ok) {
          const data = await response.json();
          setPlan(data);
        } else {
          const errorData = await response.json();
          console.error('כשל בשליפת פרטי התוכנית:', errorData.error || response.statusText);
          setFetchError(errorData.error || 'כשל בשליפת פרטי התוכנית.');
        }
      } catch (err) {
        console.error('שגיאה בשליפת פרטי התוכנית:', err);
        setFetchError('אירעה שגיאה בלתי צפויה בעת שליפת פרטי התוכנית.');
      } finally {
        setLoading(false);
      }
    };

    fetchPlan();
  }, [planId]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const validateForm = () => {
    const { cardNumber, expiryDate, cvv, cardHolder } = formData;
    if (!cardNumber || !expiryDate || !cvv || !cardHolder) {
      return 'כל השדות נדרשים.';
    }
    // Additional validations can be added here
    // Example: Check if card number is 16 digits
    const cardNumberRegex = /^\d{16}$/;
    if (!cardNumberRegex.test(cardNumber)) {
      return 'מספר הכרטיס חייב להיות 16 ספרות.';
    }
    // Example: Check expiry date format MM/YY
    const expiryDateRegex = /^(0[1-9]|1[0-2])\/\d{2}$/;
    if (!expiryDateRegex.test(expiryDate)) {
      return 'תאריך התפוגה חייב להיות בפורמט MM/YY.';
    }
    // Example: Check CVV (3 or 4 digits)
    const cvvRegex = /^\d{3,4}$/;
    if (!cvvRegex.test(cvv)) {
      return 'CVV חייב להיות 3 או 4 ספרות.';
    }
    return null;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const error = validateForm();
    if (error) {
      setFormError(error);
      return;
    }

    try {
      // Add payment processing logic here (e.g., integration with Stripe or another payment service)
      // For demonstration purposes, we'll simulate a successful payment with a timeout
      await new Promise((resolve) => setTimeout(resolve, 2000));

      // After successful payment, create the subscription
      await createSubscription(plan);
      setSuccess(true);
      // Optionally, navigate to a confirmation page
      // navigate('/confirmation');
    } catch (err) {
      console.error('שגיאה בעיבוד התשלום:', err);
      setFormError('אירעה שגיאה בלתי צפויה בעת עיבוד התשלום.');
    }
  };

  if (loading) {
    return (
      <Container sx={{ py: 8, textAlign: 'center' }}>
        <CircularProgress />
      </Container>
    );
  }

  if (fetchError) {
    return (
      <Container>
        <Alert severity="error">{fetchError}</Alert>
        <Box sx={{ mt: 4, textAlign: 'center' }}>
          <Button variant="contained" color="primary" component={Link} to="/all-plans">
            חזרה לתוכניות
          </Button>
        </Box>
      </Container>
    );
  }

  return (
    <main className="checkout">
      <Container>
        <Grid container spacing={4}>
          {/* Plan Details */}
          <Grid item xs={12} md={6}>
            <Card>
              <CardContent>
                <Typography variant="h5" gutterBottom>
                  פרטי התוכנית
                </Typography>
                <List>
                  {/* Plan Title */}
                  <ListItem>
                    <ListItemIcon>
                      <CheckIcon color="primary" />
                    </ListItemIcon>
                    <ListItemText primary={`שם התוכנית: ${plan.title}`} />
                  </ListItem>

                  {/* Plan Description */}
                  <ListItem>
                    <ListItemIcon>
                      <CheckIcon color="primary" />
                    </ListItemIcon>
                    <ListItemText primary={`תיאור: ${plan.description || 'אין תיאור'}`} />
                  </ListItem>

                  {/* Plan Price */}
                  <ListItem>
                    <ListItemIcon>
                      <CheckIcon color="primary" />
                    </ListItemIcon>
                    <ListItemText primary={`מחיר: ₪${plan.price}`} />
                  </ListItem>

                  {/* Plan Features */}
                  {plan.features && plan.features.length > 0 && (
                    <ListItem>
                      <ListItemIcon>
                        <CheckIcon color="primary" />
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          <>
                            <Typography variant="subtitle1">תכונות:</Typography>
                            <List dense>
                              {plan.features.map((feature, index) => (
                                <ListItem key={index}>
                                  <ListItemIcon>
                                    <CheckIcon color="secondary" fontSize="small" />
                                  </ListItemIcon>
                                  <ListItemText primary={feature} />
                                </ListItem>
                              ))}
                            </List>
                          </>
                        }
                      />
                    </ListItem>
                  )}

                  {/* Live Session Limit */}
                  <ListItem>
                    <ListItemIcon>
                      <CheckIcon color="primary" />
                    </ListItemIcon>
                    <ListItemText primary={`סשנים חיים מותרים: ${plan.liveSessionLimit}`} />
                  </ListItem>

                  {/* Plan Courses */}
                  {plan.courses && plan.courses.length > 0 && (
                    <ListItem>
                      <ListItemIcon>
                        <CheckIcon color="primary" />
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          <>
                            <Typography variant="subtitle1">קורסים כלולים:</Typography>
                            <List dense>
                              {plan.courses.map((course, index) => (
                                <ListItem key={index}>
                                  <ListItemIcon>
                                    <CircleIcon color="secondary" fontSize="small" />
                                  </ListItemIcon>
                                  <ListItemText primary={course.title || 'שם הקורס'} />
                                </ListItem>
                              ))}
                            </List>
                          </>
                        }
                      />
                    </ListItem>
                  )}

                  {/* Plan Lessons */}
                  {plan.lessons && plan.lessons.length > 0 && (
                    <ListItem>
                      <ListItemIcon>
                        <CheckIcon color="primary" />
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          <>
                            <Typography variant="subtitle1">שיעורים כלולים:</Typography>
                            <List dense>
                              {plan.lessons.map((lesson, index) => (
                                <ListItem key={lesson._id || index}>
                                  <ListItemIcon>
                                    <CircleIcon color="secondary" fontSize="small" />
                                  </ListItemIcon>
                                  <ListItemText primary={lesson.title || `שיעור ${index + 1}`} />
                                </ListItem>
                              ))}
                            </List>
                          </>
                        }
                      />
                    </ListItem>
                  )}
                </List>
              </CardContent>
            </Card>
          </Grid>

          {/* Payment Form */}
          <Grid item xs={12} md={6}>
            <Paper sx={{ p: 4 }}>
              <Typography variant="h5" gutterBottom>
                פרטי תשלום
              </Typography>
              {formError && (
                <Alert severity="error" sx={{ mb: 2 }}>
                  {formError}
                </Alert>
              )}
              {success ? (
                <Alert severity="success">
                  תודה! המנוי שלך נוצר בהצלחה. תקבל אישור בקרוב.
                </Alert>
              ) : (
                <Box component="form" onSubmit={handleSubmit} noValidate>
                  <TextField
                    label="שם בעל הכרטיס"
                    name="cardHolder"
                    variant="outlined"
                    fullWidth
                    required
                    value={formData.cardHolder}
                    onChange={handleChange}
                    sx={{ mb: 2 }}
                    InputProps={{
                      style: { fontSize: isMobile ? 16 : undefined },
                    }}
                    InputLabelProps={{
                      style: { fontSize: isMobile ? 16 : undefined },
                    }}
                    FormHelperTextProps={{
                      style: { fontSize: isMobile ? 16 : undefined },
                    }}
                  />
                  <TextField
                    label="מספר כרטיס"
                    name="cardNumber"
                    variant="outlined"
                    fullWidth
                    required
                    value={formData.cardNumber}
                    onChange={handleChange}
                    inputProps={{ maxLength: 16 }}
                    sx={{ mb: 2 }}
                    InputProps={{
                      style: { fontSize: isMobile ? 16 : undefined },
                    }}
                    InputLabelProps={{
                      style: { fontSize: isMobile ? 16 : undefined },
                    }}
                    FormHelperTextProps={{
                      style: { fontSize: isMobile ? 16 : undefined },
                    }}
                    helperText="הכנס 16 ספרות ללא רווחים או מקפים"
                  />
                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        label="תאריך תפוגה"
                        name="expiryDate"
                        variant="outlined"
                        fullWidth
                        required
                        value={formData.expiryDate}
                        onChange={handleChange}
                        placeholder="חודש/שנה (MM/YY)"
                        InputProps={{
                          style: { fontSize: isMobile ? 16 : undefined },
                        }}
                        InputLabelProps={{
                          style: { fontSize: isMobile ? 16 : undefined },
                        }}
                        FormHelperTextProps={{
                          style: { fontSize: isMobile ? 16 : undefined },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        label="CVV"
                        name="cvv"
                        variant="outlined"
                        fullWidth
                        required
                        value={formData.cvv}
                        onChange={handleChange}
                        InputProps={{
                          style: { fontSize: isMobile ? 16 : undefined },
                        }}
                        InputLabelProps={{
                          style: { fontSize: isMobile ? 16 : undefined },
                        }}
                        FormHelperTextProps={{
                          style: { fontSize: isMobile ? 16 : undefined },
                        }}
                        helperText="3 או 4 ספרות"
                      />
                    </Grid>
                  </Grid>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                    startIcon={<CreditCardIcon />}
                    sx={{
                      textTransform: 'none',
                      borderRadius: '50px',
                      boxShadow: 2,
                      transition: 'background-color 0.3s ease',
                      '&:hover': {
                        backgroundColor: 'primary.dark',
                      },
                    }}
                  >
                    אישור תשלום
                  </Button>
                </Box>
              )}
            </Paper>
          </Grid>
        </Grid>

        {/* Back to Plans Button */}
        {!success && (
          <Box sx={{ mt: 4, textAlign: 'center' }}>
            <Button variant="outlined" color="primary" component={Link} to="/all-plans">
              חזרה לתוכניות
            </Button>
          </Box>
        )}
      </Container>
    </main>
  );
};

export default Checkout;
