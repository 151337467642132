import React, { useState } from 'react';
import {
  Box,
  Typography,
  Card,
  CardContent,
  Collapse,
  IconButton,
  Chip,
  Divider,
  Button,
  Grid,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import VideoPlayer from '../Widgets/VideoPlayer';

// Styled Components
const StyledCard = styled(Card)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  transition: 'box-shadow 0.3s ease-in-out',
  '&:hover': {
    boxShadow: theme.shadows[4],
  },
}));

const LevelChip = styled(Chip)(({ level, theme }) => {
  const colors = {
    beginner: theme.palette.success.main,
    intermediate: theme.palette.warning.main,
    advanced: theme.palette.error.main,
  };
  return {
    backgroundColor: colors[level] || theme.palette.grey[500],
    color: theme.palette.common.white,
    '& .MuiChip-label': {
      fontWeight: 600,
    },
  };
});

const SolutionButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
}));

const HomeworkSection = ({ questions = [] }) => {
  const [expandedId, setExpandedId] = useState(null);
  const [showSolution, setShowSolution] = useState({});

  const handleExpandClick = (id) => {
    setExpandedId(expandedId === id ? null : id);
  };

  const handleToggleSolution = (questionId) => {
    setShowSolution(prev => ({
      ...prev,
      [questionId]: !prev[questionId]
    }));
  };

  return (
    <Box sx={{ mb: 4 }}>
      <Typography variant="h5" gutterBottom>
        שיעורי בית
      </Typography>

      {questions.length > 0 ? (
        questions.map((question, index) => (
          <StyledCard key={question._id}>
            <CardContent>
              {/* Question Header */}
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                <Box>
                  <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                    <Chip
                      label={`שאלה ${index + 1}`}
                      color="secondary"
                      size="small"
                      sx={{ mr: 2 }}
                    />
                    <Typography variant="h6">{question.title}</Typography>
                  </Box>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mt: 1 }}>
                    <LevelChip
                      label={question.level}
                      level={question.level}
                      size="small"
                    />
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <AccessTimeIcon sx={{ fontSize: 'small', mr: 0.5 }} />
                      <Typography variant="body2" color="text.secondary">
                        {question.estimatedTime} דקות
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                <IconButton
                  onClick={() => handleExpandClick(question._id)}
                  aria-expanded={expandedId === question._id}
                  aria-label="show more"
                >
                  {expandedId === question._id ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </IconButton>
              </Box>

              {/* Question Preview */}
              <Typography
                variant="body2"
                color="text.secondary"
                sx={{
                  mt: 2,
                  display: expandedId === question._id ? 'none' : '-webkit-box',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  WebkitLineClamp: 2,
                  WebkitBoxOrient: 'vertical',
                }}
              >
                {question.description}
              </Typography>

              {/* Expanded Content */}
              <Collapse in={expandedId === question._id} timeout="auto" unmountOnExit>
                <Box sx={{ mt: 2 }}>
                  <Divider sx={{ my: 2 }} />
                  
                  {/* Full Description */}
                  <Typography variant="body1" paragraph>
                    {question.description}
                  </Typography>

                  {/* Question Image */}
                  {question.image?.url && (
                    <Box sx={{ my: 2 }}>
                      <img
                        src={question.image.url}
                        alt={question.image.alt || question.title}
                        style={{
                          maxWidth: '100%',
                          height: 'auto',
                          borderRadius: theme => theme.shape.borderRadius,
                        }}
                      />
                    </Box>
                  )}

                  {/* Solution Button */}
                  <SolutionButton
                    variant="contained"
                    color="primary"
                    startIcon={<LightbulbIcon />}
                    onClick={() => handleToggleSolution(question._id)}
                    fullWidth
                  >
                    {showSolution[question._id] ? 'הסתר פתרון' : 'הצג פתרון'}
                  </SolutionButton>

                  {/* Answer Section */}
                  <Collapse in={showSolution[question._id]} timeout="auto">
                    <Box sx={{ mt: 3, bgcolor: 'background.default', p: 2, borderRadius: 1 }}>
                      <Typography variant="h6" gutterBottom>
                        תשובה והסבר
                      </Typography>
                      <Typography variant="body1" paragraph>
                        {question.explanation.answerText}
                      </Typography>

                      {/* Video Explanation */}
                      {question.explanation.videoUrl && (
                        <Box sx={{ mt: 3 }}>
                          <Typography variant="h6" gutterBottom>
                            הסבר מוקלט
                          </Typography>
                          <VideoPlayer 
                            videoUrl={question.explanation.videoUrl}
                            title={`הסבר מוקלט לשאלה ${index + 1}`}
                          />
                        </Box>
                      )}
                    </Box>
                  </Collapse>
                </Box>
              </Collapse>
            </CardContent>
          </StyledCard>
        ))
      ) : (
        <Typography variant="body2" color="text.secondary">
          אין שיעורי בית זמינים לשיעור זה.
        </Typography>
      )}
    </Box>
  );
};

export default HomeworkSection;