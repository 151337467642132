// src/components/StudyMaterial.js

import React, { useEffect, useState, useContext } from 'react';
import { useParams, Link as RouterLink } from 'react-router-dom';
import { DataContext } from '../../context/DataContext';
import {
  Container,
  Typography,
  CircularProgress,
  Alert,
  Grid,
  Card,
  CardMedia,
  CardContent,
  Box,
  List,
  ListItem,
  ListItemText,
  Button,
  Divider,
  Paper,
} from '@mui/material';
import { styled } from '@mui/system';
import LaunchIcon from '@mui/icons-material/Launch';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import VideoPlayer from '../Widgets/VideoPlayer';

const StudyMaterial = () => {
  const { id , courseId } = useParams();
  const { studyMaterials, loading, error: dataError } = useContext(DataContext);

  const [studyMaterial, setStudyMaterial] = useState(null);

  useEffect(() => {
    if (loading) return;

    if (dataError) {
      console.error('Error in DataContext:', dataError);
      return;
    }

    // Find the study material by ID
    const material = studyMaterials.find((sm) => sm._id === id);

    if (material) {
      setStudyMaterial(material);
    } else {
      setStudyMaterial(null);
      console.warn('Study material not found in context.');
    }
  }, [id, studyMaterials, loading, dataError]);

  if (loading) {
    return (
      <Container >
        <Box sx={{ textAlign: 'center' }}>
          <CircularProgress />
          <Typography variant="body1" sx={{ mt: 2 }}>
            טוען חומר לימוד...
          </Typography>
        </Box>
      </Container>
    );
  }

  if (dataError) {
    return (
      <Container >
        <Alert severity="error">{dataError}</Alert>
      </Container>
    );
  }

  if (!studyMaterial) {
    return (
      <Container >
        <Typography variant="h6">חומר הלימוד לא נמצא.</Typography>
      </Container>
    );
  }

  return (
    <Container >
      <Grid container spacing={4}>
        {/* Image Section */}
        {studyMaterial.imageUrl && (
          <Grid item xs={12}>
            <Card>
              <CardMedia
                component="img"
                image={studyMaterial.imageUrl}
                alt={studyMaterial.title}
                height="400"
              />
            </Card>
          </Grid>
        )}

        {/* Title and Description */}
        <Grid item xs={12}>
          <Typography variant="h3" gutterBottom>
            {studyMaterial.title}
          </Typography>
          <Typography variant="body1" gutterBottom>
            {studyMaterial.description}
          </Typography>
        </Grid>

        {/* Explanation */}
        {studyMaterial.explanation && (
          <Grid item xs={12}>
            <Typography variant="h4" gutterBottom>
              הסבר
            </Typography>
            <Typography variant="body1" gutterBottom>
              {studyMaterial.explanation}
            </Typography>
          </Grid>
        )}

        {/* Explanation Videos */}
        {studyMaterial.explanationVideos && studyMaterial.explanationVideos.length > 0 && (
          <Grid item xs={12}>
            <Typography variant="h4" gutterBottom>
              סרטוני הסבר
            </Typography>
            <Grid container spacing={2}>
              {studyMaterial.explanationVideos.map((videoUrl, index) => (
                <Grid item xs={12} md={6} key={index}>
                  <VideoPlayer videoUrl={videoUrl} title={`Explanation Video ${index + 1}`} />
                </Grid>
              ))}
            </Grid>
          </Grid>
        )}

        {/* Practice Videos */}
        {studyMaterial.practiceVideos && studyMaterial.practiceVideos.length > 0 && (
          <Grid item xs={12}>
            <Typography variant="h4" gutterBottom>
              סרטוני תרגול
            </Typography>
            <Grid container spacing={2}>
              {studyMaterial.practiceVideos.map((videoUrl, index) => (
                <Grid item xs={12} md={6} key={index}>
                  <VideoPlayer videoUrl={videoUrl} title={`Practice Video ${index + 1}`} />
                </Grid>
              ))}
            </Grid>
          </Grid>
        )}

        {/* Formulas */}
        {studyMaterial.formulas && studyMaterial.formulas.length > 0 && (
          <Grid item xs={12}>
            <Typography variant="h4" gutterBottom>
              נוסחאות
            </Typography>
            <List>
              {studyMaterial.formulas.map((formulaObj, index) => (
                <ListItem key={index}>
                  <ListItemText
                    primary={`נוסחה ${index + 1}`}
                    secondary={
                      <>
                        <Typography variant="body1" sx={{ fontFamily: 'monospace' }}>
                          {formulaObj.formula}
                        </Typography>
                        {formulaObj.reel && (
                          <Button
                            size="small"
                            color="primary"
                            href={formulaObj.reel}
                            target="_blank"
                            rel="noopener noreferrer"
                            startIcon={<LaunchIcon />}
                            sx={{ mt: 1 }}
                          >
                            להרחבה
                          </Button>
                        )}
                      </>
                    }
                  />
                </ListItem>
              ))}
            </List>
          </Grid>
        )}

        {/* Theorems */}
        {studyMaterial.theorems && studyMaterial.theorems.length > 0 && (
          <Grid item xs={12}>
            <Typography variant="h4" gutterBottom>
              משפטים
            </Typography>
            <List>
              {studyMaterial.theorems.map((theoremObj, index) => (
                <ListItem key={index}>
                  <ListItemText
                    primary={`משפט ${index + 1}`}
                    secondary={
                      <>
                        <Typography variant="body1">
                          <CheckCircleIcon color="success" sx={{ mr: 1 }} />
                          {theoremObj.theorem}
                        </Typography>
                        {theoremObj.reel && (
                          <Button
                            size="small"
                            color="primary"
                            href={theoremObj.reel}
                            target="_blank"
                            rel="noopener noreferrer"
                            startIcon={<LaunchIcon />}
                            sx={{ mt: 1 }}
                          >
                            להרחבה
                          </Button>
                        )}
                      </>
                    }
                  />
                </ListItem>
              ))}
            </List>
          </Grid>
        )}

        {/* Learning Objectives */}
        {studyMaterial.learningObjectives && studyMaterial.learningObjectives.length > 0 && (
          <Grid item xs={12}>
            <Typography variant="h4" gutterBottom>
              מטרות למידה
            </Typography>
            <List>
              {studyMaterial.learningObjectives.map((objective, index) => (
                <ListItem key={index}>
                  <ListItemText primary={`מטרה ${index + 1}`} secondary={objective} />
                </ListItem>
              ))}
            </List>
          </Grid>
        )}

        {/* Prerequisites */}
        {studyMaterial.prerequisites && studyMaterial.prerequisites.length > 0 && (
          <Grid item xs={12}>
            <Typography variant="h4" gutterBottom>
              דרישות קדם
            </Typography>
            <List>
              {studyMaterial.prerequisites.map((prereqId) => {
                const prereqMaterial = studyMaterials.find((sm) => sm._id === prereqId);
                if (prereqMaterial) {
                  return (
                    <ListItem key={prereqId}>
                      <ListItemText
                        primary={prereqMaterial.title}
                        secondary={
                          <Button
                            size="small"
                            color="primary"
                            component={RouterLink}
                            to={`/study-materials/${prereqId}`}
                            startIcon={<LaunchIcon />}
                          >
                            הצג
                          </Button>
                        }
                      />
                    </ListItem>
                  );
                } else {
                  return null;
                }
              })}
            </List>
          </Grid>
        )}

        {/* Related Materials */}
        {studyMaterial.relatedMaterials && studyMaterial.relatedMaterials.length > 0 && (
          <Grid item xs={12}>
            <Typography variant="h4" gutterBottom>
              חומרי לימוד קשורים
            </Typography>
            <List>
              {studyMaterial.relatedMaterials.map((relatedMaterialId) => {
                const relatedMaterial = studyMaterials.find((sm) => sm._id === relatedMaterialId);
                if (relatedMaterial) {
                  return (
                    <ListItem key={relatedMaterial._id}>
                      <ListItemText
                        primary={relatedMaterial.title}
                        secondary={
                          <Button
                            size="small"
                            color="primary"
                            component={RouterLink}
                            to={`/study-materials/${relatedMaterial._id}`}
                            startIcon={<LaunchIcon />}
                          >
                            הצג
                          </Button>
                        }
                      />
                    </ListItem>
                  );
                } else {
                  return null;
                }
              })}
            </List>
          </Grid>
        )}

        {/* References */}
        {studyMaterial.references && studyMaterial.references.length > 0 && (
          <Grid item xs={12}>
            <Typography variant="h4" gutterBottom>
              מקורות
            </Typography>
            <List>
              {studyMaterial.references.map((ref, index) => (
                <ListItem key={index}>
                  <ListItemText>
                    <Button
                      size="small"
                      color="primary"
                      href={ref.startsWith('http') ? ref : '#'}
                      target="_blank"
                      rel="noopener noreferrer"
                      startIcon={<LaunchIcon />}
                    >
                      מקור {index + 1}
                    </Button>
                  </ListItemText>
                </ListItem>
              ))}
            </List>
          </Grid>
        )}

        {/* Common Mistakes */}
        {studyMaterial.commonMistakes && studyMaterial.commonMistakes.length > 0 && (
          <Grid item xs={12}>
            <Typography variant="h4" gutterBottom>
              טעויות נפוצות
            </Typography>
            <List>
              {studyMaterial.commonMistakes.map((mistake, index) => (
                <ListItem key={index}>
                  <ListItemText primary={`טעות ${index + 1}`} secondary={mistake} />
                </ListItem>
              ))}
            </List>
          </Grid>
        )}

        {/* Back Button */}
        <Grid item xs={12}>
          <Box sx={{ textAlign: 'center', mt: 4 }}>
            <Button
              variant="contained"
              color="primary"
              component={RouterLink}
              to={`/course/${courseId}/details`}
              startIcon={<PlayCircleOutlineIcon />}
            >
              חזרה לקורס/שיעור
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default StudyMaterial;
