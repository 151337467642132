// src/components/Layout.js

import React from 'react';
import { Outlet } from 'react-router-dom';
import Footer from './Footer';
import NavBar from './NavBar';
import { Box } from '@mui/material';
import AccessibilitySidebar from './AccessibilitySidebar';
import ScrollToTop from './ScrollToTop';

const Layout = () => {
  return (
    <Box
      sx={{
        display: 'flex',          // Defines a flex container
        flexDirection: 'column', // Arranges children vertically
        minHeight: '100vh',      // Ensures the container spans the full viewport height
        position: 'relative',    // Ensures positioned children are relative to this container
      }}
    >
      {/* Navigation Bar */}
      <NavBar />

      {/* Main Content Area */}
      <Box
        component="main"
        sx={{
          flexGrow: 1, // Allows this box to expand and fill available space
          p: { xs: 2, sm: 3 }, // Responsive padding
        }}
      >
        <Outlet /> {/* Renders the matched child route component */}
      </Box>

      {/* Footer */}
      <Footer />

      {/* Accessibility Sidebar */}
      <AccessibilitySidebar />

      {/* Scroll to Top */}
      <ScrollToTop />
    </Box>
  );
};

export default Layout;
