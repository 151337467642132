// src/components/Home.js
import React, { useContext } from 'react';
import { Container, Grid, Box, Typography } from '@mui/material';
import UpcomingLessonWidget from './UpcomingLessonWidget'; // Import the new widget
import InfoCard from './InfoCard';
import infoCards from '../../infoCards';
import { UserContext } from '../../context/UserContext';
import FormulasThermoes from '../Widgets/formulasThermoes';

const Home = () => {
  const { user } = useContext(UserContext);
  return (
    <>
      <main className="home">
        <Container sx={{ py: { xs: 4, sm: 6 } }}>
          {/* Introduction Section */}
          <Box sx={{ textAlign: 'center', mb: { xs: 4, sm: 8 } }}>
            <Typography
              variant="h3"
              component="h1"
              gutterBottom
              sx={{ fontSize: { xs: '2rem', sm: '3rem' } }}
            >
              {user.fullName} ברוך הבא ל-Infinity
            </Typography>
            <Typography
              variant="h6"
              color="textSecondary"
              sx={{ fontSize: { xs: '1rem', sm: '1.25rem' } }}
            >
              העצמת תלמידים להיות הגרסה הטובה ביותר של עצמם באמצעות פתרונות למידה חדשניים.
            </Typography>
          </Box>
        </Container> {/* Corrected closing tag */}

        <Container sx={{ py: { xs: 4, sm: 6 } }}>
          {/* Information Cards Section */}
          <Grid container spacing={4}>
            {infoCards.map((card, index) => (
              <Grid item key={index} xs={12} sm={6} md={4}>
                <InfoCard title={card.title} description={card.description} imagePath={card.path} />
              </Grid>
            ))}
          </Grid>
        </Container>
        <Container sx={{ py: { xs: 4, sm: 6 } }}>
          {/* Upcoming Lesson Widget */}
          <Box sx={{ mb: { xs: 4, sm: 8 } }}>
            <Typography
              variant="h4"
              gutterBottom
              sx={{ textAlign: 'center', fontSize: { xs: '1.5rem', sm: '2rem' } }}
            >
            השיעורים הבאים
            </Typography>
            
            <UpcomingLessonWidget />
          </Box>

          {/* Explore Reels Section (Commented Out) */}
          {/* 
          <Box sx={{ mb: { xs: 4, sm: 8 } }}>
            <Typography variant="h4" gutterBottom sx={{ textAlign: 'center', fontSize: { xs: '1.5rem', sm: '2rem' } }}>
              חקור חומרי לימוד
            </Typography>
            <ExploreReels />
          </Box> 
          */}
        </Container>
      </main>
    </>
  );
};

export default Home;