// src/components/MyStudyMaterialList.js

import React, { useEffect, useState, useContext, useCallback } from 'react';
import {
  Container,
  Typography,
  CircularProgress,
  Alert,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
  Card,
  CardMedia,
  CardContent,
  CardActions,
  Button,
  Badge,
} from '@mui/material';
import { styled } from '@mui/system';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Link as RouterLink } from 'react-router-dom';
import { UserContext } from '../../context/UserContext';

// Styled Badge for Difficulty Level
const DifficultyBadge = styled(Badge)(({ theme, level }) => ({
  '& .MuiBadge-badge': {
    right: -3,
    top: 13,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
    backgroundColor:
      level === 'קל'
        ? theme.palette.success.main
        : level === 'בינוני'
        ? theme.palette.warning.main
        : theme.palette.error.main,
    color: theme.palette.common.white,
  },
}));

const MyStudyMaterialList = () => {
  const { user } = useContext(UserContext);

  const [courses, setCourses] = useState([]);
  const [loading, setLoading] = useState(false);
  const [dataError, setError] = useState(null);

  const fetchMyStudyMaterials = useCallback(async () => {
    if (!user || !user._id) {
      setError('משתמש לא מחובר.');
      return;
    }

    setLoading(true);
    setError(null);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_INFINITY_BACK_URL}/api/studyMaterials/my/${user._id}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        console.log('Fetched Courses:', data.courses);
        setCourses(data.courses || []);
      } else {
        const errorData = await response.json();
        console.error('Failed to fetch study materials:', errorData.message || response.statusText);
        setError(errorData.message || 'Failed to fetch study materials.');
      }
    } catch (err) {
      console.error('Error fetching study materials:', err);
      setError('אירעה שגיאה בלתי צפויה בעת הבאת חומרי הלימוד.');
    } finally {
      setLoading(false);
    }
  }, [user]);

  useEffect(() => {
    fetchMyStudyMaterials();
  }, [fetchMyStudyMaterials]);

  if (loading) {
    return (
      <Container >
        <Box sx={{ textAlign: 'center' }}>
          <CircularProgress />
          <Typography variant="body1" sx={{ mt: 2 }}>
            טוען חומרי לימוד...
          </Typography>
        </Box>
      </Container>
    );
  }

  if (dataError) {
    return (
      <Container >
        <Alert severity="error">{dataError}</Alert>
      </Container>
    );
  }

  if (courses.length === 0) {
    return (
      <Container >
        <Typography variant="h6">לא נמצאו חומרי לימוד.</Typography>
      </Container>
    );
  }

  return (
    <Container >
      <Typography variant="h4" gutterBottom align="center">
        חומרי הלימוד שלי
      </Typography>

      {courses.map((course) => (
        <Accordion key={course._id}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`panel-${course._id}-content`}
            id={`panel-${course._id}-header`}
          >
            <Typography variant="h6">{course.title}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {/* Course Description */}
            {course.description && (
              <Typography variant="body1" gutterBottom>
                {course.description}
              </Typography>
            )}

            {/* Units within the Course */}
            {course.units && course.units.length > 0 && (
              <>
                <Typography variant="subtitle1" gutterBottom sx={{ mt: 4 }}>
                  יחידות:
                </Typography>
                {course.units.map((unit, unitIndex) => (
                  <Accordion key={unitIndex} sx={{ mb: 2 }}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls={`unit-${unitIndex}-content`}
                      id={`unit-${unitIndex}-header`}
                    >
                      <Typography variant="subtitle1">{unit.title}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      {/* Unit Description */}
                      {unit.description && (
                        <Typography variant="body2" gutterBottom>
                          {unit.description}
                        </Typography>
                      )}

                      {/* Roadmap Lessons */}
                      {unit.roadmap && unit.roadmap.length > 0 && (
                        <>
                          {unit.roadmap
                            .sort((a, b) => a.order - b.order)
                            .map((roadmapItem, roadmapIndex) => (
                              <Box key={roadmapIndex} sx={{ mb: 3 }}>

                                {/* הצגת חומרי הלימוד של השיעור */}
                                {roadmapItem.lesson.mainStudyMaterial && (
                                  <Box sx={{ mt: 1 }}>
                                    <Card
                                      sx={{
                                        display: 'flex',
                                        mb: 2,
                                        boxShadow: 1,
                                        borderRadius: 1,
                                      }}
                                    >
                                      {roadmapItem.lesson.mainStudyMaterial.imageUrl && (
                                        <CardMedia
                                          component="img"
                                          image={roadmapItem.lesson.mainStudyMaterial.imageUrl}
                                          alt={roadmapItem.lesson.mainStudyMaterial.title}
                                          sx={{ width: 160 }}
                                        />
                                      )}
                                      <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
                                        <CardContent>
                                          <Typography variant="h6">
                                            {roadmapItem.lesson.mainStudyMaterial.title}
                                          </Typography>
                                          <Typography variant="body2">
                                            {roadmapItem.lesson.mainStudyMaterial.description &&
                                              roadmapItem.lesson.mainStudyMaterial.description.length > 100
                                              ? `${roadmapItem.lesson.mainStudyMaterial.description.substring(0, 100)}...`
                                              : roadmapItem.lesson.mainStudyMaterial.description}
                                          </Typography>
                                        </CardContent>
                                        <CardActions>
                                          <Button
                                            size="small"
                                            color="primary"
                                            component={RouterLink}
                                            to={`/study-material/${roadmapItem.lesson.mainStudyMaterial._id}/${course._id}`}
                                            startIcon={<VisibilityIcon />}
                                          >
                                            הצג
                                          </Button>
                                          {roadmapItem.lesson.mainStudyMaterial.explanationVideos &&
                                            roadmapItem.lesson.mainStudyMaterial.explanationVideos.length > 0 && (
                                              <Button
                                                size="small"
                                                color="secondary"
                                                component={RouterLink}
                                                to={`/study-material/${roadmapItem.lesson.mainStudyMaterial._id}/${course._id}`}
                                                startIcon={<PlayCircleOutlineIcon />}
                                              >
                                                צפייה
                                              </Button>
                                            )}
                                        </CardActions>
                                      </Box>
                                    </Card>
                                  </Box>
                                )}

                                {/* Supplementary Materials */}
                                {roadmapItem.lesson.supplementaryMaterials &&
                                  roadmapItem.lesson.supplementaryMaterials.length > 0 && (
                                    <Box sx={{ mt: 1 }}>
                                      <Typography variant="subtitle2">חומרים משלימים:</Typography>
                                      <Grid container spacing={2}>
                                        {roadmapItem.lesson.supplementaryMaterials.map((suppMat) => (
                                          <Grid item key={suppMat._id} xs={12} sm={6} md={4}>
                                            <Card
                                              sx={{
                                                height: '100%',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                boxShadow: 1,
                                                borderRadius: 1,
                                              }}
                                            >
                                              {/* Image */}
                                              {suppMat.imageUrl && (
                                                <CardMedia
                                                  component="img"
                                                  image={suppMat.imageUrl}
                                                  alt={suppMat.title}
                                                  height="140"
                                                />
                                              )}

                                              <CardContent sx={{ flexGrow: 1 }}>
                                                <Typography gutterBottom variant="h6" component="h3">
                                                  {suppMat.title}
                                                </Typography>
                                                <Typography variant="body2">
                                                  {suppMat.description && suppMat.description.length > 80
                                                    ? `${suppMat.description.substring(0, 80)}...`
                                                    : suppMat.description}
                                                </Typography>
                                              </CardContent>

                                              <CardActions>
                                                <Button
                                                  size="small"
                                                  color="primary"
                                                  component={RouterLink}
                                                  to={`/study-material/${suppMat._id}/${course._id}`}
                                                  startIcon={<VisibilityIcon />}
                                                >
                                                  הצג
                                                </Button>
                                                {suppMat.explanationVideos && suppMat.explanationVideos.length > 0 && (
                                                  <Button
                                                    size="small"
                                                    color="secondary"
                                                    component={RouterLink}
                                                    to={`/study-material/${suppMat._id}/${course._id}`}
                                                    startIcon={<PlayCircleOutlineIcon />}
                                                  >
                                                    צפייה
                                                  </Button>
                                                )}
                                              </CardActions>

                                              {/* Difficulty Badge */}
                                              <DifficultyBadge
                                                badgeContent={suppMat.difficultyLevel}
                                                level={suppMat.difficultyLevel}
                                                overlap="rectangular"
                                                anchorOrigin={{
                                                  vertical: 'top',
                                                  horizontal: 'right',
                                                }}
                                              >
                                                {/* Empty box to position the badge */}
                                                <Box sx={{ width: 0, height: 0 }} />
                                              </DifficultyBadge>
                                            </Card>
                                          </Grid>
                                        ))}
                                      </Grid>
                                    </Box>
                                  )}
                              </Box>
                            ))}
                        </>
                      )}
                    </AccordionDetails>
                  </Accordion>
                ))}
              </>
            )}
          </AccordionDetails>
        </Accordion>
      ))}
    </Container>
  );
};

export default MyStudyMaterialList;
