// src/components/PrivacyPolicy.js

import React from 'react';
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/system';

const Container = styled(Box)(({ theme }) => ({
  maxWidth: '800px',
  margin: '0 auto',
  padding: theme.spacing(4),
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[3],
  borderRadius: theme.shape.borderRadius,
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(6),
  },
  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(8),
  },
  textAlign: 'left',
}));

const SectionTitle = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(4),
  marginBottom: theme.spacing(2),
  fontWeight: 'bold',
  fontSize: {
    xs: '1.5rem',
    sm: '1.8rem',
    md: '2rem',
  },
  color: theme.palette.primary.main,
  borderBottom: `2px solid ${theme.palette.divider}`,
  paddingBottom: theme.spacing(1),
}));

const Paragraph = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(3),
  fontSize: {
    xs: '1rem',
    sm: '1.1rem',
    md: '1.2rem',
  },
  lineHeight: 1.8,
  color: theme.palette.text.primary,
}));

function PrivacyPolicy() {
  return (
    <main className="privacy-policy">
      <Box sx={{ py: 8, backgroundColor: '#f9f9f9' }}>
        <Typography variant="h3" gutterBottom sx={{
          fontSize: {
            xs: '2rem',
            sm: '2.5rem',
            md: '3rem',
          },
          fontWeight: 'bold',
          color: 'primary.main',
          textAlign: 'center',
        }}>
          מדיניות פרטיות
        </Typography>
        <Container>
          <Paragraph variant="body1">
            באינפיניטי לימרינג, אנו מחויבים להגנה על פרטיותך. מדיניות פרטיות זו מפרטת כיצד אנו אוספים, משתמשים ומגנים על המידע האישי שלך כאשר אתה משתמש בשירותינו.
          </Paragraph>
          <SectionTitle variant="h5">
            המידע שאנו אוספים
          </SectionTitle>
          <Paragraph variant="body1">
            אנו עשויים לאסוף מידע אישי כגון שמך, כתובת הדואר האלקטרוני שלך, פרטי יצירת קשר וכל מידע אחר שתספק מרצונך בעת ההרשמה לשירותינו או בתקשורת איתנו.
          </Paragraph>
          <SectionTitle variant="h5">
            כיצד אנו משתמשים במידע שלך
          </SectionTitle>
          <Paragraph variant="body1">
            המידע שאנו אוספים משמש לספק ולשפר את שירותינו, לתקשר איתך, לעבד עסקאות ולהתאים את החוויה שלך עם אינפיניטי לימרינג.
          </Paragraph>
          <SectionTitle variant="h5">
            שיתוף וגילוי מידע
          </SectionTitle>
          <Paragraph variant="body1">
            אנו לא מוכרים, סוחרים או משכירים את המידע האישי שלך לצדדים שלישיים. אנו עשויים לשתף מידע עם שותפים מהימנים המסייעים לנו בתפעול האתר ובניהול העסק שלנו, בתנאי שהצדדים הללו מסכימים לשמור על סודיות המידע.
          </Paragraph>
          <SectionTitle variant="h5">
            אבטחה
          </SectionTitle>
          <Paragraph variant="body1">
            אנו מיישמים מגוון אמצעי אבטחה כדי לשמור על בטיחות המידע האישי שלך. עם זאת, יש לקחת בחשבון שאין שיטת העברה דרך האינטרנט או אחסון אלקטרוני שהיא בטוחה ב-100%.
          </Paragraph>
          <SectionTitle variant="h5">
            קובצי קוקי
          </SectionTitle>
          <Paragraph variant="body1">
            האתר שלנו עשוי להשתמש ב"קובצי קוקי" לשיפור חוויית המשתמש. דפדפן האינטרנט שלך מאחסן קובצי קוקי בכונן הקשיח שלך למטרות ניהול רישומים ולפעמים למעקב אחר מידע אודותיך.
          </Paragraph>
          <SectionTitle variant="h5">
            שינויים במדיניות פרטיות זו
          </SectionTitle>
          <Paragraph variant="body1">
            אנו שומרים לעצמנו את הזכות לעדכן או לשנות את מדיניות הפרטיות שלנו בכל עת. כל שינוי יפורסם בדף זה עם תאריך עדכון חדש.
          </Paragraph>
          <SectionTitle variant="h5">
            צור קשר
          </SectionTitle>
          <Paragraph variant="body1">
            אם יש לך שאלות לגבי מדיניות פרטיות זו, אנא צור קשר בכתובת <a href="mailto:privacy@infinitelearning.com">privacy@infinitelearning.com</a>.
          </Paragraph>
        </Container>
      </Box>
    </main>
  );
}

export default PrivacyPolicy;
