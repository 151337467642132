// src/components/NotFound.js

import { Box, Typography, Button } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import { styled } from '@mui/system';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import HomeIcon from '@mui/icons-material/Home';

const Container = styled(Box)(({ theme }) => ({
  textAlign: 'center',
  padding: theme.spacing(6, 2),
  backgroundColor: theme.palette.background.default,
  minHeight: '80vh',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(4),
  padding: theme.spacing(1.5, 3),
  fontSize: '1rem',
  borderRadius: theme.shape.borderRadius,
  textTransform: 'none',
  boxShadow: theme.shadows[2],
  transition: 'background-color 0.3s ease, transform 0.3s ease',
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
    transform: 'translateY(-2px)',
  },
}));

function NotFound() {
  return (
    <main>
      <Container>
        <ErrorOutlineIcon sx={{ fontSize: 80, color: 'error.main', mb: 2 }} />
        <Typography variant="h2" gutterBottom sx={{
          fontSize: {
            xs: '2.5rem',
            sm: '3rem',
            md: '3.5rem',
          },
          fontWeight: 'bold',
          color: 'primary.main',
        }}>
          הדף לא נמצא
        </Typography>
        <Typography variant="h6" gutterBottom sx={{
          fontSize: {
            xs: '1.2rem',
            sm: '1.4rem',
            md: '1.6rem',
          },
          color: 'text.secondary',
        }}>
          אנו מצטערים, הדף שביקשת לא נמצא.
        </Typography>
        <StyledButton
          variant="contained"
          color="primary"
          component={Link}
          to="/"
          startIcon={<HomeIcon />}
        >
          חזרה לדף הבית
        </StyledButton>
      </Container>
    </main>
  );
}

export default NotFound;
