// src/components/Navbar.js
import React, { useContext, useState } from 'react';
import {
  Box,
  CssBaseline,
  Drawer,
  Toolbar,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
  Typography,
  BottomNavigation,
  BottomNavigationAction,
  useMediaQuery,
  IconButton,
} from '@mui/material';
import {
  Home as HomeIcon,
  MenuBook as MenuBookIcon,
  Assignment as AssignmentIcon,
  Info as InfoIcon,
  ContactMail as ContactMailIcon,
  Policy as PolicyIcon,
  Person as PersonIcon,
  ExpandLess,
  ExpandMore,
  LibraryBooks as LibraryBooksIcon,
  School as SchoolIcon,
  Subscriptions as SubscriptionsIcon,
  Brightness4 as Brightness4Icon,
  Close as CloseIcon, // שימוש ב־CloseIcon לסגירת התפריט
  Menu as MenuIcon
} from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';
import { Link, useLocation } from 'react-router-dom';
import {
  SignedIn,
  SignedOut,
  SignInButton,
  UserButton,
  useUser,
} from '@clerk/clerk-react';
import { ThemeContext } from '../../context/ThemeContext';

const drawerWidth = 260;

const Navbar = () => {
  const theme = useTheme();
  const { toggleTheme } = useContext(ThemeContext);
  const { isSignedIn } = useUser();
  const location = useLocation();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [openPersonalMenu, setOpenPersonalMenu] = useState(false);
  const [value, setValue] = useState(location.pathname);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const handlePersonalMenuClick = () => {
    setOpenPersonalMenu(!openPersonalMenu);
  };

  const handleMobileMenuToggle = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const sidebarItems = [
    { name: 'דף הבית', link: '/', icon: <HomeIcon /> },
    { name: 'כל הקורסים', link: '/all-courses', icon: <MenuBookIcon /> },
    { name: 'תוכניות לימוד', link: '/all-plans', icon: <AssignmentIcon /> },
    { name: 'אודות', link: '/about-us', icon: <InfoIcon /> },
    { name: 'צור קשר', link: '/contact', icon: <ContactMailIcon /> },
    { name: 'מדיניות פרטיות', link: '/privacy-policy', icon: <PolicyIcon /> },
  ];

  if (isSignedIn) {
    sidebarItems.push({
      name: 'חשבון אישי',
      icon: <PersonIcon />,
      submenu: [
        {
          name: 'המנויים שלי',
          link: '/my-subscription',
          icon: <SubscriptionsIcon />,
        },
        { name: 'הקורסים שלי', link: '/my-courses', icon: <MenuBookIcon /> },
        { name: 'השיעורים שלי', link: '/my-lessons', icon: <SchoolIcon /> },
        {
          name: 'חומרי לימוד שלי',
          link: '/my-study-materials',
          icon: <LibraryBooksIcon />,
        },
      ],
    });
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      {/* Responsive Navigation */}
      {isMobile ? (
        <>
          {/* Mobile Menu Drawer */}
          <Drawer
            anchor="left"
            open={mobileMenuOpen}
            onClose={handleMobileMenuToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              '& .MuiDrawer-paper': {
                width: '80%', // Adjusted to 80% for better fit on small screens
                maxWidth: 300, // Maximum width to prevent excessive size on larger mobile screens
                boxSizing: 'border-box',
                backgroundColor: theme.palette.background.paper,
              },
            }}
          >
            <Toolbar>
              <Typography
                variant="h5"
                noWrap
                component="div"
                sx={{ flexGrow: 1, textAlign: 'center' }}
              >
                <Link
                  to="/"
                  style={{
                    textDecoration: 'none',
                    color: theme.palette.text.primary,
                    fontFamily: 'Grand Hotel, cursive',
                    fontSize: '1.5rem',
                  }}
                >
                  Infinity
                </Link>
              </Typography>
              <IconButton onClick={handleMobileMenuToggle} aria-label="close menu">
                <CloseIcon />
              </IconButton>
            </Toolbar>
            <List sx={{ mt: 2 }}>
              {sidebarItems.map((item) => {
                if (item.submenu) {
                  return (
                    <React.Fragment key={item.name}>
                      <ListItem button onClick={handlePersonalMenuClick}>
                        <ListItemIcon sx={{ color: theme.palette.text.primary }}>
                          {item.icon}
                        </ListItemIcon>
                        <ListItemText
                          primary={item.name}
                          sx={{ color: theme.palette.text.primary }}
                        />
                        {openPersonalMenu ? <ExpandLess /> : <ExpandMore />}
                      </ListItem>
                      <Collapse in={openPersonalMenu} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                          {item.submenu.map((subItem) => (
                            <ListItem
                              button
                              key={subItem.name}
                              component={Link}
                              to={subItem.link}
                              onClick={handleMobileMenuToggle}
                              sx={{ pl: 4 }}
                            >
                              <ListItemIcon sx={{ color: theme.palette.text.primary }}>
                                {subItem.icon}
                              </ListItemIcon>
                              <ListItemText
                                primary={subItem.name}
                                sx={{ color: theme.palette.text.primary }}
                              />
                            </ListItem>
                          ))}
                        </List>
                      </Collapse>
                    </React.Fragment>
                  );
                } else {
                  return (
                    <ListItem
                      button
                      key={item.name}
                      component={Link}
                      to={item.link}
                      onClick={handleMobileMenuToggle}
                    >
                      <ListItemIcon sx={{ color: theme.palette.text.primary }}>
                        {item.icon}
                      </ListItemIcon>
                      <ListItemText
                        primary={item.name}
                        sx={{ color: theme.palette.text.primary }}
                      />
                    </ListItem>
                  );
                }
              })}
            </List>
            <Box sx={{ flexGrow: 1 }} />
            {/* Theme Toggle and User Button */}
            <List>
              <ListItem button onClick={toggleTheme}>
                <ListItemIcon sx={{ color: theme.palette.text.primary }}>
                  <Brightness4Icon />
                </ListItemIcon>
                <ListItemText
                  primary="שנה נושא"
                  sx={{ color: theme.palette.text.primary }}
                />
              </ListItem>
              <SignedOut>
                <SignInButton>
                  <ListItem button onClick={handleMobileMenuToggle}>
                    <ListItemIcon sx={{ color: theme.palette.text.primary }}>
                      <PersonIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary="התחבר"
                      sx={{ color: theme.palette.text.primary }}
                    />
                  </ListItem>
                </SignInButton>
              </SignedOut>
              <SignedIn>
                <ListItem
                  button
                  component={Link}
                  to="/profile"
                  onClick={handleMobileMenuToggle}
                >
                  <ListItemIcon sx={{ color: theme.palette.text.primary }}>
                    <UserButton />
                  </ListItemIcon>
                  <ListItemText
                    primary="הפרופיל שלי"
                    sx={{ color: theme.palette.text.primary }}
                  />
                </ListItem>
              </SignedIn>
            </List>
          </Drawer>

          {/* Bottom Navigation */}
          <Box
            sx={{
              position: 'fixed',
              bottom: 0,
              width: '100%',
              boxShadow: '0 -2px 10px rgba(0, 0, 0, 0.1)',
              zIndex: theme.zIndex.drawer + 1,
              backgroundColor: theme.palette.background.paper,
            }}
          >
            <BottomNavigation
              value={value}
              onChange={handleChange}
              showLabels
              sx={{
                display: 'flex',
                justifyContent: 'space-around',
                minHeight: '100px'
              }}
            >
              {/* Menu Button */}
              <BottomNavigationAction
                label="תפריט"
                icon={<MenuIcon />}
                onClick={handleMobileMenuToggle}
              />
              {/* Profile */}
              {isSignedIn ? (
                <BottomNavigationAction
                  label="פרופיל"
                  icon={<PersonIcon />} // השתמש באייקון במקום UserButton
                  component={Link}
                  to="/profile"
                />
              ) : (
                <SignInButton>
                  <BottomNavigationAction label="התחבר" icon={<PersonIcon />} />
                </SignInButton>
              )}
              {/* My Courses */}
              {isSignedIn && (
                <BottomNavigationAction
                  label="הקורסים שלי"
                  icon={<LibraryBooksIcon />}
                  component={Link}
                  to="/my-courses"
                />
              )}
              {/* My Lessons */}
              {isSignedIn && (
                <BottomNavigationAction
                  label="השיעורים שלי"
                  icon={<SchoolIcon />}
                  component={Link}
                  to="/my-lessons"
                />
              )}
              {/* Courses */}
              <BottomNavigationAction
                label="קורסים"
                icon={<MenuBookIcon />}
                component={Link}
                to="/all-courses"
              />
            </BottomNavigation>
          </Box>
        </>
      ) : (
        /* Desktop Navigation */
        <Drawer
          variant="permanent"
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              width: drawerWidth,
              boxSizing: 'border-box',
              backgroundColor: theme.palette.background.paper,
              borderRight: `1px solid ${theme.palette.divider}`,
            },
          }}
        >
          <Toolbar>
            <Typography
              variant="h5"
              noWrap
              component="div"
              sx={{ flexGrow: 1, textAlign: 'center' }}
            >
              <Link
                to="/"
                style={{
                  textDecoration: 'none',
                  color: theme.palette.text.primary,
                  fontFamily: 'Grand Hotel, cursive',
                  fontSize: '1.5rem',
                }}
              >
                Infinity
              </Link>
            </Typography>
          </Toolbar>
          <List sx={{ mt: 2 }}>
            {sidebarItems.map((item) => {
              if (item.submenu) {
                return (
                  <React.Fragment key={item.name}>
                    <ListItem
                      button
                      onClick={handlePersonalMenuClick}
                      sx={{ mb: 1 }}
                      aria-haspopup="true"
                      aria-expanded={openPersonalMenu}
                      aria-controls="personal-menu"
                    >
                      <ListItemIcon sx={{ color: theme.palette.text.primary }}>
                        {item.icon}
                      </ListItemIcon>
                      <ListItemText
                        primary={item.name}
                        sx={{ color: theme.palette.text.primary }}
                      />
                      {openPersonalMenu ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                    <Collapse
                      in={openPersonalMenu}
                      timeout="auto"
                      unmountOnExit
                      id="personal-menu"
                    >
                      <List component="div" disablePadding>
                        {item.submenu.map((subItem) => (
                          <ListItem
                            button
                            key={subItem.name}
                            component={Link}
                            to={subItem.link}
                            sx={{ pl: 4, mb: 1 }}
                          >
                            <ListItemIcon sx={{ color: theme.palette.text.primary }}>
                              {subItem.icon}
                            </ListItemIcon>
                            <ListItemText
                              primary={subItem.name}
                              sx={{ color: theme.palette.text.primary }}
                            />
                          </ListItem>
                        ))}
                      </List>
                    </Collapse>
                  </React.Fragment>
                );
              } else {
                return (
                  <ListItem
                    button
                    key={item.name}
                    component={Link}
                    to={item.link}
                    sx={{ mb: 1 }}
                  >
                    <ListItemIcon sx={{ color: theme.palette.text.primary }}>
                      {item.icon}
                    </ListItemIcon>
                    <ListItemText
                      primary={item.name}
                      sx={{ color: theme.palette.text.primary }}
                    />
                  </ListItem>
                );
              }
            })}
          </List>
          <Box sx={{ flexGrow: 1 }} />
          {/* Theme Toggle and User Button */}
          <List>
            <ListItem button onClick={toggleTheme} sx={{ mb: 1 }}>
              <ListItemIcon sx={{ color: theme.palette.text.primary }}>
                <Brightness4Icon />
              </ListItemIcon>
              <ListItemText
                primary="שנה נושא"
                sx={{ color: theme.palette.text.primary }}
              />
            </ListItem>
            <SignedOut>
              <SignInButton>
                <ListItem button sx={{ mb: 1 }}>
                  <ListItemIcon sx={{ color: theme.palette.text.primary }}>
                    <PersonIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary="התחבר"
                    sx={{ color: theme.palette.text.primary }}
                  />
                </ListItem>
              </SignInButton>
            </SignedOut>

            <SignedIn>
              <ListItem button component={Link} to="/profile" sx={{ mb: 1 }}>
                <ListItemIcon sx={{ color: theme.palette.text.primary }}>
                  <PersonIcon /> {/* השתמש באייקון במקום UserButton */}
                </ListItemIcon>
                <ListItemText
                  primary="הפרופיל שלי"
                  sx={{ color: theme.palette.text.primary }}
                />
              </ListItem>
            </SignedIn>
          </List>
        </Drawer>
      )}
      {/* Main Content */}
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          ml: isMobile ? 0 : `${drawerWidth}px`,
          mt: isMobile ? 0 : '0', // Adjusted to accommodate the toolbar height
          p: isMobile ? 0 : 3,
          pb: isMobile ? '60px' : 3, // Add padding bottom for mobile to avoid overlap with BottomNavigation
        }}
      >
        {/* Your page content goes here */}
      </Box>
    </Box>
  );
};

export default Navbar;
