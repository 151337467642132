// src/components/IconMapping.js

import React from 'react';
import * as MUIIcons from '@mui/icons-material';

// Define the list of icons categorized by field
const iconOptions = [
    // Mathematics Icons
    { name: 'Calculate', icon: <MUIIcons.Calculate /> },
    { name: 'Functions', icon: <MUIIcons.Functions /> },
    { name: 'IntegrationInstructions', icon: <MUIIcons.IntegrationInstructions /> },
    { name: 'PieChart', icon: <MUIIcons.PieChart /> },
    { name: 'ShowChart', icon: <MUIIcons.ShowChart /> },
    { name: 'FunctionsOutlined', icon: <MUIIcons.FunctionsOutlined /> },
    { name: 'BarChart', icon: <MUIIcons.BarChart /> },
    { name: 'Timeline', icon: <MUIIcons.Timeline /> },
    { name: 'StackedLineChart', icon: <MUIIcons.StackedLineChart /> },
    { name: 'Percent', icon: <MUIIcons.Percent /> },
  
    // Physics Icons
    { name: 'Science', icon: <MUIIcons.Science /> },
    { name: 'EmojiObjects', icon: <MUIIcons.EmojiObjects /> },
    { name: 'FlashOn', icon: <MUIIcons.FlashOn /> },
    { name: 'Thermostat', icon: <MUIIcons.Thermostat /> },
    { name: 'Waves', icon: <MUIIcons.Waves /> },
    { name: 'Speed', icon: <MUIIcons.Speed /> },
  
    // Computer Science Icons
    { name: 'Computer', icon: <MUIIcons.Computer /> },
    { name: 'Code', icon: <MUIIcons.Code /> },
    { name: 'DeveloperMode', icon: <MUIIcons.DeveloperMode /> },
    { name: 'Memory', icon: <MUIIcons.Memory /> },
    { name: 'Storage', icon: <MUIIcons.Storage /> },
    { name: 'SmartDisplay', icon: <MUIIcons.SmartDisplay /> },
    { name: 'BugReport', icon: <MUIIcons.BugReport /> },
    { name: 'DataUsage', icon: <MUIIcons.DataUsage /> },
    { name: 'Build', icon: <MUIIcons.Build /> },
    { name: 'CloudQueue', icon: <MUIIcons.CloudQueue /> },
    { name: 'SettingsEthernet', icon: <MUIIcons.SettingsEthernet /> },
  
    // Additional Icons
    { name: 'HelpOutline', icon: <MUIIcons.HelpOutline /> },
    { name: 'ScienceOutlined', icon: <MUIIcons.ScienceOutlined /> },
    { name: 'CodeOutlined', icon: <MUIIcons.CodeOutlined /> },
    { name: 'SchoolOutlined', icon: <MUIIcons.SchoolOutlined /> },
    { name: 'TrendingUp', icon: <MUIIcons.TrendingUp /> },
    { name: 'Insights', icon: <MUIIcons.Insights /> },
  ];

export default iconOptions;
