// src/components/Widgets/Filters/TimeframeFilter.js

import React from 'react';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import PropTypes from 'prop-types';

const TimeframeFilter = ({ timeframe, setTimeframe, fullWidth }) => {
  const handleChange = (event) => {
    setTimeframe(event.target.value);
  };

  return (
    <FormControl fullWidth={fullWidth}>
      <InputLabel id="timeframe-select-label">טווח זמן</InputLabel>
      <Select
        labelId="timeframe-select-label"
        id="timeframe-select"
        value={timeframe}
        label="טווח זמן"
        onChange={handleChange}
      >
        <MenuItem value="today">היום</MenuItem>
        <MenuItem value="week">השבוע</MenuItem>
        <MenuItem value="month">החודש</MenuItem>
      </Select>
    </FormControl>
  );
};

TimeframeFilter.propTypes = {
  timeframe: PropTypes.string.isRequired,
  setTimeframe: PropTypes.func.isRequired,
  fullWidth: PropTypes.bool,
};

TimeframeFilter.defaultProps = {
  fullWidth: true,
};

export default TimeframeFilter;
