// src/components/StudyMaterialList.js

import React, { useEffect, useState, useContext } from 'react';
import { useParams, Link as RouterLink } from 'react-router-dom';
import { DataContext } from '../../context/DataContext';
import {
  Container,
  Typography,
  Grid,
  Card,
  CardMedia,
  CardContent,
  CardActions,
  Button,
  CircularProgress,
  Alert,
  Box,
  Badge,
} from '@mui/material';
import { styled } from '@mui/system';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import VisibilityIcon from '@mui/icons-material/Visibility';

// Styled Badge for Difficulty Level
const DifficultyBadge = styled(Badge)(({ theme, level }) => ({
  '& .MuiBadge-badge': {
    right: -3,
    top: 13,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
    backgroundColor:
      level === 'קל'
        ? theme.palette.success.main
        : level === 'בינוני'
        ? theme.palette.warning.main
        : theme.palette.error.main,
    color: theme.palette.common.white,
  },
}));

const StudyMaterialList = () => {
  const { lessonId, courseId } = useParams();
  const {
    studyMaterials,
    lessons,
    courses,
    getLessonById,
    getCourseById,
    loading,
    error: dataError,
  } = useContext(DataContext);

  const [filteredMaterials, setFilteredMaterials] = useState([]);
  const [title, setTitle] = useState('חומרי לימוד');

  useEffect(() => {
    if (loading) return;

    if (dataError) {
      console.error('Error in DataContext:', dataError);
      return;
    }

    if (lessonId) {
      // Get the lesson by ID from context
      const lesson = getLessonById(lessonId);

      if (lesson) {
        setTitle(`חומרי לימוד לשיעור: ${lesson.title}`);

        // Collect study materials from the lesson
        const materials = [];

        if (lesson.mainStudyMaterial) {
          materials.push(lesson.mainStudyMaterial);
        }

        if (lesson.supplementaryMaterials && lesson.supplementaryMaterials.length > 0) {
          materials.push(...lesson.supplementaryMaterials);
        }

        setFilteredMaterials(materials);
      } else {
        setFilteredMaterials([]);
        console.warn('Lesson not found in context.');
      }
    } else if (courseId) {
      // Get the course by ID from context
      const course = getCourseById(courseId);

      if (course) {
        setTitle(`חומרי לימוד לקורס: ${course.title}`);

        if (course.studyMaterials && course.studyMaterials.length > 0) {
          setFilteredMaterials(course.studyMaterials);
        } else {
          setFilteredMaterials([]);
        }
      } else {
        setFilteredMaterials([]);
        console.warn('Course not found in context.');
      }
    } else {
      // Use all study materials from context
      setTitle('כל חומרי הלימוד');
      setFilteredMaterials(studyMaterials);
    }
  }, [
    lessonId,
    courseId,
    studyMaterials,
    lessons,
    courses,
    getLessonById,
    getCourseById,
    loading,
    dataError,
  ]);

  if (loading) {
    return (
      <Container >
        <Box sx={{ textAlign: 'center' }}>
          <CircularProgress />
          <Typography variant="body1" sx={{ mt: 2 }}>
            טוען חומרי לימוד...
          </Typography>
        </Box>
      </Container>
    );
  }

  if (dataError) {
    return (
      <Container >
        <Alert severity="error">{dataError}</Alert>
      </Container>
    );
  }

  if (filteredMaterials.length === 0) {
    return (
      <Container >
        <Typography variant="h6">לא נמצאו חומרי לימוד.</Typography>
      </Container>
    );
  }

  return (
    <Container >
      <Typography variant="h4" gutterBottom align="center">
        {title}
      </Typography>
      <Grid container spacing={4}>
        {filteredMaterials.map((material) => (
          <Grid item key={material._id} xs={12} sm={6} md={4}>
            <Card
              sx={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                boxShadow: 3,
                borderRadius: 2,
              }}
            >
              {/* Image */}
              {material.imageUrl && (
                <CardMedia
                  component="img"
                  image={material.imageUrl}
                  alt={material.title}
                  height="200"
                />
              )}

              <CardContent sx={{ flexGrow: 1 }}>
                <Typography gutterBottom variant="h5" component="h2">
                  {material.title}
                </Typography>
                <Typography>
                  {material.description.length > 100
                    ? `${material.description.substring(0, 100)}...`
                    : material.description}
                </Typography>
              </CardContent>

              <CardActions>
                <Button
                  size="small"
                  color="primary"
                  component={RouterLink}
                  to={`/study-material/${material._id}`}
                  startIcon={<VisibilityIcon />}
                >
                  הצג
                </Button>
                {material.explanationVideos && material.explanationVideos.length > 0 && (
                  <Button
                    size="small"
                    color="secondary"
                    component={RouterLink}
                    to={`/study-material/${material._id}`}
                    startIcon={<PlayCircleOutlineIcon />}
                  >
                    צפייה
                  </Button>
                )}
              </CardActions>

              {/* Difficulty Badge */}
              <DifficultyBadge
                badgeContent={material.difficultyLevel}
                level={material.difficultyLevel}
                overlap="rectangular"
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
              >
                {/* Empty box to position the badge */}
                <Box sx={{ width: 0, height: 0 }} />
              </DifficultyBadge>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default StudyMaterialList;
