// src/components/ContactUs.js

import React, { useState } from 'react';
import { Box, Typography, TextField, Button, Alert, Snackbar } from '@mui/material';
import { styled } from '@mui/system';
import SendIcon from '@mui/icons-material/Send';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const FormContainer = styled(Box)(({ theme }) => ({
  maxWidth: '600px',
  margin: '0 auto',
  padding: theme.spacing(3),
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[5],
  borderRadius: theme.shape.borderRadius,
  transition: 'transform 0.3s',
  '&:hover': {
    transform: 'scale(1.02)',
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2),
  padding: theme.spacing(1.5),
  fontSize: '1rem',
  borderRadius: theme.shape.borderRadius,
  textTransform: 'none',
  boxShadow: theme.shadows[2],
  transition: 'background-color 0.3s ease, transform 0.3s ease',
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
    transform: 'translateY(-2px)',
  },
}));

function ContactUs() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });

  const [submitted, setSubmitted] = useState(false);
  const [formError, setFormError] = useState(null);
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const { name, email, message } = formData;

  const handleChange = e => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = e => {
    e.preventDefault();
    // אימות בסיסי
    if (!name || !email || !message) {
      setFormError('כל השדות נדרשים.');
      return;
    }
    // יישום לוגיקת שליחת טופס (למשל, שליחת נתונים ל-API)
    console.log('Form submitted:', formData);
    setSubmitted(true);
    setOpenSnackbar(true);
    // איפוס הטופס
    setFormData({
      name: '',
      email: '',
      message: '',
    });
    setFormError(null);
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  return (
    <main className="contact-us">
      <Box sx={{ py: 8, textAlign: 'center', backgroundColor: '#f5f5f5' }}>
        <Typography variant="h3" gutterBottom sx={{ fontWeight: 'bold', color: 'primary.main' }}>
          צור קשר
        </Typography>
        <Typography variant="h6" gutterBottom>
          נשמח לשמוע ממך! אנא פנה אלינו עם כל שאלה או משוב.
        </Typography>
        {submitted ? (
          <Alert icon={<CheckCircleIcon fontSize="inherit" />} severity="success" sx={{ mt: 4 }}>
            תודה על הודעתך! נחזור אליך בקרוב.
          </Alert>
        ) : (
          <FormContainer
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{
              mt: 4,
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
            }}
          >
            {formError && (
              <Alert severity="error" sx={{ mb: 2 }}>
                {formError}
              </Alert>
            )}
            <TextField
              label="שם"
              name="name"
              variant="outlined"
              required
              value={name}
              onChange={handleChange}
              fullWidth
              InputLabelProps={{
                style: { fontSize: '1rem' },
              }}
              inputProps={{
                style: { fontSize: '1rem' },
              }}
            />
            <TextField
              label="דואר אלקטרוני"
              name="email"
              type="email"
              variant="outlined"
              required
              value={email}
              onChange={handleChange}
              fullWidth
              InputLabelProps={{
                style: { fontSize: '1rem' },
              }}
              inputProps={{
                style: { fontSize: '1rem' },
              }}
            />
            <TextField
              label="הודעה"
              name="message"
              variant="outlined"
              required
              multiline
              rows={6}
              value={message}
              onChange={handleChange}
              fullWidth
              InputLabelProps={{
                style: { fontSize: '1rem' },
              }}
              inputProps={{
                style: { fontSize: '1rem' },
              }}
            />
            <StyledButton
              type="submit"
              variant="contained"
              color="primary"
              endIcon={<SendIcon />}
            >
              שלח הודעה
            </StyledButton>
          </FormContainer>
        )}
        <Snackbar
          open={openSnackbar}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>
            הודעתך נשלחה בהצלחה!
          </Alert>
        </Snackbar>
      </Box>
    </main>
  );
}

export default ContactUs;
