// src/components/Course/CourseDetails.js

import React, { useContext, useEffect, useState, memo } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import {
  Box,
  Typography,
  Container,
  Card,
  CardContent,
  Button,
  CircularProgress,
  Alert,
  Chip,
  Divider,
  List,
  ListItem,
  ListItemText,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Tooltip,
} from '@mui/material';
import { styled } from '@mui/system';
import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import { DataContext } from '../../context/DataContext';
import { UserContext } from '../../context/UserContext';
import IconDisplay from '../Icon/IconDisplay';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import dayjs from 'dayjs';
import 'dayjs/locale/he';
import { useTheme } from '@mui/material/styles';

dayjs.locale('he');

// Styled Components

const SectionHeader = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(4),
  fontWeight: 700,
  textAlign: 'center',
  [theme.breakpoints.down('sm')]: {
    fontSize: '1.8rem',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '2.5rem',
  },
}));

const StyledListItem = styled(ListItem)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(2),
  alignItems: 'flex-start',
}));

// Course Type Badge positioned at the top-left corner
const CourseTypeBadge = styled(Box)(({ theme, isLive }) => ({
  position: 'absolute',
  top: theme.spacing(2),
  left: theme.spacing(2),
  padding: theme.spacing(0.5, 1),
  borderRadius: theme.shape.borderRadius,
  backgroundColor: isLive
    ? theme.palette.primary.main
    : theme.palette.warning.main,
  color: theme.palette.common.white,
  fontWeight: 600,
  zIndex: 1,
  fontSize: '0.75rem',
}));

const CourseDetails = () => {
  const theme = useTheme();
  const { courseId } = useParams(); // קבלת מזהה הקורס מהנתיב
  const navigate = useNavigate();
  const { courses, lessons, loading: dataLoading, error: dataError } = useContext(DataContext);
  const { user, isSignedIn, loading: userLoading, error: userError, hasAccess } = useContext(UserContext);

  const [course, setCourse] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setLocalError] = useState(null);
  const [userHasAccess, setUserHasAccess] = useState(false);

  useEffect(() => {
    const fetchCourse = async () => {
      setLoading(true);
      setLocalError(null);
      try {
        const selectedCourse = courses.find((c) => c._id === courseId);
        if (selectedCourse) {
          setCourse(selectedCourse);
        } else {
          // אם הקורס לא נמצא במידע הקיים, נסה לפנות ל-API ישירות
          const response = await fetch(`${process.env.REACT_APP_INFINITY_BACK_URL}/api/courses/${courseId}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
            },
          });

          if (response.ok) {
            const data = await response.json();
            setCourse(data);
          } else if (response.status === 404) {
            setLocalError('הקורס לא נמצא.');
          } else {
            const errorData = await response.json();
            setLocalError(errorData.error || 'התרחשה שגיאה בעת שליפת פרטי הקורס.');
          }
        }
      } catch (err) {
        console.error('Error fetching course details:', err);
        setLocalError('התרחשה שגיאה בלתי צפויה בעת שליפת פרטי הקורס.');
      } finally {
        setLoading(false);
      }
    };

    fetchCourse();
  }, [courseId, courses]);

  // בדיקה אם למשתמש יש גישה לקורס
  useEffect(() => {
    const checkAccess = async () => {
      if (isSignedIn && courseId) {
        const access = await hasAccess('course', courseId);
        setUserHasAccess(access);
      } else {
        setUserHasAccess(false);
      }
    };

    checkAccess();
  }, [isSignedIn, courseId, hasAccess]);

  if (dataLoading || loading || userLoading) {
    return (
      <Container>
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
          <CircularProgress />
        </Box>
      </Container>
    );
  }

  if (dataError) {
    return (
      <Container>
        <Alert severity="error">{dataError}</Alert>
      </Container>
    );
  }

  if (userError) {
    return (
      <Container>
        <Alert severity="error">{userError}</Alert>
      </Container>
    );
  }

  if (error) {
    return (
      <Container>
        <Alert severity="warning">{error}</Alert>
      </Container>
    );
  }

  if (!course) {
    return null;
  }

  return (
    <main className="course-details">
      <Container dir="rtl">
        {/* פרטי הקורס */}
        <Box sx={{ textAlign: 'center', mb: 8, position: 'relative' }}>
          {/* Course Type Badge */}
          <Tooltip title={course.isLive ? 'קורס בשידור חי' : 'קורס מוקלט'}>
            <CourseTypeBadge isLive={course.isLive} aria-label={`סוג קורס: ${course.isLive ? 'רץ בלייב' : 'מוקלט'}`}>
              {course.isLive ? 'רץ בלייב' : 'מוקלט'}
            </CourseTypeBadge>
          </Tooltip>

          <IconDisplay iconName={course.icon} size={80} color="primary.main" />
          <Typography
            variant="h3"
            component="h1"
            gutterBottom
            sx={{
              fontSize: {
                xs: '2rem',
                sm: '2.5rem',
                md: '3rem',
              },
            }}
          >
            {course.title}
          </Typography>
          <Typography
            variant="h6"
            color="text.secondary"
            gutterBottom
            sx={{
              fontSize: {
                xs: '1rem',
                sm: '1.2rem',
                md: '1.4rem',
              },
            }}
          >
            {course.description}
          </Typography>
          <Typography variant="subtitle1" color="text.secondary" sx={{ mt: 2 }}>
            נושא: {course.subject}
          </Typography>
          <Typography variant="subtitle1" color="text.secondary">
            משך הקורס: {course.duration} שעות
          </Typography>
          {/* סטטוס גישה */}
          <Chip
            label={userHasAccess ? 'יש לך גישה לקורס זה' : 'אין לך גישה לקורס זה'}
            color={userHasAccess ? 'success' : 'error'}
            sx={{ mt: 2 }}
          />
        </Box>

        {/* תוכן הקורס */}
        <Divider sx={{ mb: 4 }} />

        {/* יחידות הקורס */}
        {course.units && course.units.length > 0 && (
          <Box sx={{ mb: 8 }}>
            <SectionHeader variant="h4">יחידות הקורס</SectionHeader>

            {course.units.map((unit) => (
              <Accordion key={unit._id}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls={`unit-content-${unit._id}`}
                  id={`unit-header-${unit._id}`}
                >
                  <Typography
                    variant="h6"
                    sx={{
                      fontSize: {
                        xs: '1.2rem',
                        sm: '1.4rem',
                        md: '1.6rem',
                      },
                    }}
                  >
                    {unit.title}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {/* פרטי היחידה */}
                  {unit.description && (
                    <Typography
                      variant="body1"
                      sx={{
                        mb: 2,
                        fontSize: {
                          xs: '0.9rem',
                          sm: '1rem',
                          md: '1.1rem',
                        },
                      }}
                    >
                      {unit.description}
                    </Typography>
                  )}

                  {unit.mainTopics && unit.mainTopics.length > 0 && (
                    <Box sx={{ mb: 2 }}>
                      <Typography variant="subtitle1" gutterBottom>
                        נושאים עיקריים:
                      </Typography>
                      {unit.mainTopics.map((topic, topicIndex) => (
                        <Chip
                          key={topicIndex}
                          label={topic}
                          variant="outlined"
                          sx={{ mr: 1, mb: 1, fontSize: '0.8rem' }}
                        />
                      ))}
                    </Box>
                  )}

                  {/* רשימת השיעורים בתוך היחידה - רק תצוגת רשימה */}
                  {unit.roadmap && unit.roadmap.length > 0 ? (
                    <List>
                      {unit.roadmap
                        .sort((a, b) => a.order - b.order)
                        .map((item, index) => {
                          const lesson = lessons.find((l) => l._id === item.lesson._id);

                          return (
                            <StyledListItem key={item.lesson._id}>
                              <ListItemText
                                primary={
                                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Chip
                                      label={`שיעור ${item.order}`}
                                      color="secondary"
                                      size="small"
                                      sx={{ mr: 2 }}
                                    />
                                    <Typography
                                      variant="h6"
                                      sx={{
                                        fontSize: {
                                          xs: '1rem',
                                          sm: '1.1rem',
                                          md: '1.2rem',
                                        },
                                      }}
                                    >
                                      {lesson ? lesson.title : item.lesson.title}
                                    </Typography>
                                  </Box>
                                }
                                secondary={
                                  <>
                                    <Typography
                                      sx={{
                                        display: 'block',
                                        fontSize: {
                                          xs: '0.8rem',
                                          sm: '0.9rem',
                                          md: '1rem',
                                        },
                                      }}
                                      component="span"
                                      variant="body2"
                                      color="text.primary"
                                    >
                                      {lesson && lesson.description
                                        ? lesson.description.length > 100
                                          ? `${lesson.description.substring(0, 100)}...`
                                          : lesson.description
                                        : 'אין תיאור זמין.'}
                                    </Typography>
                                    <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
                                      <CalendarTodayIcon color="action" sx={{ mr: 1 }} />
                                      <Typography
                                        variant="body2"
                                        color="text.secondary"
                                        sx={{
                                          fontSize: {
                                            xs: '0.8rem',
                                            sm: '0.9rem',
                                            md: '1rem',
                                          },
                                        }}
                                      >
                                        {lesson && lesson.startDateTime
                                          ? dayjs(lesson.startDateTime).format('DD/MM/YYYY HH:mm')
                                          : 'תאריך לא נקבע'}
                                      </Typography>
                                    </Box>
                                    <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
                                      <AccessTimeIcon color="action" sx={{ mr: 1 }} />
                                      <Typography
                                        variant="body2"
                                        color="text.secondary"
                                        sx={{
                                          fontSize: {
                                            xs: '0.8rem',
                                            sm: '0.9rem',
                                            md: '1rem',
                                          },
                                        }}
                                      >
                                        משך: {lesson && lesson.duration ? `${lesson.duration} דקות` : 'לא ידוע'}
                                      </Typography>
                                    </Box>
                                  </>
                                }
                              />
                              <Button
                                variant="contained"
                                color="primary"
                                component={Link}
                                to={`/lessons/${item.lesson._id}`}
                                sx={{
                                  textTransform: 'none',
                                  borderRadius: '50px',
                                  boxShadow: theme.shadows[2],
                                  transition: 'background-color 0.3s ease',
                                  '&:hover': {
                                    backgroundColor: theme.palette.primary.dark,
                                  },
                                  ml: 2,
                                }}
                                aria-label={`פרטים נוספים על שיעור ${lesson ? lesson.title : item.lesson.title}`}
                              >
                                לפרטים נוספים
                              </Button>
                            </StyledListItem>
                          );
                        })}
                    </List>
                  ) : (
                    <Typography variant="body2" color="text.secondary">
                      אין שיעורים זמינים ביחידה זו.
                    </Typography>
                  )}
                </AccordionDetails>
              </Accordion>
            ))}
          </Box>
        )}

        {/* הודעות שגיאה משתמש */}
        {userError && (
          <Box sx={{ mt: 4, textAlign: 'center' }}>
            <Alert severity="error">{userError}</Alert>
          </Box>
        )}

        {/* כפתור חזרה לקורסים */}
        <Box sx={{ mt: 8, textAlign: 'center' }}>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => navigate('/all-courses')}
            sx={{
              textTransform: 'none',
              borderRadius: '50px',
              borderColor: 'primary.main',
              color: 'primary.main',
              boxShadow: theme.shadows[2],
              transition: 'background-color 0.3s ease, color 0.3s ease',
              '&:hover': {
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.common.white,
              },
            }}
            aria-label="חזרה לקורסים"
          >
            חזרה לקורסים
          </Button>
        </Box>
      </Container>
    </main>
  );
};

export default CourseDetails;
