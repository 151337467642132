// src/components/Widgets/Filters/SearchComponent.js

import React, { useState, useEffect } from 'react';
import { TextField, IconButton, InputAdornment } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import PropTypes from 'prop-types';
import debounce from 'lodash.debounce';

const SearchComponent = ({ onSearch, fullWidth }) => {
  const [searchInput, setSearchInput] = useState('');

  // Debounce the search input to prevent excessive API calls
  const debouncedSearch = React.useMemo(
    () =>
      debounce((value) => {
        onSearch(value);
      }, 500),
    [onSearch]
  );

  useEffect(() => {
    debouncedSearch(searchInput);

    // Cancel the debounce on useEffect cleanup
    return () => {
      debouncedSearch.cancel();
    };
  }, [searchInput, debouncedSearch]);

  const handleChange = (event) => {
    setSearchInput(event.target.value);
  };

  const handleSearchClick = () => {
    onSearch(searchInput);
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSearchClick();
      event.preventDefault();
    }
  };

  return (
    <TextField
      fullWidth={fullWidth}
      label="חיפוש"
      variant="outlined"
      value={searchInput}
      onChange={handleChange}
      onKeyPress={handleKeyPress}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={handleSearchClick} edge="end">
              <SearchIcon />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};

SearchComponent.propTypes = {
  onSearch: PropTypes.func.isRequired,
  fullWidth: PropTypes.bool,
};

SearchComponent.defaultProps = {
  fullWidth: true,
};

export default SearchComponent;
