// src/components/InfoCard.js
import React from 'react';
import { Card, CardContent, CardMedia, Typography } from '@mui/material';
import PropTypes from 'prop-types';

const InfoCard = ({ title, description, imagePath }) => (
  <Card
    sx={{
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      border: '1px solid #e0e0e0',
      boxShadow: 'none',
      transition: 'transform 0.3s, box-shadow 0.3s',
      '&:hover': {
        transform: 'translateY(-8px)',
        boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
      },
    }}
  >
    {/* Image with better alt description handling */}
    <CardMedia
      component="img"
      height={{ xs: 120, sm: 160 }}
      image={imagePath}
      alt={`${title} image`}
      sx={{ objectFit: 'cover' }}
    />
    <CardContent>
      <Typography
        sx={{ textAlign: 'center', fontSize: { xs: '1.2rem', sm: '1.5rem' } }}
        variant="h5"
        component="h2"
        gutterBottom
      >
        {title}
      </Typography>
      <Typography
        sx={{ textAlign: 'center', fontSize: { xs: '0.9rem', sm: '1rem' } }}
        variant="body1"
        color="textSecondary"
      >
        {description}
      </Typography>
    </CardContent>
  </Card>
);

InfoCard.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  imagePath: PropTypes.string.isRequired,
};

export default InfoCard;
