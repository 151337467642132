// src/components/HomeWidgets/UpcomingLessonWidget.js

import React, { useContext, useEffect, useState, useRef } from 'react';
import { DataContext } from '../../context/DataContext';
import { UserContext } from '../../context/UserContext';
import {
  Card,
  Box,
  CircularProgress,
  Alert,
  Snackbar,
  Grid,
  Typography,
} from '@mui/material';
import dayjs from 'dayjs';
import 'dayjs/locale/he';
import { styled } from '@mui/system';
import { useTheme } from '@mui/material/styles';

import TimeframeFilter from '../Widgets/Filters/TimeframeFilter';
import CoursesFilter from '../Widgets/Filters/CoursesFilter';
import SubjectsFilter from '../Widgets/Filters/SubjectsFilter';
import SearchComponent from '../Widgets/Filters/SearchComponent';
import LessonCardWidget from './LessonCardWidget';

// Import Ant Design Carousel
import { Carousel } from 'antd';
import 'antd/dist/reset.css'; // Ensure Ant Design styles are imported

dayjs.locale('he');

// Styled Components

const StyledCard = styled(Card)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  mb: 4,
  boxShadow: 'none',
  border: '1px solid #e0e0e0',
  transition: 'transform 0.3s, box-shadow 0.3s',
  padding: theme.spacing(2),
}));

const StyledCarousel = styled(Carousel)(({ theme }) => ({
  width: '100%',
  '& .ant-carousel .slick-slide': {
    textAlign: 'center',
    height: '100%',
    // Override any default Ant Design Carousel styles if necessary
  },
}));

const UpcomingLessonWidget = () => {
  const theme = useTheme();
  const {
    subjects,
    filteredLessons,
    fetchFilteredUpcomingLessons,
    filteredLoading,
    filteredError,
  } = useContext(DataContext);
  const {
    isSignedIn,
    enrollInLesson,
    cancelLesson,
    user,
    loading: userLoading,
    error: userError,
    hasAccess,
  } = useContext(UserContext);

  // Filter states
  const [timeframe, setTimeframe] = useState('today'); // 'today', 'week', 'month'
  const [filterCourses, setFilterCourses] = useState('myCourses'); // 'myCourses', 'allCourses'
  const [selectedSearchOptions, setSelectedSearchOptions] = useState('');
  const [selectedSubjects, setSelectedSubjects] = useState([]);
  const [enrollmentLoading, setEnrollmentLoading] = useState({});

  // Ref to track if reload has been triggered
  const reloadTriggered = useRef(false);

  // Snackbar state
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success', // 'success' | 'error' | 'warning' | 'info'
  });

  // Fetch filtered lessons whenever filters change
  useEffect(() => {
    const fetchLessons = async () => {
      if (!isSignedIn || !user) {
        // Handle unauthenticated state if necessary
        return;
      }

      const filters = {
        timeframe,
        courseFilter: filterCourses,
        subjects: selectedSubjects,
        search: selectedSearchOptions.trim(),
      };

      // Assuming clerkUserId is available in UserContext
      const clerkUserId = user?.clerkUserId;

      if (clerkUserId) {
        await fetchFilteredUpcomingLessons(clerkUserId, filters);
      }
    };

    fetchLessons();
  }, [
    isSignedIn,
    timeframe,
    filterCourses,
    selectedSubjects,
    selectedSearchOptions,
    fetchFilteredUpcomingLessons,
    user,
  ]);

  // Add handlers for enrollment
  const handleEnroll = async (lessonId) => {
    try {
      setEnrollmentLoading((prev) => ({ ...prev, [lessonId]: true }));
      await enrollInLesson(lessonId);
      setSnackbar({
        open: true,
        message: 'נרשמת בהצלחה לשיעור!',
        severity: 'success',
      });
      // Optionally refetch lessons if enrollment affects filters
      if (user.clerkUserId) {
        await fetchFilteredUpcomingLessons(user.clerkUserId, {
          timeframe,
          courseFilter: filterCourses,
          subjects: selectedSubjects,
          search: selectedSearchOptions.trim(),
        });
      }
    } catch (error) {
      setSnackbar({
        open: true,
        message: error.message || 'ההרשמה נכשלה. אנא נסה שוב.',
        severity: 'error',
      });
    } finally {
      setEnrollmentLoading((prev) => ({ ...prev, [lessonId]: false }));
    }
  };

  const handleCancelEnrollment = async (lessonId) => {
    try {
      setEnrollmentLoading((prev) => ({ ...prev, [lessonId]: true }));
      await cancelLesson(lessonId);
      setSnackbar({
        open: true,
        message: 'ביטלת את ההרשמה בהצלחה!',
        severity: 'success',
      });
      // Optionally refetch lessons if enrollment affects filters
      if (user.clerkUserId) {
        await fetchFilteredUpcomingLessons(user.clerkUserId, {
          timeframe,
          courseFilter: filterCourses,
          subjects: selectedSubjects,
          search: selectedSearchOptions.trim(),
        });
      }
    } catch (error) {
      setSnackbar({
        open: true,
        message: error.message || 'ביטול ההרשמה נכשל. אנא נסה שוב.',
        severity: 'error',
      });
    } finally {
      setEnrollmentLoading((prev) => ({ ...prev, [lessonId]: false }));
    }
  };

  // Handle search change
  const handleSearchChange = (searchValue) => {
    setSelectedSearchOptions(searchValue);
  };

  // Handle subjects filter change
  const handleSubjectsChange = (event, newSubjects) => {
    setSelectedSubjects(newSubjects);
  };

  // Handle page refresh on error
  useEffect(() => {
    if ((filteredError || userError) && !reloadTriggered.current) {
      reloadTriggered.current = true;
      // Show Snackbar message
      setSnackbar({
        open: true,
        message: filteredError || userError?.message || 'התרחשה שגיאה בלתי צפויה.',
        severity: 'error',
      });
      // Refresh the page after a delay
      setTimeout(() => {
        window.location.reload();
      }, 3000); // רענון הדף אחרי 3 שניות
    }
  }, [filteredError, userError]);

  // Handle closing the Snackbar
  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar((prev) => ({ ...prev, open: false }));
  };

  // Carousel settings
  const carouselSettings = {
    dots: true,
    infinite: filteredLessons.length > 1, // Enable infinite only if more than one slide
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    autoplay: false,
    adaptiveHeight: true,
  };

  return (
    <StyledCard>
      {/* Filters Grid */}
      <Grid container spacing={2} sx={{ mb: 3 }}>
        {/* First Row */}
        <Grid item xs={12} sm={6}>
          <TimeframeFilter
            timeframe={timeframe}
            setTimeframe={setTimeframe}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <CoursesFilter
            filterCourses={filterCourses}
            setFilterCourses={setFilterCourses}
            fullWidth
          />
        </Grid>

        {/* Second Row */}
        <Grid item xs={12} sm={6}>
          <Box sx={{ width: '100%' }}>
            <SearchComponent
              onSearch={handleSearchChange}
              fullWidth
            />
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box sx={{ width: '100%' }}>
            <SubjectsFilter
              multiple
              options={subjects}
              value={selectedSubjects}
              onChange={handleSubjectsChange}
              sx={{
                width: '100%',
                '& .MuiAutocomplete-root': {
                  width: '100%',
                },
              }}
            />
          </Box>
        </Grid>
      </Grid>

      {/* Loading State */}
      {(filteredLoading || userLoading) && (
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
          <CircularProgress />
        </Box>
      )}

      {/* Error State */}
      {(filteredError || userError) && (
        <Box sx={{ width: '100%', textAlign: 'center', mt: 4 }}>
          <Alert severity="error">
            {filteredError || userError?.message || 'התרחשה שגיאה בלתי צפויה.'}
          </Alert>
        </Box>
      )}

      {/* Lessons Carousel */}
      {!filteredLoading && !userLoading && !(filteredError || userError) && (
        <>
          {filteredLessons.length > 0 ? (
            <StyledCarousel {...carouselSettings}>
              {filteredLessons.map((lesson) => (
                <Box key={lesson._id} sx={{ padding: 2 }}>
                  <LessonCardWidget
                    lesson={lesson}
                    isEnrolled={lesson.enrolledStudents?.some(
                      (student) => student._id === user?._id
                    )}
                    onEnroll={() => handleEnroll(lesson._id)}
                    onCancel={() => handleCancelEnrollment(lesson._id)}
                    isLoading={enrollmentLoading[lesson._id]}
                  />
                </Box>
              ))}
            </StyledCarousel>
          ) : (
            <Box sx={{ width: '100%', textAlign: 'center', mt: 4 }}>
              <Typography variant="h6">
                אין שיעורים קרובים במסגרת הסינונים שנבחרו.
              </Typography>
            </Box>
          )}
        </>
      )}

      {/* Snackbar */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbar.severity}
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </StyledCard>
  );
};

export default UpcomingLessonWidget;
