// src/components/LessonCardWidget.js

import React from 'react';
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  Button,
  Box,
  Chip,
  Tooltip,
  Badge,
  CircularProgress,
} from '@mui/material';
import EventIcon from '@mui/icons-material/Event';
import CheckIcon from '@mui/icons-material/Check';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CategoryIcon from '@mui/icons-material/Category';
import { styled } from '@mui/system';
import { useTheme } from '@mui/material/styles';
import dayjs from 'dayjs';

// Styled Components

const StyledCard = styled(Card)(({ theme }) => ({
  display: 'flex',
  flexDirection: { xs: 'column', sm: 'row' },
  mb: theme.spacing(3),
  boxShadow: 'none',
  borderRadius: theme.shape.borderRadius,
  border: `1px solid ${theme.palette.divider}`,
  transition: 'transform 0.3s, box-shadow 0.3s',
  '&:hover': {
    transform: 'translateY(-4px)',
    boxShadow: theme.shadows[4],
  },
}));

const LessonDetails = styled(CardContent)(({ theme }) => ({
  flex: 1,
  padding: theme.spacing(2),
  '&:last-child': {
    paddingBottom: theme.spacing(2),
  },
}));

const LessonTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  fontSize: '1.25rem',
  marginBottom: theme.spacing(1),
}));

const LessonDescription = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  marginBottom: theme.spacing(2),
}));

const InfoBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginBottom: theme.spacing(2),
  '& > *': {
    marginRight: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
  },
}));

const ActionButton = styled(Button)(({ theme }) => ({
  textTransform: 'none',
  borderRadius: '50px',
  boxShadow: theme.shadows[2],
  transition: 'background-color 0.3s ease, box-shadow 0.3s ease',
  '&:hover': {
    boxShadow: theme.shadows[4],
  },
}));

const StatusBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: -3,
    top: 13,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
    backgroundColor: theme.palette.success.main,
    color: theme.palette.common.white,
  },
}));

const LessonCardWidget = ({ 
  lesson, 
  isEnrolled, 
  onEnroll, 
  onCancel,
  isLoading  }) => {
  const theme = useTheme();

  return (
    <StyledCard>
      {/* Lesson Image */}
      {lesson.image && (
        <CardMedia
          component="img"
          sx={{
            width: { xs: '100%', sm: 200 },
            height: { xs: 150, sm: '100%' },
            objectFit: 'cover',
            borderTopLeftRadius: { xs: theme.shape.borderRadius, sm: theme.shape.borderRadius },
            borderBottomLeftRadius: { xs: theme.shape.borderRadius, sm: 0 },
            borderTopRightRadius: { xs: theme.shape.borderRadius, sm: 0 },
            borderBottomRightRadius: { xs: theme.shape.borderRadius, sm: theme.shape.borderRadius },
          }}
          image={lesson.image}
          alt={lesson.title}
        />
      )}
      {/* Lesson Details */}
      <LessonDetails>
        {/* Lesson Title with Category Badge */}
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
          <LessonTitle variant="h6">{lesson.title}</LessonTitle>
          {lesson.category && (
            <Tooltip title={`קטגוריה: ${lesson.category}`}>
              <Chip
                icon={<CategoryIcon />}
                label={lesson.category}
                size="small"
                sx={{
                  marginLeft: theme.spacing(1),
                  backgroundColor: theme.palette.info.light,
                  color: theme.palette.info.contrastText,
                }}
              />
            </Tooltip>
          )}
        </Box>
        {/* Lesson Description */}
        <LessonDescription variant="body2">
          {lesson.description && lesson.description.length > 150
            ? `${lesson.description.substring(0, 150)}...`
            : lesson.description || 'אין תיאור זמין.'}
        </LessonDescription>
        {/* Lesson Info */}
        <InfoBox>
          <Box>
            <EventIcon fontSize="small" sx={{ mr: 0.5 }} />
            <Typography variant="body2" color="text.secondary">
              {dayjs(lesson.startDateTime).format('D MMMM YYYY')}
            </Typography>
          </Box>
          <Box>
            <AccessTimeIcon fontSize="small" sx={{ mr: 0.5 }} />
            <Typography variant="body2" color="text.secondary">
              {dayjs(lesson.startDateTime).format('HH:mm')}
            </Typography>
          </Box>
        </InfoBox>
        {/* Action Buttons */}
        <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
          {!isEnrolled ? (
            <>
              <Button
                variant="contained"
                color="primary"
                onClick={onEnroll}
                disabled={isLoading}
                startIcon={isLoading ? <CircularProgress size={20} /> : <CheckIcon />}
              >
                {isLoading ? 'מבצע רישום...' : 'הירשם לשיעור'}
              </Button>
            </>
          ) : (
            <>
              <Button
                variant="outlined"
                color="secondary"
                onClick={onCancel}
                disabled={isLoading}
                startIcon={isLoading ? <CircularProgress size={20} /> : <CheckIcon />}
              >
                {isLoading ? 'מבטל רישום...' : 'בטל הרשמה'}
              </Button>
            </>
          )}
        </Box>
      </LessonDetails>
    </StyledCard>
  );
};

export default LessonCardWidget;
