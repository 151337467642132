// src/components/MyLessons.js

import React, { useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
  Box,
  Typography,
  Container,
  Grid,
  Card,
  CardContent,
  Button,
  CircularProgress,
  Alert,
  CardActions,
} from '@mui/material';
import { styled } from '@mui/system';
import { DataContext } from '../../context/DataContext';
import { UserContext } from '../../context/UserContext';
import dayjs from 'dayjs'; // לעיצוב תאריכים

// רכיבי עיצוב
const SectionHeader = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(4),
  fontWeight: 'bold',
  textAlign: 'center',
  [theme.breakpoints.down('sm')]: {
    fontSize: '1.8rem',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '2.5rem',
  },
}));

const LessonCard = styled(Card)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  boxShadow: 'none',
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: theme.shape.borderRadius,
  transition: 'transform 0.3s, box-shadow 0.3s',
  '&:hover': {
    transform: 'translateY(-8px)',
    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
  },
}));

const MyLessons = () => {
  const navigate = useNavigate();
  const { lessons, loading: dataLoading, error: dataError } = useContext(DataContext);
  const {
    isSignedIn,
    enrolledLessons, // מניחים שזו מערך של מזהי שיעורים או אובייקטים
    loading: userLoading,
    error: userError,
    user,
  } = useContext(UserContext);

  const mylessons = user.enrolledLessons;

  // מצב לניהול פרטי השיעורים הרשומים
  const [enrolledLessonDetails, setEnrolledLessonDetails] = useState([]);
  const [detailsLoading, setDetailsLoading] = useState(true);
  const [detailsError, setDetailsError] = useState(null);

  useEffect(() => {
    const fetchEnrolledLessons = async () => {
      if (!isSignedIn) {
        setEnrolledLessonDetails([]);
        setDetailsLoading(false);
        return;
      }

      setDetailsLoading(true);
      setDetailsError(null);

      try {
        // קביעת האם enrolledLessons מכיל מזהים או אובייקטים של שיעורים
        let enrolledIds = [];
        if (Array.isArray(mylessons)) {
          if (mylessons.length > 0) {
            const firstLesson = mylessons[0];
            if (typeof firstLesson === 'string' || typeof firstLesson === 'number') {
              // enrolledLessons מכיל מזהי שיעורים
              enrolledIds = mylessons;
            } else if (typeof firstLesson === 'object' && firstLesson._id) {
              // enrolledLessons מכיל אובייקטים של שיעורים
              enrolledIds = mylessons.map((lesson) => lesson._id);
            }
          }
        }

        // אם enrolledIds ריק, אין שיעורים להצגה
        if (enrolledIds.length === 0) {
          setEnrolledLessonDetails([]);
          setDetailsLoading(false);
          return;
        }

        // סינון השיעורים מ-DataContext שתואמים למזהי השיעורים הרשומים
        const detailedLessons = lessons.filter((lesson) => enrolledIds.includes(lesson._id));

        setEnrolledLessonDetails(detailedLessons);
      } catch (err) {
        console.error('שגיאה בשליפת פרטי השיעורים הרשומים:', err);
        setDetailsError('כשל בטעינת השיעורים שהרשמת אליהם.');
      } finally {
        setDetailsLoading(false);
      }
    };

    fetchEnrolledLessons();
  }, [isSignedIn, mylessons, lessons]);

  // טיפול במצבי טעינה ושגיאה
  if (userLoading || dataLoading || detailsLoading) {
    return (
      <Container >
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
          <CircularProgress />
        </Box>
      </Container>
    );
  }

  if (userError || dataError || detailsError) {
    return (
      <Container >
        <Box sx={{ mt: 4 }}>
          {userError && <Alert severity="error">{userError}</Alert>}
          {dataError && <Alert severity="error">{dataError}</Alert>}
          {detailsError && <Alert severity="error">{detailsError}</Alert>}
        </Box>
      </Container>
    );
  }

  // אם המשתמש אינו מחובר
  if (!isSignedIn) {
    return (
      <Container >
        <Alert severity="warning">
          עליך להתחבר כדי לצפות בשיעורים שהרשמת אליהם.
        </Alert>
        <Box sx={{ mt: 4, textAlign: 'center' }}>
          <Button variant="contained" color="primary" onClick={() => navigate('/signin')}>
            התחבר
          </Button>
        </Box>
      </Container>
    );
  }

  return (
    <main className="myLessons">
      <Container >
        {/* חלק הכותרת */}
        <Box sx={{ textAlign: 'center', mb: 8 }}>
          <Typography variant="h3" component="h1" gutterBottom>
            השיעורים שלי
          </Typography>
          <Typography variant="h6" color="textSecondary">
            להלן השיעורים שהרשמת אליהם.
          </Typography>
        </Box>

        {/* אין שיעורים רשומים */}
        {enrolledLessonDetails.length === 0 ? (
          <Box sx={{ mt: 4, textAlign: 'center' }}>
            <Alert severity="info">עדיין לא נרשמת לשיעורים.</Alert>
            <Box sx={{ mt: 2 }}>
              <Button variant="contained" color="primary" onClick={() => navigate('/all-courses')}>
                דפדף בקורסים
              </Button>
            </Box>
          </Box>
        ) : (
          /* רשת השיעורים */
          <Grid container spacing={4}>
            {enrolledLessonDetails.map((lesson) => (
              <Grid item key={lesson._id} xs={12} sm={4} md={4}>
                <LessonCard>
                  {/* תוכן השיעור */}
                  <CardContent sx={{ flexGrow: 1 }}>
                    <Typography
                      variant="h5"
                      component="h2"
                      gutterBottom
                      sx={{
                        fontSize: {
                          xs: '1rem',
                          sm: '1.1rem',
                          md: '1.2rem',
                        },
                      }}
                    >
                      {lesson.title}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      gutterBottom
                      sx={{
                        fontSize: {
                          xs: '0.8rem',
                          sm: '0.9rem',
                          md: '1rem',
                        },
                      }}
                    >
                      {lesson.description.length > 100
                        ? `${lesson.description.substring(0, 100)}...`
                        : lesson.description}
                    </Typography>
                    {/* פרטים נוספים */}
                    <Box sx={{ mt: 2 }}>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        sx={{
                          fontSize: {
                            xs: '0.8rem',
                            sm: '0.9rem',
                            md: '1rem',
                          },
                        }}
                      >
                        <strong>קורס:</strong> {lesson.course?.title || 'אין מידע'}
                      </Typography>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        sx={{
                          fontSize: {
                            xs: '0.8rem',
                            sm: '0.9rem',
                            md: '1rem',
                          },
                        }}
                      >
                        <strong>התחלה:</strong>{' '}
                        {lesson.startDateTime
                          ? dayjs(lesson.startDateTime).format('D MMMM YYYY HH:mm')
                          : 'אין מידע'}
                      </Typography>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        sx={{
                          fontSize: {
                            xs: '0.8rem',
                            sm: '0.9rem',
                            md: '1rem',
                          },
                        }}
                      >
                        <strong>סיום:</strong>{' '}
                        {lesson.endDateTime
                          ? dayjs(lesson.endDateTime).format('D MMMM YYYY HH:mm')
                          : 'אין מידע'}
                      </Typography>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        sx={{
                          fontSize: {
                            xs: '0.8rem',
                            sm: '0.9rem',
                            md: '1rem',
                          },
                        }}
                      >
                        <strong>משך:</strong> {lesson.duration ? `${lesson.duration} דקות` : 'לא ידוע'}
                      </Typography>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        sx={{
                          fontSize: {
                            xs: '0.8rem',
                            sm: '0.9rem',
                            md: '1rem',
                          },
                        }}
                      >
                        <strong>סטטוס:</strong> {lesson.status.charAt(0).toUpperCase() + lesson.status.slice(1)}
                      </Typography>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        sx={{
                          fontSize: {
                            xs: '0.8rem',
                            sm: '0.9rem',
                            md: '1rem',
                          },
                        }}
                      >
                        <strong>שידור חי:</strong> {lesson.isLive ? 'כן' : 'לא'}
                      </Typography>
                    </Box>
                  </CardContent>

                  {/* פעולות השיעור */}
                  <CardActions sx={{ justifyContent: 'center', p: 2 }}>
                    <Button
                      variant="contained"
                      color="primary"
                      component={Link}
                      to={`/lessons/${lesson._id}`}
                      sx={{
                        textTransform: 'none',
                        borderRadius: '50px',
                        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                        transition: 'background-color 0.3s ease',
                        '&:hover': {
                          backgroundColor: 'primary.dark',
                        },
                      }}
                    >
                      צפה בשיעור
                    </Button>
                  </CardActions>
                </LessonCard>
              </Grid>
            ))}
          </Grid>
        )}
      </Container>
    </main>
  );
};

export default MyLessons;
