// src/pages/Profile.js
import React, { useContext, useState, useEffect } from 'react';
import {
  Container,
  Grid,
  Box,
  Typography,
  CircularProgress,
  Alert,
  Card,
  CardContent,
  Chip,
} from '@mui/material';
import { UserContext } from '../../context/UserContext';
import EditableField from './EditField'; // Adjust import path
import dayjs from 'dayjs'; // Import dayjs for date formatting

const Profile = () => {
  const {
    user,
    updateUser,
    loading,
    error,
    setError,
    fetchUserData,
  } = useContext(UserContext);

  const [globalSuccess, setGlobalSuccess] = useState('');

  // Function to handle saving updates
  const handleSave = async (field, value) => {
    // Prepare the update payload
    const payload = {};
    payload[field] = value;

    try {
      await updateUser(payload);
      setGlobalSuccess('הפרטים עודכנו בהצלחה!');
      // Optionally, refetch user data
      await fetchUserData();
      // Clear success message after a delay
      setTimeout(() => setGlobalSuccess(''), 3000);
    } catch (err) {
      // Error is handled in EditableField component
      // Optionally, you can handle global errors here
    }
  };

  // Validation functions
  const validateFullName = (value) => {
    if (!value.trim()) {
      return 'השם המלא הינו חובה.';
    }
    return '';
  };

  const validatePhoneNumber = (value) => {
    if (value && !/^\d{10}$/.test(value)) {
      return 'מספר הטלפון אינו בתבנית נכונה. יש להזין 10 ספרות.';
    }
    return '';
  };

  const validateEmail = (value) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!value.trim()) {
      return 'האימייל הינו חובה.';
    }
    if (!emailRegex.test(value)) {
      return 'האימייל אינו בתבנית נכונה.';
    }
    return '';
  };

  return (
    <main className="profile">
      <Container sx={{ py: { xs: 4, sm: 6 } }}>
        {/* Profile Header */}
        <Box sx={{ textAlign: 'center', mb: { xs: 4, sm: 6 } }}>
          <Typography variant="h4" component="h1" gutterBottom sx={{ fontSize: { xs: '1.75rem', sm: '2.5rem' } }}>
            פרופיל המשתמש
          </Typography>
          <Typography variant="subtitle1" color="textSecondary" sx={{ fontSize: { xs: '1rem', sm: '1.25rem' } }}>
            נהל את הפרטים האישיים שלך והצפה במידע החשבון שלך.
          </Typography>
        </Box>

        {/* Global Success Message */}
        {globalSuccess && (
          <Alert severity="success" sx={{ mb: 2 }}>
            {globalSuccess}
          </Alert>
        )}

        {/* Global Error Message */}
        {error && (
          <Alert severity="error" sx={{ mb: 2 }}>
            {error}
          </Alert>
        )}

        <Grid container spacing={4}>
          {/* Editable Fields Section */}
          <Grid item xs={12} md={6}>
            <Box>
              <Typography variant="h5" gutterBottom sx={{ fontSize: { xs: '1.25rem', sm: '1.5rem' } }}>
                עדכון פרטים אישיים
              </Typography>
              <Box sx={{ mt: 2 }}>
                {/* Full Name Field */}
                <EditableField
                  label="שם מלא"
                  value={user.fullName}
                  onSave={(value) => handleSave('fullName', value)}
                  required
                  validation={validateFullName}
                />

                {/* Phone Number Field */}
                <EditableField
                  label="מספר טלפון"
                  value={user.phoneNumber}
                  onSave={(value) => handleSave('phoneNumber', value)}
                  required={false}
                  type="tel"
                  validation={validatePhoneNumber}
                />

                {/* Email Field */}
                <EditableField
                  label="אימייל"
                  value={user.email}
                  onSave={(value) => handleSave('email', value)}
                  required
                  type="email"
                  validation={validateEmail}
                />
              </Box>
            </Box>
          </Grid>

          {/* Enrolled Courses Section */}
          <Grid item xs={12}>
            <Box>
              <Typography variant="h5" gutterBottom sx={{ fontSize: { xs: '1.25rem', sm: '1.5rem' } }}>
                הקורסים שלי
              </Typography>
              {user.enrolledCourses && user.enrolledCourses.length > 0 ? (
                <Grid container spacing={2} sx={{ mt: 2 }}>
                  {user.enrolledCourses.map((object) => (
                    <Grid item xs={12} sm={6} md={4} key={object.course._id}>
                      <Card
                        sx={{
                          height: '100%',
                          display: 'flex',
                          flexDirection: 'column',
                          border: '1px solid #e0e0e0',
                          boxShadow: 'none',
                          transition: 'transform 0.3s, box-shadow 0.3s',
                          '&:hover': {
                            transform: 'translateY(-4px)',
                            boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)',
                          },
                        }}
                      >
                        <CardContent>
                          <Typography variant="h6" gutterBottom sx={{ fontSize: { xs: '1rem', sm: '1.25rem' } }}>
                            {object.course.title}
                          </Typography>
                          <Typography variant="body2" color="textSecondary" sx={{ mb: 1, fontSize: { xs: '0.9rem', sm: '1rem' } }}>
                            {object.course.description}
                          </Typography>
                        </CardContent>
                      </Card>
                    </Grid>
                  ))}
                </Grid>
              ) : (
                <Typography sx={{ mt: 2, fontSize: { xs: '1rem', sm: '1.25rem' } }}>
                  אתה לא רשום כרגע בקורסים.
                </Typography>
              )}
            </Box>
          </Grid>

          {/* Current Plan Section */}
          <Grid item xs={12}>
            <Box>
              <Typography variant="h5" gutterBottom sx={{ fontSize: { xs: '1.25rem', sm: '1.5rem' } }}>
                התוכנית הנוכחית שלי
              </Typography>
              {user.subscriptions && user.subscriptions.length > 0 ? (
                <Card sx={{ mt: 2, p: { xs: 2, sm: 3 } }}>
                  {/* Assuming the latest subscription is the current one */}
                  <CardContent>
                    <Typography variant="h6" gutterBottom sx={{ fontSize: { xs: '1.25rem', sm: '1.5rem' } }}>
                      {user.subscriptions[user.subscriptions.length - 1].plan.title}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" sx={{ fontSize: { xs: '0.9rem', sm: '1rem' } }}>
                      תאריך התחלה: {dayjs(user.subscriptions[user.subscriptions.length - 1].startDate).format('D MMMM YYYY')}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" sx={{ fontSize: { xs: '0.9rem', sm: '1rem' } }}>
                      תאריך סיום: {dayjs(user.subscriptions[user.subscriptions.length - 1].endDate).format('D MMMM YYYY')}
                    </Typography>
                    <Chip
                      label={`סטטוס: ${user.subscriptions[user.subscriptions.length - 1].status}`}
                      color={user.subscriptions[user.subscriptions.length - 1].status === 'active' ? 'success' : 'default'}
                      sx={{ mt: 1, fontSize: { xs: '0.75rem', sm: '0.875rem' } }}
                    />
                  </CardContent>
                </Card>
              ) : (
                <Typography sx={{ mt: 2, fontSize: { xs: '1rem', sm: '1.25rem' } }}>
                  אין לך תוכנית מנוי פעילה.
                </Typography>
              )}
            </Box>
          </Grid>
        </Grid>
      </Container>
    </main>
  );
};

export default Profile;
