// src/components/Footer.js

import React from 'react';
import { Box, Typography, Link } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const Footer = () => {
  const theme = useTheme();

  return (
    <Box
      component="footer"
      sx={{
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.text.secondary,
        p: { xs: 2, sm: 3 },
        textAlign: 'center',
        borderTop: `1px solid ${theme.palette.divider}`,
      }}
    >
      <Typography variant="body2">
        <Link href="/privacy" color="inherit" sx={{ mx: 1, textDecoration: 'none' }}>
          פרטיות
        </Link>
        |
        <Link href="/policy" color="inherit" sx={{ mx: 1, textDecoration: 'none' }}>
          מדיניות
        </Link>
        |
        <Link href="/terms" color="inherit" sx={{ mx: 1, textDecoration: 'none' }}>
          תנאים
        </Link>
      </Typography>
      <Typography variant="caption" display="block" sx={{ mt: 1 }}>
        © {new Date().getFullYear()} Infinity. כל הזכויות שמורות.
      </Typography>
    </Box>
  );
};

export default Footer;
