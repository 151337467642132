// src/components/MyCourses.js

import React, { useContext, useEffect, useState, memo } from 'react';
import { Link } from 'react-router-dom';
import {
  Box,
  Typography,
  Container,
  Grid,
  Card,
  CardContent,
  Button,
  CircularProgress,
  Alert,
} from '@mui/material';
import { styled } from '@mui/system';
import { DataContext } from '../../context/DataContext';
import { UserContext } from '../../context/UserContext';
import IconDisplay from '../Icon/IconDisplay';
import { useTheme } from '@mui/material/styles';

// Styled Components

const SectionHeader = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(4),
  fontWeight: 700,
  textAlign: 'center',
  [theme.breakpoints.down('sm')]: {
    fontSize: '1.8rem',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '2.5rem',
  },
}));

// Course Type Badge positioned at the top-left corner
const CourseTypeBadge = styled(Box)(({ theme, isLive }) => ({
  position: 'absolute',
  top: theme.spacing(1),
  left: theme.spacing(1),
  padding: theme.spacing(0.5, 1),
  borderRadius: theme.shape.borderRadius,
  backgroundColor: isLive
    ? theme.palette.primary.main
    : theme.palette.warning.main,
  color: theme.palette.common.white,
  fontWeight: 600,
  zIndex: 1,
  fontSize: '0.75rem',
}));

const MyCourses = () => {
  const theme = useTheme();
  const { user, isSignedIn, loading: userLoading, error: userError, fetchUserData } = useContext(UserContext);
  const { courses , loading: dataLoading, error: dataError } = useContext(DataContext);

  const [enrolledCourses, setEnrolledCourses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setLocalError] = useState(null);

  useEffect(() => {
    const fetchEnrolledCourses = async () => {
      setLoading(true);
      setLocalError(null);
      try {
        // Check if user has enrolled courses
        if (user.enrolledCourses && user.enrolledCourses.length > 0) {
          setEnrolledCourses(user.enrolledCourses);
        } else {
          setEnrolledCourses([]);
        }
      } catch (err) {
        console.error('Error fetching enrolled courses:', err);
        setLocalError('התרחשה שגיאה בעת שליפת הקורסים הרשומים.');
      } finally {
        setLoading(false);
      }
    };

    if (user && courses.length > 0) {
      fetchEnrolledCourses();
    } else if (isSignedIn && !courses.length) {
      // If data is not loaded yet, fetch user data
      fetchUserData();
    }
  }, [user, courses, fetchUserData, isSignedIn]);

  if (userLoading || dataLoading || loading) {
    return (
      <Container>
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
          <CircularProgress />
        </Box>
      </Container>
    );
  }

  if (dataError) {
    return (
      <Container>
        <Alert severity="error">{dataError}</Alert>
      </Container>
    );
  }

  if (userError) {
    return (
      <Container>
        <Alert severity="error">{userError}</Alert>
      </Container>
    );
  }

  return (
    <main className="my-courses">
      <Container dir="rtl">
        {/* Header Section */}
        <Box sx={{ textAlign: 'center', mb: 8 }}>
          <SectionHeader variant="h3">הקורסים שלי</SectionHeader>
          <Typography variant="h6" color="text.secondary">
            המשך בלימודים והתקדם בקורסים שהרשמת אליהם.
          </Typography>
        </Box>

        {/* List of Enrolled Courses */}
        {enrolledCourses.length === 0 ? (
          <Box sx={{ textAlign: 'center' }}>
            <Typography variant="h6" color="text.secondary">
              אין לך קורסים רשומים כרגע.
            </Typography>
            <Button
              variant="contained"
              color="primary"
              component={Link}
              to="/all-courses"
              sx={{
                mt: 2,
                textTransform: 'none',
                borderRadius: '50px',
                boxShadow: theme.shadows[2],
                transition: 'background-color 0.3s ease',
                '&:hover': {
                  backgroundColor: theme.palette.primary.dark,
                },
              }}
            >
              גלה קורסים חדשים
            </Button>
          </Box>
        ) : (
          <Grid container spacing={4}>
            {enrolledCourses.map((enrolledCourse) => (
              <Grid item key={enrolledCourse.course._id} xs={12} sm={6} md={4}>
                <Card
                  sx={{
                    position: 'relative', // To position the badge
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    boxShadow: theme.shadows[2],
                    borderRadius: theme.shape.borderRadius,
                    transition: 'transform 0.3s, box-shadow 0.3s',
                    '&:hover': {
                      transform: 'translateY(-8px)',
                      boxShadow: theme.shadows[4],
                    },
                  }}
                >
                  {/* Course Type Badge */}
                  <CourseTypeBadge isLive={enrolledCourse.course.isLive} aria-label={`סוג קורס: ${enrolledCourse.course.isLive ? 'רץ בלייב' : 'מוקלט'}`}>
                    {enrolledCourse.course.isLive ? 'רץ בלייב' : 'מוקלט'}
                  </CourseTypeBadge>

                  {/* Icon Display */}
                  <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                    <IconDisplay
                      iconName={enrolledCourse.course.icon}
                      size={80}
                      color="primary.main"
                    /> 
                  </Box>
                  
                  {/* Card Content */}
                  <CardContent sx={{ flexGrow: 1, textAlign: 'center' }}>
                    <Typography
                      variant="h5"
                      component="h2"
                      gutterBottom
                      sx={{
                        fontSize: {
                          xs: '1.2rem',
                          sm: '1.4rem',
                          md: '1.6rem',
                        },
                      }}
                    >
                      {enrolledCourse.course.title}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{
                        fontSize: {
                          xs: '0.9rem',
                          sm: '1rem',
                          md: '1.1rem',
                        },
                      }}
                    >
                      {enrolledCourse.course.description && enrolledCourse.course.description.length > 100
                        ? `${enrolledCourse.course.description.substring(0, 100)}...`
                        : enrolledCourse.course.description}
                    </Typography>
                  </CardContent>
                  
                  {/* Action Button */}
                  <Box sx={{ p: 2, textAlign: 'center' }}>
                    <Button
                      variant="contained"
                      color="primary"
                      component={Link}
                      to={`/course/${enrolledCourse.course._id}/details`}
                      sx={{
                        textTransform: 'none',
                        borderRadius: '50px',
                        boxShadow: theme.shadows[2],
                        transition: 'background-color 0.3s ease',
                        '&:hover': {
                          backgroundColor: theme.palette.primary.dark,
                        },
                      }}
                      aria-label={`להמשך לימודים בקורס ${enrolledCourse.course.title}`}
                    >
                      להמשך לימודים
                    </Button>
                  </Box>
                </Card>
              </Grid>
            ))}
          </Grid>
        )}

        {/* User Error Message */}
        {error && (
          <Box sx={{ mt: 4, textAlign: 'center' }}>
            <Alert severity="error">{error}</Alert>
          </Box>
        )}
      </Container>
    </main>
  );
};

export default MyCourses;
