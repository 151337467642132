// src/components/SubscriptionCanceled.js

import React from 'react';
import { Box, Typography, Container, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const SubscriptionCanceled = () => {
  const navigate = useNavigate();

  return (
    <Container sx={{ py: 8, textAlign: 'center' }}>
      <Typography variant="h4" gutterBottom>
        Subscription Canceled
      </Typography>
      <Typography variant="body1" color="textSecondary" gutterBottom>
        Your subscription has been successfully canceled. We're sorry to see you go!
      </Typography>
      <Button variant="contained" color="primary" onClick={() => navigate('/')}>
        Go to Home
      </Button>
    </Container>
  );
};

export default SubscriptionCanceled;
