// src/components/Widgets/Filters/SubjectsFilter.js

import React from 'react';
import { Autocomplete, TextField } from '@mui/material';
import PropTypes from 'prop-types';

const SubjectsFilter = ({
  multiple = false,
  options,
  value,
  onChange,
  sx = {},
}) => {
  return (
    <Autocomplete
      multiple={multiple}
      options={options}
      getOptionLabel={(option) => option.name} // Ensure 'name' exists
      value={value}
      onChange={onChange}
      renderInput={(params) => <TextField {...params} label="נושאים" />}
      sx={sx}
    />
  );
};

SubjectsFilter.propTypes = {
  multiple: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      // Add other required fields if any
    })
  ).isRequired,
  value: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      // Add other required fields if any
    })
  ).isRequired,
  onChange: PropTypes.func.isRequired,
  sx: PropTypes.object,
};

export default SubjectsFilter;
