// src/components/AccessibilitySidebar.js

import React, { useState, useContext } from 'react';
import {
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
  useMediaQuery,
  Divider,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@mui/material';
import {
  Accessibility,
  Close,
  FontDownload,
  Contrast,
  Brightness4,
  Brightness7,
  MotionPhotosOff, // מוחק את 'Motion' ומחליף ב-MotionPhotosOff
  TextFields,
  FontDownloadOff,
  Palette,
  RecordVoiceOver, // מוחק את 'TextToSpeech' ומחליף ב-RecordVoiceOver
} from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/material/styles';
import { ThemeContext } from '../../context/ThemeContext';

const useStyles = makeStyles((theme) => ({
  drawerPaper: {
    width: 300,
    padding: theme.spacing(2),
  },
  openButton: {
    position: 'fixed',
    top: theme.spacing(2),
    right: theme.spacing(2),
    zIndex: 1300, // מבטיח שהכפתור יישאר מעל אלמנטים אחרים
  },
  closeButton: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  section: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

const AccessibilitySidebar = () => {
  const classes = useStyles();
  const theme = useTheme();
  const {
    mode,
    highContrast,
    fontSize,
    reduceMotion,
    dyslexiaFont,
    colorBlindMode,
    textToSpeech,
    toggleTheme,
    toggleHighContrast,
    increaseFontSize,
    decreaseFontSize,
    toggleReduceMotion,
    toggleDyslexiaFont,
    setColorBlindMode,
    toggleTextToSpeech,
    setFontSize, // הנחה: setFontSize נגיש מהקונטקסט
  } = useContext(ThemeContext);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [open, setOpen] = useState(false);

  const toggleDrawer = (state) => () => {
    setOpen(state);
  };

  // Handler לאיפוס גודל הגופן לברירת מחדל
  const resetFontSize = () => {
    setFontSize(16); // אפס גודל גופן ל-16px
  };

  return (
    <>
      <Tooltip title="אפשרויות נגישות">
        <IconButton
          color="primary"
          onClick={toggleDrawer(true)}
          className={classes.openButton}
          aria-label="פתח אפשרויות נגישות"
        >
          <Accessibility />
        </IconButton>
      </Tooltip>
      <Drawer
        anchor="right"
        open={open}
        onClose={toggleDrawer(false)}
        classes={{ paper: classes.drawerPaper }}
        variant={isMobile ? 'temporary' : 'persistent'}
      >
        <div className={classes.closeButton}>
          <IconButton onClick={toggleDrawer(false)} aria-label="סגור אפשרויות נגישות">
            <Close />
          </IconButton>
        </div>
        <List>
          {/* בקרות גודל גופן */}
          <ListItem>
            <ListItemIcon>
              <TextFields />
            </ListItemIcon>
            <ListItemText primary="גודל גופן" />
          </ListItem>
          <ListItem button onClick={increaseFontSize}>
            <ListItemIcon>
              <FontDownload />
            </ListItemIcon>
            <ListItemText primary="הגדל גודל גופן" />
          </ListItem>
          <ListItem button onClick={decreaseFontSize}>
            <ListItemIcon>
              <FontDownloadOff />
            </ListItemIcon>
            <ListItemText primary="הקטן גודל גופן" />
          </ListItem>
          <ListItem button onClick={resetFontSize}>
            <ListItemIcon>
              <FontDownloadOff />
            </ListItemIcon>
            <ListItemText primary="אפס גודל גופן" />
          </ListItem>
          <Divider className={classes.section} />

          {/* בקרות נושא */}
          <ListItem>
            <ListItemIcon>
              <Contrast />
            </ListItemIcon>
            <ListItemText primary="הגדרות נושא" />
          </ListItem>
          <ListItem button onClick={toggleTheme}>
            <ListItemIcon>
              {mode === 'dark' ? <Brightness7 /> : <Brightness4 />}
            </ListItemIcon>
            <ListItemText
              primary={mode === 'dark' ? 'החלף למצב בהיר' : 'החלף למצב כהה'}
            />
          </ListItem>
          <ListItem button onClick={toggleHighContrast}>
            <ListItemIcon>
              <Palette />
            </ListItemIcon>
            <ListItemText
              primary={highContrast ? 'השבת ניגודיות גבוהה' : 'הפעל ניגודיות גבוהה'}
            />
          </ListItem>
          <Divider className={classes.section} />

          {/* בקרות תנועה */}
          <ListItem>
            <ListItemIcon>
              <MotionPhotosOff /> {/* שימוש באייקון MotionPhotosOff */}
            </ListItemIcon>
            <ListItemText primary="הגדרות תנועה" />
          </ListItem>
          <ListItem button onClick={toggleReduceMotion}>
            <ListItemIcon>
              <MotionPhotosOff />
            </ListItemIcon>
            <ListItemText
              primary={reduceMotion ? 'השבת הפחתת תנועה' : 'הפעל הפחתת תנועה'}
            />
          </ListItem>
          <Divider className={classes.section} />

          {/* הגדרות גופן דיסלקסיה */}
          <ListItem>
            <ListItemIcon>
              <FontDownload />
            </ListItemIcon>
            <ListItemText primary="הגדרות גופן" />
          </ListItem>
          <ListItem button onClick={toggleDyslexiaFont}>
            <ListItemIcon>
              <FontDownload />
            </ListItemIcon>
            <ListItemText
              primary={dyslexiaFont ? 'השבת גופן ידידותי לדיסלקסיה' : 'הפעל גופן ידידותי לדיסלקסיה'}
            />
          </ListItem>
          <Divider className={classes.section} />

          {/* מצבי עיוורון צבעים */}
          <ListItem>
            <ListItemIcon>
              <Palette />
            </ListItemIcon>
            <ListItemText primary="מצבי עיוורון צבעים" />
          </ListItem>
          <FormControl component="fieldset" className={classes.section}>
            <FormLabel component="legend">בחר מצב</FormLabel>
            <RadioGroup
              aria-label="מצב עיוורון צבעים"
              name="color-blind-mode"
              value={colorBlindMode}
              onChange={(e) => setColorBlindMode(e.target.value)}
            >
              <FormControlLabel value="none" control={<Radio />} label="ללא" />
              <FormControlLabel value="protanopia" control={<Radio />} label="פרוטנופיה" />
              <FormControlLabel value="deuteranopia" control={<Radio />} label="דויטרנופיה" />
              <FormControlLabel value="tritanopia" control={<Radio />} label="טריטאנופיה" />
            </RadioGroup>
          </FormControl>
          <Divider className={classes.section} />

          {/* המרת טקסט לדיבור */}
          <ListItem>
            <ListItemIcon>
              <RecordVoiceOver /> {/* שימוש באייקון RecordVoiceOver */}
            </ListItemIcon>
            <ListItemText primary="המרת טקסט לדיבור" />
          </ListItem>
          <ListItem button onClick={toggleTextToSpeech}>
            <ListItemIcon>
              <RecordVoiceOver />
            </ListItemIcon>
            <ListItemText
              primary={textToSpeech ? 'השבת המרת טקסט לדיבור' : 'הפעל המרת טקסט לדיבור'}
            />
          </ListItem>
          {/* ניתן להוסיף אפשרויות נגישות נוספות כאן */}
        </List>
      </Drawer>
    </>
  );
};

export default AccessibilitySidebar;
