// src/components/AllPlans.js

import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Box,
  Typography,
  Container,
  Grid,
  Card,
  CardContent,
  CardActions,
  Button,
  CircularProgress,
  Alert,
  List,
  ListItem,
  ListItemText,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Divider,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/system';

// Styled Components
const PlanTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 'bold',
  color: theme.palette.primary.main,
  [theme.breakpoints.down('sm')]: {
    fontSize: '1.4rem',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '1.8rem',
  },
}));

const StyledCard = styled(Card)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: theme.shape.borderRadius,
  transition: 'transform 0.3s, box-shadow 0.3s',
  '&:hover': {
    transform: 'translateY(-8px)',
    boxShadow: theme.shadows[4],
  },
}));

const SelectButton = styled(Button)(({ theme }) => ({
  width: '100%',
  paddingY: theme.spacing(1.5),
  fontSize: '1rem',
  borderRadius: theme.shape.borderRadius,
  textTransform: 'none',
  boxShadow: theme.shadows[2],
  transition: 'background-color 0.3s ease, box-shadow 0.3s ease',
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
    boxShadow: theme.shadows[4],
  },
}));

const AllPlans = () => {
  const [plans, setPlans] = useState([]);
  const [loading, setLoading] = useState(false);
  const [localError, setLocalError] = useState(null);

  // State for Lessons Dialog
  const [openLessonsDialog, setOpenLessonsDialog] = useState(false);
  const [selectedPlanForLessons, setSelectedPlanForLessons] = useState(null);

  // State for Courses Dialog
  const [openCoursesDialog, setOpenCoursesDialog] = useState(false);
  const [selectedPlanForCourses, setSelectedPlanForCourses] = useState(null);

  useEffect(() => {
    const fetchPlans = async () => {
      setLoading(true);
      setLocalError(null);
      try {
        const response = await fetch(`${process.env.REACT_APP_INFINITY_BACK_URL}/api/plans`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });

        if (response.ok) {
          const data = await response.json();
          setPlans(data);
        } else {
          const errorData = await response.json();
          console.error('נכשל בקבלת התוכניות:', errorData.error || response.statusText);
          setLocalError(errorData.error || 'נכשל בקבלת התוכניות.');
        }
      } catch (err) {
        console.error('שגיאה בקבלת התוכניות:', err);
        setLocalError('אירעה שגיאה בלתי צפויה בעת קבלת התוכניות.');
      } finally {
        setLoading(false);
      }
    };

    fetchPlans();
  }, []);

  // Handlers for Lessons Dialog
  const handleOpenLessonsDialog = (plan) => {
    setSelectedPlanForLessons(plan);
    setOpenLessonsDialog(true);
  };

  const handleCloseLessonsDialog = () => {
    setOpenLessonsDialog(false);
    setSelectedPlanForLessons(null);
  };

  // Handlers for Courses Dialog
  const handleOpenCoursesDialog = (plan) => {
    setSelectedPlanForCourses(plan);
    setOpenCoursesDialog(true);
  };

  const handleCloseCoursesDialog = () => {
    setOpenCoursesDialog(false);
    setSelectedPlanForCourses(null);
  };

  if (loading) {
    return (
      <Container sx={{ py: 8, textAlign: 'center' }}>
        <CircularProgress />
      </Container>
    );
  }

  return (
    <main className="all-plans">
      <Container>
        {/* Header Section */}
        <Box sx={{ textAlign: 'center', mb: 8 }}>
          <Typography variant="h3" component="h1" gutterBottom>
            בחר את התוכנית המושלמת עבורך
          </Typography>
          <Typography variant="h6" color="textSecondary">
            בחר תוכנית המתאימה ביותר לצרכי הלמידה וההעדפות שלך.
          </Typography>
        </Box>

        {/* Display Local Error if Exists */}
        {localError && (
          <Box sx={{ mb: 4 }}>
            <Alert severity="error">{localError}</Alert>
          </Box>
        )}

        {/* Plans Grid */}
        <Grid container spacing={4}>
          {plans.map((plan) => (
            <Grid item key={plan._id} xs={12} sm={6} md={4}>
              <StyledCard>
                <CardContent sx={{ flexGrow: 1 }}>
                  <PlanTitle variant="h5" component="h2" gutterBottom>
                    {plan.title}
                  </PlanTitle>
                  <Typography variant="body1" color="textSecondary" gutterBottom>
                    {plan.description}
                  </Typography>
                  <Typography variant="h6" color="primary" gutterBottom>
                    ₪{plan.price}
                  </Typography>
                  <List>
                    {plan.features.map((feature, index) => (
                      <ListItem key={index} disableGutters>
                        <ListItemText primary={`• ${feature}`} />
                      </ListItem>
                    ))}
                  </List>

                  {/* Conditionally Render Courses if They Exist */}
                  {plan.courses && plan.courses.length > 0 && (
                    <Box sx={{ mt: 2 }}>
                      <Typography variant="subtitle1" gutterBottom>
                        קורסים כלולים:
                      </Typography>
                      <List>
                        {plan.courses.map((course) => (
                          <ListItem key={course._id} disableGutters>
                            <ListItemText primary={`• ${course.title}`} />
                          </ListItem>
                        ))}
                      </List>
                    </Box>
                  )}

                  {/* Conditionally Render Lesson Count if They Exist */}
                  {plan.lessons && plan.lessons.length > 0 && (
                    <Box sx={{ mt: 2 }}>
                      <Typography variant="subtitle1" gutterBottom>
                        מספר השיעורים: {plan.lessons.length}
                      </Typography>
                    </Box>
                  )}
                </CardContent>
                <CardActions sx={{ padding: 2 }}>
                  <Box
                    sx={{
                      width: '100%',
                      display: 'flex',
                      gap: 2,
                      flexWrap: 'wrap',
                    }}
                  >
                    {/* Conditionally Render Show Lessons Button */}
                    {plan.lessons && plan.lessons.length > 0 && (
                      <Tooltip title="הצג שיעורים">
                        <SelectButton
                          variant="contained"
                          color="secondary" // Consistent color with "Show Courses"
                          onClick={() => handleOpenLessonsDialog(plan)}
                          sx={{ flex: '1 1 auto' }}
                        >
                          הצג שיעורים
                        </SelectButton>
                      </Tooltip>
                    )}

                    {/* Conditionally Render Show Courses Button */}
                    {plan.courses && plan.courses.length > 0 && (
                      <Tooltip title="הצג קורסים">
                        <SelectButton
                          variant="contained"
                          color="secondary"
                          onClick={() => handleOpenCoursesDialog(plan)}
                          sx={{ flex: '1 1 auto' }}
                        >
                          הצג קורסים
                        </SelectButton>
                      </Tooltip>
                    )}

                    {/* Always Render Select Plan Button */}
                    <Tooltip title="המשך לתשלום">
                      <SelectButton
                        variant="contained"
                        color="primary"
                        component={Link}
                        to={`/checkout/${plan._id}`} // Pass the plan ID to the checkout page
                        sx={{ flex: '1 1 auto' }}
                      >
                        בחר תוכנית
                      </SelectButton>
                    </Tooltip>
                  </Box>
                </CardActions>
              </StyledCard>
            </Grid>
          ))}
        </Grid>

        {/* Lessons Dialog */}
        {selectedPlanForLessons && (
          <Dialog
            open={openLessonsDialog}
            onClose={handleCloseLessonsDialog}
            fullWidth
            maxWidth="md"
            aria-labelledby="lessons-dialog-title"
          >
            <DialogTitle id="lessons-dialog-title">
              שיעורים בתוכנית: {selectedPlanForLessons.title}
            </DialogTitle>
            <DialogContent dividers>
              {selectedPlanForLessons.lessons && selectedPlanForLessons.lessons.length > 0 ? (
                <List>
                  {selectedPlanForLessons.lessons.map((lesson) => (
                    <Box key={lesson._id} sx={{ mb: 2 }}>
                      <Typography variant="h6" gutterBottom>
                        {lesson.title}
                      </Typography>
                      <Typography variant="body2" color="textSecondary" gutterBottom>
                        {lesson.description}
                      </Typography>
                      <List>
                        <ListItem disableGutters>
                          <ListItemText
                            primary={`• זמן התחלה: ${new Date(lesson.startDateTime).toLocaleString('he-IL')}`}
                          />
                        </ListItem>
                        <ListItem disableGutters>
                          <ListItemText
                            primary={`• זמן סיום: ${new Date(lesson.endDateTime).toLocaleString('he-IL')}`}
                          />
                        </ListItem>
                        <ListItem disableGutters>
                          <ListItemText
                            primary={`• חומר לימוד עיקרי: ${
                              lesson.mainStudyMaterial ? lesson.mainStudyMaterial.title : 'N/A'
                            }`}
                          />
                        </ListItem>
                      </List>
                      <Divider sx={{ my: 2 }} />
                    </Box>
                  ))}
                </List>
              ) : (
                <Typography variant="body2" color="textSecondary">
                  אין שיעורים כלולים בתוכנית זו.
                </Typography>
              )}
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseLessonsDialog} color="primary">
                סגור
              </Button>
            </DialogActions>
          </Dialog>
        )}

        {/* Courses Dialog with Collapsible Courses and Units */}
        {selectedPlanForCourses && (
          <Dialog
            open={openCoursesDialog}
            onClose={handleCloseCoursesDialog}
            fullWidth
            maxWidth="lg"
            aria-labelledby="courses-dialog-title"
          >
            <DialogTitle id="courses-dialog-title">
              קורסים בתוכנית: {selectedPlanForCourses.title}
            </DialogTitle>
            <DialogContent dividers>
              {selectedPlanForCourses.courses && selectedPlanForCourses.courses.length > 0 ? (
                <List>
                  {selectedPlanForCourses.courses.map((course) => (
                    <Box key={course._id} sx={{ mb: 2 }}>
                      {/* Accordion for Each Course */}
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls={`course-content-${course._id}`}
                          id={`course-header-${course._id}`}
                        >
                          <Typography variant="h6">{course.title}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography variant="body1" color="textSecondary" gutterBottom>
                            {course.description}
                          </Typography>

                          {/* Units within the Course */}
                          {course.units && course.units.length > 0 ? (
                            <List>
                              {course.units.map((unit, unitIndex) => (
                                <Box key={unit._id} sx={{ mb: 2 }}>
                                  {/* Accordion for Each Unit */}
                                  <Accordion>
                                    <AccordionSummary
                                      expandIcon={<ExpandMoreIcon />}
                                      aria-controls={`unit-content-${unit._id}`}
                                      id={`unit-header-${unit._id}`}
                                    >
                                      <Typography variant="subtitle1">
                                        יחידה {unitIndex + 1}: {unit.title}
                                      </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                      <Typography variant="body2" color="textSecondary" gutterBottom>
                                        {unit.description}
                                      </Typography>
                                      <Typography variant="subtitle2" gutterBottom>
                                        סדר השיעורים:
                                      </Typography>
                                      <List sx={{ pl: 2 }}>
                                        {unit.roadmap
                                          .sort((a, b) => a.order - b.order)
                                          .map((roadmapItem) => (
                                            <ListItem key={roadmapItem.lesson._id} disableGutters>
                                              <ListItemText
                                                primary={`• ${roadmapItem.order}. ${roadmapItem.lesson.title}`}
                                              />
                                            </ListItem>
                                          ))}
                                      </List>
                                    </AccordionDetails>
                                  </Accordion>
                                </Box>
                              ))}
                            </List>
                          ) : (
                            <Typography variant="body2" color="textSecondary">
                              אין יחידות בקורס זה.
                            </Typography>
                          )}
                        </AccordionDetails>
                      </Accordion>
                    </Box>
                  ))}
                </List>
              ) : (
                <Typography variant="body2" color="textSecondary">
                  אין קורסים כלולים בתוכנית זו.
                </Typography>
              )}
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseCoursesDialog} color="primary">
                סגור
              </Button>
            </DialogActions>
          </Dialog>
        )}

        {/* Optional: Back to Home Button */}
        <Box sx={{ textAlign: 'center', mt: 8 }}>
          <Button
            variant="outlined"
            color="primary"
            size="large"
            component={Link}
            to="/"
            sx={{
              paddingX: 4,
              paddingY: 1.5,
              fontSize: '1rem',
              borderRadius: '50px',
              textTransform: 'none',
              boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
              transition: 'background-color 0.3s ease',
              '&:hover': {
                backgroundColor: 'primary.light',
              },
            }}
          >
            חזרה לדף הבית
          </Button>
        </Box>
      </Container>
    </main>
  );
};

export default AllPlans;
