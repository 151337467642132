// src/components/Widgets/Filters/CoursesFilter.js

import React from 'react';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import PropTypes from 'prop-types';

const CoursesFilter = ({ filterCourses, setFilterCourses, fullWidth }) => {
  const handleChange = (event) => {
    setFilterCourses(event.target.value);
  };

  return (
    <FormControl fullWidth={fullWidth}>
      <InputLabel id="courses-filter-label">סינון קורסים</InputLabel>
      <Select
        labelId="courses-filter-label"
        id="courses-filter"
        value={filterCourses}
        label="סינון קורסים"
        onChange={handleChange}
      >
        <MenuItem value="myCourses">הקורסים שלי</MenuItem>
        <MenuItem value="allCourses">כל הקורסים</MenuItem>
      </Select>
    </FormControl>
  );
};

CoursesFilter.propTypes = {
  filterCourses: PropTypes.string.isRequired,
  setFilterCourses: PropTypes.func.isRequired,
  fullWidth: PropTypes.bool,
};

CoursesFilter.defaultProps = {
  fullWidth: true,
};

export default CoursesFilter;
