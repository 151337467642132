// src/components/IconDisplay.js
import React from 'react';
import { Box } from '@mui/material';
import iconOptions from './IconMapping'; // Adjust the path if needed
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'; // Fallback icon

const IconDisplay = ({ iconName, size = 64, color = 'primary.main', ...props }) => {
  const iconEntry = iconOptions.find((option) => option.name === iconName);
  const IconComponent = iconEntry ? iconEntry.icon : <HelpOutlineIcon />;

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: size,
        transition: 'transform 0.3s',
        '&:hover': {
          transform: 'scale(1.1)',
        },
      }}
      {...props}
    >
      {React.cloneElement(IconComponent, {
        sx: {
          fontSize: size * 0.9, // Increases icon size
          color: color,
          textAlign: 'center',
          lineHeight: 1,
        },
      })}
    </Box>
  );
};

export default IconDisplay;



