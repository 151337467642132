// src/components/AboutUs.js

import React from 'react';
import { Box, Typography, Avatar, Grid, Paper, Button } from '@mui/material';
import { styled } from '@mui/system';
import { Timeline, TimelineItem, TimelineSeparator, TimelineConnector, TimelineContent, TimelineDot } from '@mui/lab';
import SchoolIcon from '@mui/icons-material/School';
import StarIcon from '@mui/icons-material/Star';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import { motion } from 'framer-motion';

// רכיב עיצוב לכותרת
const Header = styled(Box)(({ theme }) => ({
  textAlign: 'center',
  padding: theme.spacing(6, 2),
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  borderRadius: theme.shape.borderRadius,
  marginBottom: theme.spacing(6),
}));

// רכיב עיצוב לטקסט
const Description = styled(Typography)(({ theme }) => ({
  maxWidth: '600px',
  margin: '0 auto',
  textAlign: 'left',
  padding: theme.spacing(2),
}));

// רכיב עיצוב לסקשנים
const Section = styled(Box)(({ theme }) => ({
  padding: theme.spacing(4, 2),
}));

function AboutUs() {
  return (
    <main className="about-us">
      {/* Header */}
      <Header component={motion.div} initial={{ opacity: 0, y: -50 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 1 }}>
        <Typography variant="h2" gutterBottom sx={{
          fontSize: {
            xs: '2rem',
            sm: '3rem',
            md: '4rem',
          },
          fontWeight: 'bold',
        }}>
          אודות אוהד ניסים
        </Typography>
        <Avatar
          alt="אוהד ניסים"
          src="../images/ohad-nissim.jpg"
          sx={{
            width: { xs: 140, sm: 180, md: 220 },
            height: { xs: 140, sm: 180, md: 220 },
            mx: 'auto',
            mb: 4,
            border: '5px solid white',
            boxShadow: 3,
          }}
        />
      </Header>

      {/* הסקירה האישית */}
      <Section component={motion.div} initial={{ opacity: 0 }} whileInView={{ opacity: 1 }} transition={{ duration: 1 }}>
        <Description variant="h5" gutterBottom>
          שלום, אני אוהד ניסים
        </Description>
        <Description variant="body1" paragraph>
          עם יותר משש שנות ניסיון בהוראת מתמטיקה, אני נלהב לעזור לתלמידים ברחבי העולם להיות הגרסה הטובה ביותר של עצמם. הפיתוח של השיטה האישית שלי נבע מהרצון להפוך את הלמידה לנגישה, מהנה ואפקטיבית יותר עבור כל תלמיד.
        </Description>
      </Section>

      {/* המסע שלי */}
      <Section component={motion.div} initial={{ opacity: 0 }} whileInView={{ opacity: 1 }} transition={{ duration: 1, delay: 0.5 }}>
        <Typography variant="h4" align="center" gutterBottom>
          המסע שלי
        </Typography>
        <Timeline position="alternate">
          <TimelineItem>
            <TimelineSeparator>
              <TimelineDot color="primary">
                <SchoolIcon />
              </TimelineDot>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>
              <Paper elevation={3} sx={{ padding: 2 }}>
                <Typography variant="h6">התחלות בהוראה</Typography>
                <Typography>התחלתי כמשתנך בכיתות תיכון, שם גיליתי את התשוקה שלי להוראה והנחת התלמידים.</Typography>
              </Paper>
            </TimelineContent>
          </TimelineItem>

          <TimelineItem>
            <TimelineSeparator>
              <TimelineDot color="secondary">
                <StarIcon />
              </TimelineDot>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>
              <Paper elevation={3} sx={{ padding: 2 }}>
                <Typography variant="h6">פיתוח שיטת לימוד ייחודית</Typography>
                <Typography>במהלך השנים פיתחתי שיטה שמבוססת על התאמה אישית, תרגול מתמיד ומשוב איכותי.</Typography>
              </Paper>
            </TimelineContent>
          </TimelineItem>

          <TimelineItem>
            <TimelineSeparator>
              <TimelineDot color="success">
                <EmojiEventsIcon />
              </TimelineDot>
            </TimelineSeparator>
            <TimelineContent>
              <Paper elevation={3} sx={{ padding: 2 }}>
                <Typography variant="h6">הקמת InfinityLearning</Typography>
                <Typography>הקמתי את הפלטפורמה InfinityLearning כדי לשתף את השיטה שלי עם תלמידים בכל הרמות ולהעניק להם את הכלים להצלחה.</Typography>
              </Paper>
            </TimelineContent>
          </TimelineItem>
        </Timeline>
      </Section>

      {/* השיטה שלי */}
      <Section component={motion.div} initial={{ opacity: 0 }} whileInView={{ opacity: 1 }} transition={{ duration: 1, delay: 1 }}>
        <Typography variant="h4" align="center" gutterBottom>
          השיטה שלי
        </Typography>
        <Grid container spacing={4} justifyContent="center">
          <Grid item xs={12} md={4}>
            <Paper elevation={3} sx={{ padding: 3, textAlign: 'center' }}>
              <Typography variant="h6" gutterBottom>
                למידה מותאמת אישית
              </Typography>
              <Typography>התאמת חומרי הלימוד והקצב לכל תלמיד בהתאם לצרכיו האישיים.</Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} md={4}>
            <Paper elevation={3} sx={{ padding: 3, textAlign: 'center' }}>
              <Typography variant="h6" gutterBottom>
                תרגול מתמיד
              </Typography>
              <Typography>שאלות תרגול מתקדמות ומשוב מקצועי לשיפור הבנת החומר ופתרון בעיות.</Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} md={4}>
            <Paper elevation={3} sx={{ padding: 3, textAlign: 'center' }}>
              <Typography variant="h6" gutterBottom>
                תמיכה אישית
              </Typography>
              <Typography>הזדמנויות לעלות פתורנות ולקבל משוב מפורט ממני על כל שאלה.</Typography>
            </Paper>
          </Grid>
        </Grid>
      </Section>

      {/* המלצות תלמידים */}
      <Section component={motion.div} initial={{ opacity: 0 }} whileInView={{ opacity: 1 }} transition={{ duration: 1, delay: 1.5 }}>
        <Typography variant="h4" align="center" gutterBottom>
          מה תלמידים אומרים
        </Typography>
        <Grid container spacing={4} justifyContent="center">
          <Grid item xs={12} md={6}>
            <Paper elevation={3} sx={{ padding: 3 }}>
              <Typography variant="body1" paragraph>
                "עם השיטה של אוהד, הציונים שלי במתמטיקה זינקו ב-30%. ההסברים הברורים והמשוב המקצועי עזרו לי להבין מושגים שקודם לכן לא הייתי מצליח להם."
              </Typography>
              <Typography variant="subtitle1" align="right">- יעל, תיכון</Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} md={6}>
            <Paper elevation={3} sx={{ padding: 3 }}>
              <Typography variant="body1" paragraph>
                "הפלטפורמה InfinityLearning היא כלי מצוין ללמידה עצמאית. הקורסים המוקלטים והלייב מאפשרים לי ללמוד בכל זמן ובכל מקום."
              </Typography>
              <Typography variant="subtitle1" align="right">- דניאל, אוניברסיטה</Typography>
            </Paper>
          </Grid>
        </Grid>
      </Section>

      {/* קריאה לפעולה */}
      <Section component={motion.div} initial={{ opacity: 0 }} whileInView={{ opacity: 1 }} transition={{ duration: 1, delay: 2 }}>
        <Box textAlign="center" py={4}>
          <Typography variant="h5" gutterBottom>
            מוכנים להתחיל את המסע שלכם להצלחה?
          </Typography>
          <Button variant="contained" color="primary" size="large">
            הצטרפו עכשיו
          </Button>
        </Box>
      </Section>
    </main>
  );
}

export default AboutUs;
