// src/components/EditField.js

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Typography,
  IconButton,
  TextField,
  Button,
  useMediaQuery,
} from '@mui/material';
import { Edit, Save, Cancel } from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';

const EditField = ({
  label,
  value,
  onSave,
  required,
  type = 'text',
  validation,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [isEditing, setIsEditing] = useState(false);
  const [currentValue, setCurrentValue] = useState(value || '');
  const [error, setError] = useState('');

  const handleSave = async () => {
    if (validation) {
      const validationError = validation(currentValue);
      if (validationError) {
        setError(validationError);
        return;
      }
    }
    try {
      await onSave(currentValue);
      setIsEditing(false);
      setError('');
    } catch (err) {
      setError(err.message || 'שגיאה בעדכון השדה.'); // "Error updating the field."
    }
  };

  const handleCancel = () => {
    setCurrentValue(value || '');
    setIsEditing(false);
    setError('');
  };

  return (
    <Box sx={{ mb: isMobile ? 2 : 3 }}>
      <Typography variant="subtitle1" gutterBottom>
        {label}
        {required && (
          <Box component="span" sx={{ color: theme.palette.error.main, ml: 0.5 }}>
            *
          </Box>
        )}
      </Typography>
      {!isEditing ? (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            backgroundColor: theme.palette.background.default,
            padding: theme.spacing(isMobile ? 1.5 : 2),
            borderRadius: 1,
            boxShadow: theme.shadows[1],
          }}
        >
          <Typography variant="body1">
            {value || (
              <Box component="span" sx={{ color: theme.palette.error.main }}>
                יש לעדכן {/* "Not Available" */}
              </Box>
            )}
          </Typography>
          <IconButton
            aria-label={`ערוך ${label}`} // "Edit {label}" in Hebrew
            onClick={() => setIsEditing(true)}
            size="small"
            sx={{ color: theme.palette.primary.main }}
          >
            <Edit />
          </IconButton>
        </Box>
      ) : (
        <Box>
          <TextField
            variant="outlined"
            fullWidth
            type={type}
            value={currentValue}
            onChange={(e) => setCurrentValue(e.target.value)}
            error={Boolean(error)}
            helperText={error}
            InputProps={{
              sx: {
                backgroundColor: theme.palette.background.paper,
                // Ensure font size is at least 16px on mobile to prevent zoom
                fontSize: isMobile ? '16px' : 'inherit',
              },
            }}
            // Adjust inputProps to set font size and prevent zoom
            inputProps={{
              style: {
                fontSize: isMobile ? 16 : undefined, // 16px on mobile
              },
            }}
            // Optionally, set size to 'medium' to ensure adequate touch targets
            size={isMobile ? 'medium' : 'medium'}
          />
          <Box
            sx={{
              mt: isMobile ? 1 : 2,
              display: 'flex',
              gap: isMobile ? 1 : 1.5,
              flexDirection: isMobile ? 'column' : 'row',
            }}
          >
            <Button
              variant="contained"
              color="primary"
              startIcon={<Save />}
              onClick={handleSave}
              fullWidth={isMobile}
              sx={{
                textTransform: 'none',
                fontWeight: 600,
                fontSize: isMobile ? '16px' : 'inherit',
              }}
            >
              שמור {/* "Save" */}
            </Button>
            <Button
              variant="outlined"
              color="secondary"
              startIcon={<Cancel />}
              onClick={handleCancel}
              fullWidth={isMobile}
              sx={{
                textTransform: 'none',
                fontWeight: 600,
                fontSize: isMobile ? '16px' : 'inherit',
              }}
            >
              בטל {/* "Cancel" */}
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  );
};

EditField.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string,
  onSave: PropTypes.func.isRequired,
  required: PropTypes.bool,
  type: PropTypes.string,
  validation: PropTypes.func,
};

export default EditField;
