// src/components/VideoPlayer.js

import React, { useState, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/system';

const VideoContainer = styled(Box)({
  position: 'relative',
  paddingTop: '56.25%', // יחס 16:9
  marginBottom: '16px',
});

const ResponsiveIframe = styled('iframe')({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  border: 0,
});

const PlaceholderContainer = styled(Box)({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: '#f0f0f0', // רקע אפור בהיר (ניתן לשנות לפי הצורך)
});

const VideoPlayer = ({ videoUrl, title }) => {
  const [videoAvailable, setVideoAvailable] = useState(true);

  useEffect(() => {
    const checkVideoAvailability = async () => {
      try {
        const response = await fetch(videoUrl, { method: 'HEAD' });
        if (!response.ok) {
          setVideoAvailable(false);
        }
      } catch (error) {
        setVideoAvailable(false);
      }
    };

    checkVideoAvailability();
  }, [videoUrl]);

  return (
    <VideoContainer>
      {videoAvailable ? (
        <ResponsiveIframe
          src={videoUrl}
          title={title}
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></ResponsiveIframe>
      ) : (
        <PlaceholderContainer>
          <Typography variant="h6" color="textSecondary">
            הווידאו אינו זמין כרגע
          </Typography>
        </PlaceholderContainer>
      )}
    </VideoContainer>
  );
};

export default VideoPlayer;
