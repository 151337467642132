import React, { useContext, useState, useEffect } from 'react';
import { useParams, useNavigate, Link as RouterLink } from 'react-router-dom';
import {
  Box,
  Typography,
  Container,
  Button,
  CircularProgress,
  Alert,
  Link as MuiLink,
  Grid,
  Card,
  CardContent,
  CardMedia,
  CardActions,
  Chip,
  Snackbar,
  Divider,
  Tooltip,
  Avatar,
  LinearProgress,
  Stack,
} from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import { styled } from '@mui/system';
import CheckIcon from '@mui/icons-material/Check';
import LaunchIcon from '@mui/icons-material/Launch';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import EventIcon from '@mui/icons-material/Event';
import PeopleIcon from '@mui/icons-material/People';
import LiveTvIcon from '@mui/icons-material/LiveTv';
import { DataContext } from '../../context/DataContext';
import { UserContext } from '../../context/UserContext';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import HomeworkSection from './HomeworkSection';
import FormulasTheorems from '../Widgets/formulasThermoes';

// Extend Day.js with the relativeTime plugin
dayjs.extend(relativeTime);

// Styled Components

// Enhanced LessonHeader with Gradient Background
const LessonHeader = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(6),
  textAlign: 'center',
  background: `linear-gradient(135deg, ${theme.palette.primary.main} 30%, ${theme.palette.secondary.main} 90%)`,
  color: theme.palette.primary.contrastText,
  padding: theme.spacing(6, 4),
  borderRadius: theme.shape.borderRadius,
  position: 'relative',
}));

const LessonIcon = styled(Avatar)(({ theme }) => ({
  width: theme.spacing(10),
  height: theme.spacing(10),
  backgroundColor: theme.palette.background.paper,
  color: theme.palette.primary.main,
  position: 'absolute',
  top: theme.spacing(-5),
  left: '50%',
  transform: 'translateX(-50%)',
  boxShadow: theme.shadows[4],
}));

const LessonTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 'bold',
  fontSize: '2.5rem',
  marginTop: theme.spacing(2),
}));

const CourseTitle = styled(Typography)(({ theme }) => ({
  fontSize: '1.4rem',
  marginTop: theme.spacing(1),
}));

const StatusChip = styled(Chip)(({ theme, status }) => ({
  marginTop: theme.spacing(2),
  backgroundColor:
    status === 'live'
      ? theme.palette.success.main
      : status === 'scheduled'
      ? theme.palette.info.main
      : status === 'passed'
      ? theme.palette.grey[500]
      : status === 'canceled'
      ? theme.palette.error.main
      : theme.palette.warning.main,
  color: theme.palette.getContrastText(
    status === 'live'
      ? theme.palette.success.main
      : status === 'scheduled'
      ? theme.palette.info.main
      : status === 'passed'
      ? theme.palette.grey[500]
      : status === 'canceled'
      ? theme.palette.error.main
      : theme.palette.warning.main
  ),
}));

const StyledButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2),
}));

const BadgeChip = styled(Chip)(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(1),
  left: theme.spacing(1),
  zIndex: 1,
}));

const IconAvatar = styled(Avatar)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.main,
  marginRight: theme.spacing(1),
}));

const RemainingSeatsBox = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(4),
  marginBottom: theme.spacing(4),
}));

const LessonDetail = () => {
  const { lessonId } = useParams();
  const navigate = useNavigate();
  const {
    lessons,
    courses,
    fetchLessons,
    loading: dataLoading,
    error: dataError,
  } = useContext(DataContext);
  const {
    user,
    isSignedIn,
    hasAccess,
    enrollInLesson,
    cancelLesson,
    loading: userLoading,
    error: userError,
  } = useContext(UserContext);

  // State for course and lesson
  const [course, setCourse] = useState(null);
  const [lesson, setLesson] = useState(null);

  // State for user access
  const [userHasAccess, setUserHasAccess] = useState(false);
  const [accessLoading, setAccessLoading] = useState(true);
  const [accessError, setAccessError] = useState(null);

  const [lessonFormulas, setLessonFormulas] = useState({
    title: '',
    description: '',
    formulas: [],
    theorems: []
  });
  const [questions, setQuestions] = useState([]);
  const [loadingQuestions, setLoadingQuestions] = useState(false);

  // Snackbar state
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success', // 'success' | 'error' | 'warning' | 'info'
  });

  const [isEnrolled, setIsEnrolled] = useState(false);

  // Check user access
  useEffect(() => {
    const checkAccess = async () => {
      setLoadingQuestions(true);
      setAccessLoading(true);
      try {
        const access = await hasAccess('lesson', lessonId);
        setUserHasAccess(access);
        setQuestions(lesson?.homeWork ||  []);
      } catch (err) {
        console.error('Error checking access:', err);
        setAccessError('אירעה שגיאה בבדיקת הגישה לשיעור זה.');
        setUserHasAccess(false);
      } finally {
        setAccessLoading(false);
        setLoadingQuestions(false);
      }
    };

    if (isSignedIn && lessonId) {
      checkAccess();
    } else {
      setUserHasAccess(false);
      setAccessLoading(false);
    }
  }, [isSignedIn, lessonId, hasAccess]);

  // Update course and lesson when data loads
  useEffect(() => {
    if (!dataLoading) {
      // Ensure lessons are loaded and include all necessary fields
      const foundLesson = lessons.find((l) => l._id === lessonId);
      console.log('Found Lesson:', foundLesson);
      setLesson(foundLesson);

      setLessonFormulas({
        title: lesson?.mainStudyMaterial?.title || 'נוסחאות ותיאוריות של השיעור',
        description: lesson?.mainStudyMaterial?.description || 'נוסחאות ותיאוריות חשובות',
        formulas: lesson?.mainStudyMaterial?.formulas || [],
        theorems: lesson?.mainStudyMaterial?.theorems || []
      });

      console.log(courses);
      const foundCourse = courses.find((c) => c._id === foundLesson?.course._id);
      console.log(foundCourse);
      setCourse(foundCourse);
    }
  }, [dataLoading, courses, lessons, course, lessonId]);

  // Update enrollment status
  useEffect(() => {
    if (lesson && user) {
      setIsEnrolled(
        lesson.enrolledStudents?.some((student) => student._id === user._id) || false
      );
    }
  }, [lesson, user]);

  // Handle enrollment
  const handleEnroll = async () => {
    try {
      await enrollInLesson(lessonId);
      setSnackbar({
        open: true,
        message: 'נרשמת בהצלחה לשיעור!',
        severity: 'success',
      });
      await fetchLessons();
    } catch (error) {
      setSnackbar({
        open: true,
        message: error.message || 'ההרשמה נכשלה. אנא נסה שוב.',
        severity: 'error',
      });
    }
  };

  // Handle cancellation
  const handleCancelLesson = async () => {
    try {
      await cancelLesson(lessonId, user._id);
      setSnackbar({
        open: true,
        message: 'ביטלת את ההרשמה בהצלחה!',
        severity: 'success',
      });
      await fetchLessons();
    } catch (error) {
      setSnackbar({
        open: true,
        message: error.message || 'ביטול ההרשמה נכשל. אנא נסה שוב.',
        severity: 'error',
      });
    }
  };

  // Handle Snackbar close
  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') return;
    setSnackbar({ ...snackbar, open: false });
  };

  // Show loading state
  if (dataLoading || accessLoading || !lesson) {
    return (
      <Container sx={{ py: 8, textAlign: 'center' }}>
        <CircularProgress />
      </Container>
    );
  }

  // Show error messages
  if (dataError || accessError) {
    return (
      <Container >
        {dataError && <Alert severity="error">{dataError}</Alert>}
        {accessError && <Alert severity="error">{accessError}</Alert>}
        <Box sx={{ mt: 4, textAlign: 'center' }}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => navigate(`/course/${course._id}/details`)}
            startIcon={<CheckIcon />}
          >
            חזרה לקורס
          </Button>
        </Box>
      </Container>
    );
  }

  // Show if lesson not found
  if (!lesson) {
    return (
      <Container >
        <Alert severity="error">השיעור לא נמצא.</Alert>
        <Box sx={{ mt: 4, textAlign: 'center' }}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => navigate(`/course/${course._id}/details`)}
            startIcon={<CheckIcon />}
          >
            חזרה לקורס
          </Button>
        </Box>
      </Container>
    );
  }

  // Format dates
  const formattedStartDate = lesson.startDateTime
    ? dayjs(lesson.startDateTime).format('DD/MM/YYYY HH:mm')
    : 'לא נקבע';
  const formattedEndDate = lesson.endDateTime
    ? dayjs(lesson.endDateTime).format('DD/MM/YYYY HH:mm')
    : 'לא נקבע';

  // Calculate remaining seats
  const remainingSeats =
    lesson.maxStudents > 0 ? lesson.maxStudents - (lesson.enrolledStudents?.length || 0) : null;
  const enrollmentPercentage =
    lesson.maxStudents > 0 ? (lesson.enrolledStudents?.length / lesson.maxStudents) * 100 : 0;

  return (
    <main className="lesson-detail">
      <Container >
        {/* Header Section */}
        <LessonHeader>
          {/* {lesson.icon && <LessonIcon>{lesson.icon.charAt(0).toUpperCase()}</LessonIcon>} */}
          <LessonTitle variant="h4" component="h1" gutterBottom>
            {lesson.title}
          </LessonTitle>
          <CourseTitle variant="subtitle1">
            <strong>קורס:</strong> {course ? course.title : 'לא ידוע'}
          </CourseTitle>
          <StatusChip label={lesson.status.toUpperCase()} status={lesson.status} />
        </LessonHeader>

        {/* User Error Message */}
        {userError && (
          <Box sx={{ mb: 4 }}>
            <Alert severity="error">{userError}</Alert>
          </Box>
        )}

        {/* Lesson Content */}
        {userHasAccess ? (
          <>
            <FormulasTheorems lessonFormulas={lessonFormulas} />
            {/* Description */}
            <Typography variant="h5" gutterBottom>
              תיאור
            </Typography>
            <Typography variant="body1" paragraph>
              {lesson.description || 'אין תיאור זמין לשיעור זה.'}
            </Typography>

            <Divider sx={{ my: 4 }} />

            {/* Lesson Details */}
            <Box sx={{ mb: 4 }}>
              <Typography variant="h5" gutterBottom>
                פרטי השיעור
              </Typography>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <CalendarTodayIcon color="action" sx={{ mr: 2 }} />
                    <Box>
                      <Typography variant="body1" component="div">
                        <strong>תאריך התחלה:</strong> {formattedStartDate}
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        {dayjs(lesson.startDateTime).fromNow()}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <EventIcon color="action" sx={{ mr: 2 }} />
                    <Typography variant="body1">
                      <strong>תאריך סיום:</strong> {formattedEndDate}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <AccessTimeIcon color="action" sx={{ mr: 2 }} />
                    <Typography variant="body1">
                      <strong>משך:</strong> {lesson.duration ? `${lesson.duration} דקות` : 'לא נקבע'}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <PeopleIcon color="action" sx={{ mr: 2 }} />
                    <Typography variant="body1">
                      <strong>מקסימום תלמידים:</strong> {lesson.maxStudents > 0 ? lesson.maxStudents : 'ללא הגבלה'}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <LiveTvIcon color="action" sx={{ mr: 2 }} />
                    <Typography variant="body1">
                      <strong>שידור חי:</strong> {lesson.isLive ? 'כן' : 'לא'}
                    </Typography>
                  </Box>
                </Grid>
                {/* Remaining Seats */}
                {lesson.maxStudents > 0 && (
                  <Grid item xs={12}>
                    <RemainingSeatsBox>
                      <Typography variant="body1" gutterBottom>
                        <strong>מקומות פנויים:</strong> {remainingSeats}
                      </Typography>
                      <LinearProgress
                        variant="determinate"
                        value={enrollmentPercentage}
                        sx={{
                          height: 10,
                          borderRadius: 5,
                          backgroundColor: 'grey.300',
                          '& .MuiLinearProgress-bar': {
                            backgroundColor: 'primary.main',
                          },
                        }}
                      />
                      <Typography variant="body2" color="textSecondary" sx={{ mt: 1 }}>
                        {lesson.enrolledStudents?.length} מתוך {lesson.maxStudents} תלמידים רשומים
                      </Typography>
                    </RemainingSeatsBox>
                  </Grid>
                )}
              </Grid>
            </Box>

            {/* Lesson Status Specific Content */}
            {lesson.status === 'live' && lesson.joinLink && (
              <Box sx={{ mb: 4 }}>
                <Typography variant="h5" gutterBottom>
                  הצטרף לשיעור החי
                </Typography>
                <MuiLink
                  href={lesson.joinLink}
                  target="_blank"
                  rel="noopener noreferrer"
                  underline="hover"
                  variant="h6"
                >
                  לחץ כאן להצטרפות <LaunchIcon fontSize="small" />
                </MuiLink>
              </Box>
            )}

            {lesson.status === 'passed' && lesson.recordLink && (
              <Box sx={{ mb: 4 }}>
                <Typography variant="h5" gutterBottom>
                  הקלטת השיעור
                </Typography>
                <MuiLink
                  href={lesson.recordLink}
                  target="_blank"
                  rel="noopener noreferrer"
                  underline="hover"
                  variant="h6"
                >
                  לחץ כאן לצפייה בהקלטה <LaunchIcon fontSize="small" />
                </MuiLink>
              </Box>
            )}

            {lesson.status === 'canceled' && (
              <Box sx={{ mb: 4 }}>
                <Alert severity="warning">השיעור בוטל.</Alert>
              </Box>
            )}

            {lesson.status === 'bonus' && (
              <Box sx={{ mb: 4 }}>
                <Alert severity="success">שיעור בונוס!</Alert>
              </Box>
            )}

            {/* Enrollment Details */}
            <Box sx={{ mb: 4 }}>
              <Typography variant="h5" gutterBottom>
                רשומים לשיעור
              </Typography>
              {lesson.enrolledStudents && lesson.enrolledStudents.length > 0 ? (
                <Grid container spacing={2}>
                  {lesson.enrolledStudents.map((student) => (
                    <Grid item key={student._id}>
                      <Tooltip title={student.fullName}>
                        <Avatar alt={student.fullName} src={student.avatarUrl}>
                          {student.fullName.charAt(0).toUpperCase()}
                        </Avatar>
                      </Tooltip>
                    </Grid>
                  ))}
                </Grid>
              ) : (
                <Typography variant="body2" color="textSecondary">
                  אין תלמידים רשומים לשיעור זה.
                </Typography>
              )}
            </Box>

          
          {/* Homework Section */}
          <Divider sx={{ my: 4 }} />
          {loadingQuestions ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', py: 3 }}>
              <CircularProgress />
            </Box>
          ) : (
            <HomeworkSection questions={lesson.homeWork} />
          )}
          <Divider sx={{ my: 4 }} />
          

            {/* Study Materials */}
            <Typography variant="h5" gutterBottom>
              חומרי לימוד
            </Typography>
            {lesson.mainStudyMaterial || (lesson.supplementaryMaterials && lesson.supplementaryMaterials.length > 0) ? (
              <Grid container spacing={4}>
                {/* Main Study Material */}
                {lesson.mainStudyMaterial && (
                  <Grid item xs={12} sm={6} md={4}>
                    <Card
                      sx={{
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        boxShadow: 3,
                        borderRadius: 2,
                        position: 'relative',
                        transition: 'transform 0.3s',
                        '&:hover': {
                          transform: 'scale(1.05)',
                        },
                      }}
                    >
                      {/* Badge for Main Material */}
                      <BadgeChip label="ראשי" color="primary" size="small" />

                      {lesson.mainStudyMaterial.imageUrl ? (
                        <CardMedia
                          component="img"
                          image={lesson.mainStudyMaterial.imageUrl}
                          alt={lesson.mainStudyMaterial.title}
                          height="200"
                        />
                      ) : null}

                      <CardContent
                        sx={{
                          flexGrow: 1,
                          mt: lesson.mainStudyMaterial.imageUrl ? 0 : 2,
                        }}
                      >
                        <Typography
                          gutterBottom
                          variant="h5"
                          component="h2"
                          sx={{
                            mt: lesson.mainStudyMaterial.imageUrl ? 0 : 2,
                          }}
                        >
                          {lesson.mainStudyMaterial.title}
                        </Typography>
                        <Typography>
                          {lesson.mainStudyMaterial.description && lesson.mainStudyMaterial.description.length > 100
                            ? `${lesson.mainStudyMaterial.description.substring(0, 100)}...`
                            : lesson.mainStudyMaterial.description}
                        </Typography>
                      </CardContent>
                      <CardActions>
                        <Button
                          size="small"
                          color="primary"
                          component={RouterLink}
                          to={`/study-material/${lesson.mainStudyMaterial._id}/${course._id}`}
                          startIcon={<LaunchIcon />}
                        >
                          לצפייה
                        </Button>
                      </CardActions>
                    </Card>
                  </Grid>
                )}

                {/* Supplementary Study Materials */}
                {lesson.supplementaryMaterials && lesson.supplementaryMaterials.length > 0 && (
                  lesson.supplementaryMaterials.map((material) => (
                    <Grid item xs={12} sm={6} md={4} key={material._id}>
                      <Card
                        sx={{
                          height: '100%',
                          display: 'flex',
                          flexDirection: 'column',
                          boxShadow: 3,
                          borderRadius: 2,
                          position: 'relative',
                          transition: 'transform 0.3s',
                          '&:hover': {
                            transform: 'scale(1.05)',
                          },
                        }}
                      >
                        {/* Badge for Supplementary Material */}
                        <BadgeChip label="משני" color="secondary" size="small" />

                        {material.imageUrl ? (
                          <CardMedia
                            component="img"
                            image={material.imageUrl}
                            alt={material.title}
                            height="200"
                          />
                        ) : null}

                        <CardContent
                          sx={{
                            flexGrow: 1,
                            mt: material.imageUrl ? 0 : 2,
                          }}
                        >
                          <Typography
                            gutterBottom
                            variant="h5"
                            component="h2"
                            sx={{
                              mt: material.imageUrl ? 0 : 2,
                            }}
                          >
                            {material.title}
                          </Typography>
                          <Typography>
                            {material.description && material.description.length > 100
                              ? `${material.description.substring(0, 100)}...`
                              : material.description}
                          </Typography>
                        </CardContent>
                        <CardActions>
                          <Button
                            size="small"
                            color="primary"
                            component={RouterLink}
                            to={`/study-material/${material._id}`}
                            startIcon={<LaunchIcon />}
                          >
                            לצפייה
                          </Button>
                        </CardActions>
                      </Card>
                    </Grid>
                  ))
                )}
              </Grid>
            ) : (
              <Typography variant="body2" color="textSecondary">
                אין חומרי לימוד זמינים לשיעור זה.
              </Typography>
            )}

            {/* Enrollment Actions */}
            <Box sx={{ mt: 4, textAlign: 'center' }}>
              {!isEnrolled ? (
                <>
                  <Alert severity="info" sx={{ mb: 2 }}>
                    אנא הירשם כדי לשמור את מקומך.
                  </Alert>
                  <StyledButton
                    variant="contained"
                    color="primary"
                    onClick={handleEnroll}
                    startIcon={<CheckIcon />}
                    disabled={userLoading || (lesson.maxStudents > 0 && remainingSeats <= 0)}
                  >
                    {userLoading ? <CircularProgress size={24} color="inherit" /> : 'הירשם לשיעור'}
                  </StyledButton>
                  {lesson.maxStudents > 0 && remainingSeats <= 0 && (
                    <Alert severity="warning" sx={{ mt: 2 }}>
                      השיעור מלא.
                    </Alert>
                  )}
                </>
              ) : (
                <>
                  <Alert severity="success" sx={{ mb: 2 }}>
                    אתה רשום לשיעור זה.
                  </Alert>
                  <StyledButton
                    variant="outlined"
                    color="secondary"
                    onClick={handleCancelLesson}
                    startIcon={<CheckIcon />}
                    disabled={userLoading}
                  >
                    {userLoading ? <CircularProgress size={24} color="inherit" /> : 'בטל הרשמה'}
                  </StyledButton>
                </>
              )}
            </Box>
          </>
        ) : (
          <Box sx={{ mb: 8 }}>
            <Alert severity="info">
              אין לך גישה לשיעור זה. אנא הירשם כדי לקבל גישה.
            </Alert>
          </Box>
        )}

        {/* Back to Course Button */}
        <Box sx={{ mt: 8, textAlign: 'center' }}>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => navigate(`/course/${course._id}/details`)}
            startIcon={<CheckIcon />}
          >
            חזרה לקורס
          </Button>
        </Box>

        {/* Snackbar for Notifications */}
        <Snackbar
          open={snackbar.open}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <MuiAlert
            onClose={handleCloseSnackbar}
            severity={snackbar.severity}
            sx={{ width: '100%' }}
            elevation={6}
            variant="filled"
          >
            {snackbar.message}
          </MuiAlert>
        </Snackbar>
      </Container>
    </main>
  );
};

export default LessonDetail;
